import { FieldValues, useForm } from "react-hook-form";
import Modal from "../../components/atoms/Modal";
import { task } from "./Procedures";
import { useEffect } from "react";
import TextField from "../../components/atoms/forms/Fields/TextField";
import NumberField from "../../components/atoms/forms/Fields/NumberField";
import SelectField from "../../components/atoms/forms/Fields/SelectField";
import Button from "../../components/atoms/Button";
import APIRequest from "../../helpers/CreateRequest";
import { mode } from "../../Types/FormTypes";
import SearchField from "../../components/atoms/forms/Fields/SearchField";

type ModifyTaskType = {
    procedureID: string
    mode: mode,
    setMode: Function,
    task?: task,
    emptySet: FieldValues
    refreshProcedure: boolean,
    setRefreshProcedure: Function,

}

export default function ModifyTask({ procedureID, mode, setMode, task, emptySet, refreshProcedure, setRefreshProcedure }: ModifyTaskType) {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, isLoading },
        reset,
        getValues,
        setValue,
    } = useForm()

    const onSubmit = async (e: FieldValues) => {
        try {
            //Build request
            let request;
            if (mode !== 'delete') {

                if (mode === 'edit') e.id = task?.TaskID

                const method = (mode === 'add' ? 'POST' : 'PUT')
                request = new APIRequest(`/tasks`, method, null, e)
            } else {
                request = new APIRequest(`/tasks`, 'DELETE', null, { id: task?.TaskID })
            }

            const result = await request.GenerateRequest()
            const body = await result.json()
            //If okay, use new id from obj or use current id 
            if (body.status === 200) {
                setRefreshProcedure(!refreshProcedure)
                setMode('view')
            }

        } catch (err) {
            console.log(err)

        }
    }

    //when mode switches and there is a memory stored, if its add mode the form clears 
    useEffect(() => {
        reset(emptySet)

        if ((mode !== 'add' || task)) {
            reset(task)
        }
    }, [mode])

    return (
        <>
            {mode !== 'view' && task &&
                <Modal className="relative z-10 bg-md-on-secondary p-4 rounded-lg shadow-md " onClose={() => setMode('view')}>
                    <form className=" p-10 gap-y-5 flex flex-col" onSubmit={handleSubmit(onSubmit)}>

                        <input type="text" hidden value={procedureID} {...register('ProcedureID')} />

                        <TextField
                            name="Description"
                            label="Description"
                            value={task.Description}
                            formRef={register('Description')}
                            setValue={setValue}
                            disabled={mode === 'delete'}
                            size="lg"
                        />

                        <SearchField
                            name="PerformerRoleID"
                            label="Performed By"
                            value={task.PerformerRoleID !== undefined && task.PerformerRoleID !== null ? task.PerformerRoleID.toString() : undefined}
                            setValue={setValue}
                            fieldNames={{ name: 'Role', namePlural: 'Roles' }}
                            disabled={mode === 'delete'}
                            size="lg"
                        />

                        <NumberField
                            name="DurationMinutes"
                            label="Duration Minutes"
                            value={task.DurationMinutes !== undefined && task.DurationMinutes !== null && task.DurationMinutes !== '' ? task.DurationMinutes : undefined}
                            formRef={register("DurationMinutes")}
                            setValue={setValue}
                            disabled={mode === 'delete'}
                            size="md"
                        />




                        <div className="grid grid-cols-2 gap-5">
                            <Button
                                label="Cancel"
                                action={() => setMode('view')}
                                styleType="outlined"
                                type="button"
                            />
                            <Button
                                label={mode === 'delete' ? 'Delete' : 'Save'}
                                action={() => null}
                                styleType="tonal"
                                type="submit"
                            />
                        </div>
                    </form>
                </Modal>
            }
        </>
    )
}