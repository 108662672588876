import { useDrop } from "react-dnd";
import { DraggableItem, ItemType, CanvasItemType, ProjectItems } from "./ProjectTypes";

type CanvasItemProps = {
    item: CanvasItemType;
    project: ProjectItems
    nestLevel: number;
    selectedItemID: string;
    changeSelectedItemID: (id: string) => void
    addProjectItem: (item: ItemType, parentItem?: CanvasItemType) => void
    deleteProjectItem: (itemID: string, project: ProjectItems) => void
};


export default function CanvasItem({ item, project, selectedItemID, nestLevel, addProjectItem, deleteProjectItem, changeSelectedItemID }: CanvasItemProps) {
    const backGroundColor = item.type === 'lot' ? '#22c55e' : '#f59e0b'; //green-500, amber-500

    const [{ isOver }, drop] = useDrop(() => ({
        accept: item.type === 'lot'  ? 'structure' : [],
        drop: (draggableItem: DraggableItem) => {
            // add item and link to parent
            addProjectItem(draggableItem.type, item);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [project, item, addProjectItem]);

    const handleItemClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        changeSelectedItemID(item.id);
    };  

    return (
        <div
            ref={drop}
            className={`relative flex items-center justify-center gap-4 border-2 border-black ${selectedItemID === item.id ? 'border-sky-500' : ''} ${`z-[${nestLevel}]`}`}
            onClick={handleItemClick}
            style={{
                minWidth: item.dimensions.width,
                minHeight: item.dimensions.height,
                backgroundColor: isOver ? '#a7f3d0' : backGroundColor //emerald-200
            }}
        >
            <div className="absolute top-0 left-1">{item.props.name}</div>
            <svg className="absolute right-0 top-0 hover:bg-gray-300 cursor-pointer z-20" onMouseDown={() => deleteProjectItem(item.id, project)} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>

            {item.children && item.children.length > 0 && (
                <div className={`flex flex-wrap  gap-4 px-4 py-10`}>
                    {
                        item.children.map(childID => {
                            const child = project.get(childID)
                            return child && <CanvasItem key={childID} item={child} selectedItemID={selectedItemID} addProjectItem={addProjectItem} deleteProjectItem={deleteProjectItem} nestLevel={nestLevel + 1} changeSelectedItemID={changeSelectedItemID} project={project} />
                        })
                    }
                </div>
            )}
        </div>
    );
}