import React, { useEffect, useState } from "react"
import APIRequest from "../../helpers/CreateRequest"
import NewIconButton from "../../components/atoms/NewIconButton";
import { Currency } from "../../helpers/InputValueConverter"
import { useLocation, useNavigate } from "react-router-dom";

type Project = {
    ProjectObjectID: number;
    ProjectObjectNameCode: string;
    Description: string;
    Number: string;
    Phase: string;
    LotPrice: number;
    House: string;
    HouseSalePrice: number;
    HouseCost: number;
    MarginAmount: number;
    MarginPercentage: number;
};

type Projects = Project[];

type SortableColumnNames = 'ProjectObjectNameCode' | 'Number' | 'Phase' | 'LotPrice' | 'House' | 'HouseSalePrice' | 'HouseCost' | 'MarginAmount' | 'MarginPercentage';
type SortableColumnTypes = 'identifier' | 'currency' | 'percentage';
type SortableColumn = {
    
    columnName: SortableColumnNames;
    alias?: string;
    type: SortableColumnTypes;
}
type SortableColumns = SortableColumn[];

export default function ProjectDashboard() {
    const [projects, setProjects] = useState<Projects>([]);
    const [sortColumn, setSortColumn] = useState<SortableColumnNames>("ProjectObjectNameCode");
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [selectedProjectID, setSelectedProjectID] = useState<string>('');

    const navigate = useNavigate();
    const location = useLocation();

    async function GetProjects() {
        try {
            const result = await (new APIRequest('/projectObjects/dashboard', 'GET', null, null).GenerateRequest())
            if (result.status === 200) {
                const body = await result.json()
                const recordset = body.recordset
                const tempProjects: Projects = [];
                for (let i = 0; i < recordset.length; i++) {
                    tempProjects.push({ ...recordset[i] })
                }
                setProjects(tempProjects);
            } else {
                console.log(result)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const addProject = () => {
        navigate('/app/community-builder')
    };

    const handleProjectClick = (projectID: number) => {
        setSelectedProjectID(projectID.toString())
        navigate(`/app/community-builder`, { state: { projectID: projectID } })
    }

    const handleSort = (column: SortableColumnNames) => {
        let direction: "asc" | "desc" = "asc";
        if (sortColumn === column && sortDirection === "asc") {
          direction = "desc";
        }
        setSortColumn(column);
        setSortDirection(direction);
    
        const sortedProjects = [...projects].sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];

            if (aValue == null && bValue == null) return 0; // Both are null or undefined, so they are equal
            if (aValue == null) return direction === "asc" ? -1 : 1; // a is null, b is greater
            if (bValue == null) return direction === "asc" ? 1 : -1; // b is null, a is greater
    
            // Compare both values as numbers or strings based on their types
            if (typeof aValue === "number" && typeof bValue === "number") {
                // Handle number comparison
                if (aValue < bValue) return direction === "asc" ? -1 : 1;
                if (aValue > bValue) return direction === "asc" ? 1 : -1;
            } else {
                // Handle string comparison (or other types)
                const aStr = String(aValue).toLowerCase(); // Normalize string case
                const bStr = String(bValue).toLowerCase(); // Normalize string case
                if (aStr < bStr) return direction === "asc" ? -1 : 1;
                if (aStr > bStr) return direction === "asc" ? 1 : -1;
            }
    
          return 0;
        });
        setProjects(sortedProjects);
    };
    
    useEffect(() => {
        GetProjects()
    }, [])


    return (
        <div className="flex flex-col w-full h-full gap-y-4 p-4">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Project Dashboard</h1>
            </div>
            <div className="flex justify-end">
                <div className="h-min w-min">
                    <NewIconButton 
                        title="New" 
                        size="sm" 
                        action={() => addProject()} 
                        type="button" 
                        icon=
                            {
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                            } 
                    />
                </div>            
            </div>
            <Dashboard projects={projects} handleProjectClick={handleProjectClick} sortColumn={sortColumn} sortDirection={sortDirection} handleSort={handleSort} />
        </div>
    )
}


function Dashboard({ projects, handleProjectClick, sortColumn, sortDirection, handleSort }: { projects: Projects, handleProjectClick: (projectID: number) => void, sortColumn: string, sortDirection: 'asc' | 'desc', handleSort: (column: SortableColumnNames) => void }) {

    const sortableColumns: SortableColumns = 
    [
        {
            columnName: 'ProjectObjectNameCode',
            alias: 'Name',
            type: 'identifier'
        },
        {
            columnName: 'Number',
            alias: 'Project No',
            type: 'identifier'
        },
        {
            columnName: 'Phase',
            type: 'identifier',
        },
        {
            columnName: 'LotPrice',
            alias: 'Lot Price',
            type: 'currency'
        },
        {
            columnName: 'House',
            type: 'identifier'
        },
        {
            columnName: 'HouseSalePrice',
            alias: 'House Sale Price',
            type: 'currency'
        },
        {     
            columnName: 'HouseCost',
            alias: 'House Cost',
            type: 'currency'
        },
        {
            columnName: 'MarginAmount',
            alias: 'Margin Amount',
            type: 'currency'
        },
        {
            columnName: 'MarginPercentage',
            alias: 'Margin Percentage',
            type: 'percentage'
        }];

    return (
        <div className={`overflow-auto w-full border-2 mx-auto`}>
        <table id="ProjectDashboard" className=" text-nowrap h-full w-full border-collapse table-auto ">
            <thead className="sticky top-0 z-10 bg-gray-200 ">
                <tr>
                {
                    sortableColumns.map((column, index) => <DashboardHeader key={`${column.columnName}_${index}`} columnName={column.columnName} alias={column.alias} type={column.type} sortColumn={sortColumn} sortDirection={sortDirection} handleSort={handleSort} />)
                }
                </tr>
            </thead>

            <tbody>
                {
                    projects ?
                        projects.map((project, index) => {
                            return (
                                <tr key={project.ProjectObjectNameCode + index} className="even:bg-gray-100 odd:bg-white">
                                    <td className="p-2">
                                        <button 
                                            className="text-blue-600 underline" 
                                            onClick={() => handleProjectClick(project.ProjectObjectID)}  // Handle click to navigate
                                        >
                                            {project.ProjectObjectNameCode}
                                        </button>
                                    </td>                                    
                                    <td className="p-2 text-left">{project.Number}</td>
                                    <td className="p-2 text-left">{project.Phase}</td>
                                    <td className="p-2 text-right">{Currency(project.LotPrice)}</td>
                                    <td className="p-2 text-left">{project.House}</td>
                                    <td className="p-2 text-right">{Currency(project.HouseSalePrice)}</td>
                                    <td className="p-2 text-right">{Currency(project.HouseCost)}</td>
                                    <td className="p-2 text-right">{Currency(project.MarginAmount)}</td>
                                    <td className="p-2 pr-4 text-right">{`${(project.MarginPercentage * 100).toFixed(2)}%`}</td>
                                </tr>
                            )
                        })
  
                        :
                        <tr className="text-center font-bold h-20 m-auto">
                            <td></td>
                            <td className="w-full text-center">Create a project.</td>
                            <td></td>
                        </tr>
                }
            </tbody>
        </table>
    </div>
    )
}

// Arrow icon component that changes based on the sorting state
function ArrowIcon({
    direction,
  }: {
    direction: "asc" | "desc";
  }) {
    return (
        direction === "asc" ?
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="18px" 
            viewBox="0 -960 960 960" 
            width="18px" 
            fill="#000000"
        >
            <path d="M440-80v-647L256-544l-56-56 280-280 280 280-56 57-184-184v647h-80Z"/>
        </svg>
        :
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            height="18px" 
            viewBox="0 -960 960 960" 
            width="18px" 
            fill="#000000"
        >
            <path d="M480-80 200-360l56-56 184 183v-647h80v647l184-184 56 57L480-80Z"/>
        </svg>
     
    );
  }

function DashboardHeader({columnName, alias, type, sortColumn, sortDirection, handleSort}:{columnName: SortableColumnNames, alias?: string, type: SortableColumnTypes, sortColumn: string, sortDirection: 'asc' | 'desc', handleSort: (column: SortableColumnNames) => void}) {
    return (
        <th className={`p-2 text-left sticky top-0 ${columnName === 'MarginPercentage' ? 'pr-4' : ''}`} onClick={() => handleSort(columnName)}>
            <div className={`flex items-center ${type === 'currency' || type === 'percentage' ? 'justify-end': 'justify-start'} gap-x-1`}>
            {alias ? alias : columnName}
            {
                sortColumn === columnName &&
                <ArrowIcon direction={sortDirection} />
            }
            </div>
        </th>
)
}

