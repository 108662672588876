
type NewIconButtonType = {
    type?: 'button' | 'submit'
    icon: any
    size: 'sm' | 'md' | 'lg'
    action?: Function,
    title?: string,
    disabled?: boolean,
}

export default function NewIconButton({ type, icon, size, action, title, disabled }: NewIconButtonType) {

    return (
        <>
            <button title={title} type={type || 'button'}
                className={` ${size === 'sm' ? 'w-[40px] h-[40px]' : size === 'md' ? 'w-[52px] h-[52px]' : 'w-[64px] h-[64px]'} bg-transparent  transition-all duration-300 ease-in-out
        enabled:hover:bg-slate-200 
        enabled:active:bg-slate-300`}
                onClick={action ? () => action() : () => null}
                disabled={disabled}
            >
                <div className={`w-[24px] h-[24px] m-auto flex justify-center`} >
                    {icon}
                </div >

            </button >

        </>

    )
}
