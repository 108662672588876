import { Events, JVSpec, JVSpecCalculations } from "./JVSpecTypes";
import { Currency } from "../../helpers/InputValueConverter";
import { useEffect, useState } from "react";
import FormatDate from "../../helpers/FormatDate";

type DrawScheduleValues = {
    [key: string]: {
        date: string,
        draw?: number,
        days?: number
    }
}

type DrawScheduleTotals = {
    drawTotal: string,
    daysTotal: number
}

export function ConstructionDrawTable(props: { form: JVSpec, calculations?: JVSpecCalculations }): JSX.Element {

    const [drawScheduleTotals, setDrawScheduleTotals] = useState<DrawScheduleTotals>({drawTotal: Currency('0'), daysTotal: 0});
    const [drawScheduleValues, setDrawScheduleValues] = useState<DrawScheduleValues>({});

    const getDrawScheduleValues = (events: Events) => {

        const tempDrawScheduleValues: DrawScheduleValues = {}

        events.forEach(event => {
            tempDrawScheduleValues[event.event] = {date: FormatDate(event.date), draw: event.balance ? event.balance : 0, days: event.days ?? 0};
        });

        setDrawScheduleValues(tempDrawScheduleValues);

    }

    const getDrawScheduleTotals = (events: Events) => {

        const tempDrawTotal: string = Currency(events.filter( item => item.event !== 'Land Acquisition' && item.event !== 'Construction Start' && item.event !== 'Construction End' && item.event !== 'Sale Date' && item.event !== 'Settlement Date').reduce((acc, event) => acc + Number(event.balance), 0));
        const tempDaysTotal: number = events.filter( item => item.event !== 'Land Acquisition' && item.event !== 'Construction Start' && item.event !== 'Construction End' && item.event !== 'Sale Date' && item.event !== 'Settlement Date').reduce((acc, event) => acc + (event.days ?? 0), 0);

        setDrawScheduleTotals({drawTotal: tempDrawTotal, daysTotal: tempDaysTotal});
    }

    useEffect(() => {
        if (props.calculations) {
            // Calculate Draw Schedule Totals
            getDrawScheduleValues(props.calculations.ProjectEvents ?? []);
            getDrawScheduleTotals(props.calculations.ProjectEvents ?? []);
        }
    }, [props.form, props.calculations]);

    return (
            <table id="PercentageDrawTable" className="h-full border-collapse">
                <thead className="">
                    <tr>
                        <th colSpan={5} className="p-4 border-b border-t border-l border-r border-black bg-gray-200 text-lg" >Draw Schedule</th>
                    </tr>
                    <tr>
                        <th className="border-r border-l border-black text-center pl-1 pr-1">Percentage Draw</th>
                        <th className="border-r border-black pl-1 text-left">Description</th>
                        <th className="border-r border-black pl-4 pr-1 text-right">Draw</th>
                        <th className="border-r border-black pl-4 pr-1 text-right">Days</th>
                        <th className="border-r border-black pl-4 pr-1 text-right">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="table-cell border-2 border-black text-center">10%</td>
                        <td className="table-cell border-2 border-black text-left">FOUNDATION - Masonry or Concrete Foundation Walls of Dwelling are complete and ready for Steel (Rough Grading, Waterproofing and backfill not necessary; Building Permit and As-Built Foundation Obtained)</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(drawScheduleValues['Foundation Draw']?.draw ?? 0)}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Foundation Draw']?.days ?? 0}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Foundation Draw']?.date ?? ''}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-center">20%</td>
                        <td className="table-cell border-2 border-black text-left">FRAMING - Framing System is Complete - Partitions, Subfloor, Exterior Wall and Roof Sheathings (Windows, Exterior Doors, Framing of finished basement and staircases not necessary).</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(drawScheduleValues['Framing Draw']?.draw ?? 0)}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Framing Draw']?.days ?? 0}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Framing Draw']?.date ?? ''}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-center">15%</td>
                        <td className="table-cell border-2 border-black text-left">ROUGH INS - HVAC Ductwork, Interior Waste and Water Supplies, Electrical Wire to Panel Location are Installed (Equipment, such as furnaces and A/C units, Water Heater, Electric Service Panel, etc., not necessary)</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(drawScheduleValues['Rough Ins Draw']?.draw ?? 0)}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Rough Ins Draw']?.days ?? 0}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Rough Ins Draw']?.date ?? ''}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-center">20%</td>
                        <td className="table-cell border-2 border-black text-left">EXTERIOR - Brick or Siding Veneers of Exterior Walls and Roofing are Complete (Stone and Siding Accent Veneers, Metal Accent Roofs, Gutters not necessary)</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(drawScheduleValues['Exterior Complete Draw']?.draw ?? 0)}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Exterior Complete Draw']?.days ?? 0}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Exterior Complete Draw']?.date ?? ''}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-center">10%</td>
                        <td className="table-cell border-2 border-black text-left">INTERIOR WALLS - All Drywall and Plastering are Complete and Surfaces are Ready for Paint</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(drawScheduleValues['Interior Walls Draw']?.draw ?? 0)}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Interior Walls Draw']?.days ?? 0}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Interior Walls Draw']?.date ?? ''}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-center">15%</td>
                        <td className="table-cell border-2 border-black text-left">TRIM - Exterior and Interior Doors are Hung and Kitchen Cabinets are Installed (Painting, Countertops, Flooring not necessary)</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(drawScheduleValues['Trim Draw']?.draw ?? 0)}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Trim Draw']?.days ?? 0}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Trim Draw']?.date ?? ''}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-center">10%</td>
                        <td className="table-cell border-2 border-black text-left">FINAL - When entire structure is complete – countertops, fixtures, hardware, appliances, HVAC equipment,flooring, retaining walls, patios, decks, covered porches, driveway, walks, backfill, rough grading and Landscaping, if applicable. (Exterior Items incomplete as a result of seasonal delays shall be deposited into escrow, the release of which, shall be conditioned solely on substantial completion of the same, and with subsequent disbursement direct to Seller)</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(drawScheduleValues['Final Draw']?.draw ?? 0)}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Final Draw']?.days ?? 0}</td>
                        <td className="table-cell border-2 border-black text-right">{drawScheduleValues['Final Draw']?.date ?? ''}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-center bg-gray-200 font-bold">100%</td>
                        <td className="table-cell border-2 border-black text-right bg-gray-200 font-bold">Total:</td>
                        <td className="table-cell border-2 border-black text-right bg-gray-200 font-bold">{drawScheduleTotals.drawTotal}</td>
                        <td className="table-cell border-2 border-black text-right bg-gray-200 font-bold">{drawScheduleTotals.daysTotal}</td>
                        <td className="table-cell border-2 border-black text-right bg-gray-200 font-bold">{''}</td>
                    </tr>
                </tbody>
            </table>
 );
}
