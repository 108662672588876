import { useEffect, useState } from "react";
import { CanvasItemProperties, ProjectItems, CanvasItemType, ItemType, ReferenceType } from "./ProjectTypes";
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from "./Toolbar";
import Canvas from "./Canvas";
import Properties from "./Properties/Properties";
import APIRequest from "../../helpers/CreateRequest";
import Report from "./Report";
import { usePrompt } from "../../hooks/usePrompt";
import { CurrencyToNumber } from "../../helpers/InputValueConverter";
import SnackBar from "../../components/molecules/SnackBar";

const initialProject: CanvasItemType = {
    id: 'project_-1',
    type: 'project',
    parentID: undefined,
    references: [],
    props: {
        id: 'project_-1',
        type: 'project',
        name: '',
        code: '',
        projectNo: undefined,
        projectPhase: 'Feasibility',
        feasibilityMethod: 'By Margin Amount',
        feasibilityGrossMarginAmount: '',
        feasibilityGrossMarginPercentage: '',
        totalIncome: '',
        totalExpenses: ''
    },
    children: [],
    dimensions: {
        height: 0,
        width: 0
    }
}

export default function Projects() {
    const location = useLocation();
    const [projectID, setProjectID] = useState<string>(initialProject.id);
    const [project, setProject] = useState<ProjectItems>(new Map().set(initialProject.id, initialProject));
    const [selectedItemID, setSelectedItemID] = useState<string>(projectID);
    const [message, setMessage] = useState<{ message: string, refresh: boolean }>({ message: '', refresh: false })
    const [isDirty, setIsDirty] = useState<boolean>(false)

    usePrompt({ when: isDirty, message: 'You have unsaved changes. Are you sure you want to leave?' })

    const changeSelectedItemID = (id: string) => setSelectedItemID(id)
    const changeProject = (id: string) => setProjectID(`project_${id}`)

    const addProjectItem = (type: ItemType, parentItem?: CanvasItemType) => {
        setIsDirty(true)
        setProject(prev => {

            // find the open key by type 
            let instance = 0
            const keys = [...prev.keys()].filter(key => key.startsWith(type))
            for (let i = 1; i < keys.length + 2; i++) {
                if (!keys.includes(`${type}_${i}`)) {
                    instance = i
                    break;
                }
            }

            const id = `${type}_${instance}`
            const size = getSizeForItem(type);

            const newItem: CanvasItemType = {
                id: id,
                type: type,
                props: getInitialProperties(type, id, instance, parentItem?.props.name),
                parentID: parentItem ? parentItem.id : undefined,
                references: [],
                children: [],
                dimensions: {
                    height: size.height,
                    width: size.width
                }
            }

            const newProject = new Map(prev)

            if (parentItem) {
                const newParentItem: CanvasItemType = {
                    ...parentItem, children: [...parentItem.children, id], dimensions: {
                        height: parentItem.dimensions.height + size.height / 2,
                        width: parentItem.dimensions.width + size.width / 2
                    },
                    references: getReferences(parentItem.references, newItem)

                }
                newProject.set(parentItem.id, newParentItem)
                newProject.set(id, newItem)
            } else {
                newProject.set(id, newItem)
            }

            return newProject
        })
    }

    const updateProject = async (item: CanvasItemType, project: ProjectItems) => {
        setIsDirty(true)
        let newProject = new Map(project)
        newProject.set(item.id, { ...item });

        if (item.references.length > 0) {
            item.references.forEach(ref => {
                const refItem = newProject.get(ref.dependentID)
                if (refItem) {
                    const newRefItem = { ...refItem, props: { ...refItem.props, [ref.dependentPropName]: item.props[ref.propName] } }
                    newProject.set(refItem.id, newRefItem)
                }
            })
        }

        newProject = await getCalculations(newProject) || newProject
        setProject(newProject)
    }

    // need deeper delete logic for deeply nested children. currently only deletes top level children
    // is should also check the children being deleted and delete their children
    const deleteProjectItem = async (itemID: string, project: ProjectItems) => {
        setIsDirty(true)
        let newProject = new Map(project)

        const children = newProject.get(itemID)?.children;

        // if selected is being deleted, clear selected
        if (selectedItemID === itemID) {
            setSelectedItemID('');
        }

        if (children) {
            children.forEach(childID => {
                if (selectedItemID === childID) {
                    setSelectedItemID('');
                }
            })
        }

        // if a child, remove from parent and updae prent size
        const parentID = newProject.get(itemID)?.parentID;
        if (parentID) {
            const parent = newProject.get(parentID);
            if (parent) {
                const newChildren = parent.children.filter(child => child !== itemID);
                newProject.set(parentID, {
                    ...parent,
                    children: newChildren,
                    dimensions: {
                        height: parent.dimensions.height - ((newProject.get(itemID)?.dimensions.height) || 0) / 2,
                        width: parent.dimensions.width - ((newProject.get(itemID)?.dimensions.width) || 0) / 2
                    }
                })
            }
        }

        // if a parent, remove children
        if (children) {
            children.forEach(childID => {
                newProject.delete(childID);
            })
        }

        newProject.delete(itemID);

        // get calculations 
        newProject = await getCalculations(newProject) || newProject

        setProject(newProject)
    }

    const getCalculations = async (project: ProjectItems) => {
        const projectObject = Object.fromEntries(project);
        const result = await new APIRequest('/project/calculations', 'POST', null, { project: projectObject }).GenerateRequest();

        if (result.status === 200) {
            const body = await result.json()
            const project = body.project

            let data;
            if (typeof project === 'string') {
                try {
                    data = JSON.parse(project);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    return;
                }
            } else {
                data = project;
            }

            // Ensure data is in the correct format for Map
            const projectData = Array.isArray(data) ? data : Object.entries(data);

            const returnProject: ProjectItems = new Map(projectData);
            return returnProject;
        }
    }

    const getProject = async (id: string) => {
        const projectIDNumber = id.split('_')[1]
        try {
            const result = await new APIRequest(`/projectObjects/unit`, 'POST', null, { id: projectIDNumber }).GenerateRequest();
            if (result.status === 200) {
                const body = await result.json()

                if (body.status !== 200) throw body.message

                const record = body.recordset[0]
                const project = record.ObjectModel

                let data;
                if (typeof project === 'string') {
                    try {
                        data = JSON.parse(project);
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                        return;
                    }
                } else {
                    data = project;
                }

                // Ensure data is in the correct format for Map
                const projectData = Array.isArray(data) ? data : Object.entries(data);

                let newProject: ProjectItems = new Map(projectData)

                const newID = `project_${record.ProjectObjectID}`
                newProject.set(newID, {
                    id: newID,
                    type: 'project',
                    parentID: undefined,
                    references: [],
                    props: {
                        id: newID,
                        type: 'project',
                        name: record.Name,
                        code: record.Code,
                        projectNo: record.Number,
                        projectPhase: record.Phase,
                        feasibilityMethod: 'By Margin Amount',
                        feasibilityGrossMarginAmount: '',
                        feasibilityGrossMarginPercentage: '',
                        totalIncome: '',
                        totalExpenses: ''
                    },
                    children: [],
                    dimensions: {
                        height: 0,
                        width: 0
                    }
                })

                // get calculations
                newProject = await getCalculations(newProject) || newProject

                setProject(newProject)
            }
        } catch (error) {
            console.error('Error getting project:', error);
        }

    }

    const saveProject = async (isDuplicate = false) => {
        try {

            // create project header
            const projectItem = project.get(projectID)

            // Initialize the accumulators for the required values
            let lotPriceTotal = 0;
            let houses: string[] = [];
            let houseSalePrice = 0;
            let houseCost = 0;

            // Iterate over the values once
            for (const item of project.values()) {
                if (item.type === 'lot') {
                    const cost = CurrencyToNumber(item.props.cost) || 0;
                    lotPriceTotal += cost;
                } else if (item.type === 'structure') {
                    houses.push(item.props.name || '');
                    houseSalePrice += CurrencyToNumber(item.props.salePrice) || 0;
                    houseCost += CurrencyToNumber(item.props.cost) || 0;
                }
            }

            // Join houses into a string
            const housesString = houses.join(', ');

            // remove project from project object
            project.delete(projectID)

            // prep for serialization
            const projectObject = Object.fromEntries(project);

            const body = {
                id: projectID.split('_')[1],
                Name: isDuplicate ? `${projectItem?.props.name} - Copy` : projectItem?.props.name,
                Code: isDuplicate ? `${projectItem?.props.code} - Copy` : projectItem?.props.code,
                Number: projectItem?.props.projectNo,
                Phase: projectItem?.props.projectPhase,
                LotPrice: lotPriceTotal,
                House: housesString,
                HouseSalePrice: houseSalePrice,
                HouseCost: houseCost,
                ObjectModel: JSON.stringify(projectObject)
            }
            const method = projectID === 'project_-1' || isDuplicate ? 'POST' : 'PUT'
            const result = await new APIRequest('/projectObjects', method, null, body).GenerateRequest();

            if (result.status === 200) {
                const body = await result.json()

                if (body.status === 200) {
                    if (method === 'POST') setProjectID(`project_${body.id}`)
                    setMessage(prev => { return { message: 'Project saved', refresh: !prev.refresh } })
                    setIsDirty(false)
                } else throw body.message
            } else throw result.statusText
        } catch (err) {
            setMessage(prev => { return { message: 'Failed to save project', refresh: !prev.refresh } })
            console.log(err)
        }
    }

    const deleteProject = async () => {
        try {
            const projectIDNumber = projectID.split('_')[1]
            const result = await new APIRequest(`/projectObjects`, 'DELETE', null, { id: projectIDNumber }).GenerateRequest();

            if (result.status === 200) {
                const body = await result.json()

                if (body.status === 200) {
                    setProjectID('project_-1')
                    setProject(new Map().set('project_-1', initialProject))
                    setMessage(prev => { return { message: 'Project deleted', refresh: !prev.refresh } })
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (projectID !== 'project_-1') getProject(projectID)
        else setProject(new Map().set('project_-1', initialProject))
    }, [projectID])

    useEffect(() => {
        if (location.state && location.state.projectID) {
            setProjectID(`project_${location.state.projectID}`)
        }
    }, [])

    // change to new objectwhen projectID changes
    useEffect(() => setSelectedItemID(projectID), [projectID])

    return (
        <div className="flex flex-col w-full h-full">
            <Toolbar projectID={projectID} changeProject={changeProject} saveProject={saveProject} deleteProject={deleteProject} />
            <div className="w-full flex flex-grow justify-between flex-nowrap overflow-auto ">
                <Canvas
                    project={project}
                    projectID={projectID}
                    selectedItemID={selectedItemID}
                    addProjectItem={addProjectItem}
                    changeSelectedItemID={changeSelectedItemID}
                    deleteProjectItem={deleteProjectItem}
                />

                <div className="flex flex-col min-w-[300px] w-1/5 max-w-[500px] drop-shadow-lg z-20 bg-white border-gray-700 resize-x overflow-auto">
                    <Properties
                        project={project}
                        selectedItemID={selectedItemID}
                        updateProject={updateProject}
                    />

                    <Report project={project} projectID={projectID} />
                </div>
            </div>
            <SnackBar message={message} />
        </div>
    )
}


// Helper function to calculate size based on item type and nest level
function getSizeForItem(type: ItemType) {
    if (type === 'lot') {
        return { height: 200, width: 200 };
    } else if (type === 'structure') {
        return { height: 150, width: 150 };
    }
    return { height: 100, width: 100 };
}

function getInitialProperties(type: ItemType, id: string, instance: number, parentID?: string): CanvasItemProperties {
    if (type === 'lot') {
        return {
            id: id,
            type: 'lot',
            name: `Lot ${instance}`,
            cost: ''
        };
    } else {
        return {
            id: id,
            type: 'structure',
            name: `House ${instance}`,
            salePrice: '',
            cost: '',
            lot: parentID || ''
        };
    }
}


const getReferences = (pastReferences: ReferenceType, child: CanvasItemType) => {
    const parentPropName = 'name'
    const childPropName = 'lot'

    const newReferences = pastReferences

    newReferences.push({ propName: parentPropName, dependentID: child.id, dependentPropName: childPropName })

    return newReferences
}