import { Fragment, useState, useEffect } from 'react'
import APIRequest from "../../../../helpers/CreateRequest"
import { option } from "../HouseHistoryReport/HouseHistory"
import { month } from '../../../../Types/FormTypes'
import { Currency } from "../../../../helpers/InputValueConverter"
import MultiSelectField from "../../../../components/atoms/forms/SimpleFields/MultiSelectField"
import FormatDate from "../../../../helpers/FormatDate"
import { saleStatus, eventType, barChartData, lineChartData } from '../ReportTypes'
import ActualPerformanceBarChart from './BarChart'
import LineChart from './LineChart'


const monthOrder = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]
const eventTypeOrder = [
    'Sale', 'Start', 'Settlement'
]

type actualPerformanceFilters = {
    community: string[];
    year: string[];
    eventType: string[];
}

type actualPerformanceOptions = {
    community: option[];
    year: option[];
    eventType: option[];
}

type actualPerformanceRecordset = {
    year: string;
    monthName: month;
    eventDate: string;
    saleEventDate: string;
    startEventDate: string;
    settlementEventDate: string;
    communityNameCode: string;
    houseCode: string;
    eventType: eventType;
    saleStatus: saleStatus;
    revenue: number;
    gross: number;
}[]

type house = {
    year: string;
    monthName: month;
    communityNameCode: string;
    houseCode: string;
    eventType: eventType;
    eventDate: string;
    saleEventDate: string;
    startEventDate: string;
    settlementEventDate: string;
    saleStatus: saleStatus;
    revenue: number;
    gross: number;
}

type community = {
    houses: house[];
}

type communityMap = Map<string, community>;

type totals = {
    [key: string]: {
        [key: string]: {
            count: number
            revenue: number
            gross: number
        }
    }
}

export default function ActualPerformanceReport({ScenarioID, triggerNewData}: {ScenarioID: string, triggerNewData: boolean}) {
    const currentYear = new Date().getFullYear()
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based
    const currentDay = currentDate.getDate();
    const [actualPerformanceRecordset, setActualPerformanceRecordset] = useState<actualPerformanceRecordset>([])
    const [barChartData, setBarChartData] = useState<barChartData>([])
    const [communityMap, setCommunityMap] = useState<communityMap>(new Map())
    const [totals, setTotals] = useState<{ communityTotals: totals }>({ communityTotals: {} })
    const [lineChartDataSales, setLineChartDataSales] = useState<lineChartData>([])
    const [lineChartDataStarts, setLineChartDataStarts] = useState<lineChartData>([])
    const [lineChartDataSettlements, setLineChartDataSettlements] = useState<lineChartData>([])

    const [filters, setFilters] = useState<actualPerformanceFilters>({
        community: [],
        year: [currentYear.toString() ],
        eventType: ['Sale', 'Start', 'Settlement']
    })
    const [options, setOptions] = useState<actualPerformanceOptions>({
        community: [],
        year: [],
        eventType: []
    })
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleData = (actualPerformanceRecordset: actualPerformanceRecordset) => {

        setOptions(getFilterOptions(actualPerformanceRecordset))

        const tempCommunityMap: communityMap = new Map()
        const tempBarChartData: barChartData = []
        const tempLineChartDataSales: lineChartData = []
        const tempLineChartDataStarts: lineChartData = []
        const tempLineChartDataSettlements: lineChartData = []

        const houses: actualPerformanceRecordset = filterData(actualPerformanceRecordset)

        houses.forEach(house => {

            /////////////////////
            // Line Chart Data //
            /////////////////////
            let yearFound = false

            // Select the correct tempLineChartData array based on eventType
            let targetDataArray: lineChartData = [];

            if (house.eventType === 'Sale') {
                targetDataArray = tempLineChartDataSales;
            } else if (house.eventType === 'Start') {
                targetDataArray = tempLineChartDataStarts;
            } else if (house.eventType === 'Settlement') {
                targetDataArray = tempLineChartDataSettlements;
            }

            for (let i = 0; i < targetDataArray.length; i++) {
                if (targetDataArray[i].id === house.year) {
                    yearFound = true
                    for (let j = 0; j < targetDataArray[i].data.length; j++) {
                        if (targetDataArray[i].data[j].x === house.monthName) {
                            targetDataArray[i].data[j].y = (targetDataArray[i].data[j].y ?? 0) + 1

                            for (let k = j + 1; k < targetDataArray[i].data.length; k++) {
                                targetDataArray[i].data[k].y = (targetDataArray[i].data[k].y ?? 0) + 1
                            }
                            break;
                        }
                    }
                }
            }
            if (!yearFound) {
                targetDataArray.push({
                    id: house.year,
                    data: [
                        { x: 'January', y: house.monthName === 'January' ? 1 : null }
                        ,{ x: 'February', y: house.monthName === 'February' ? 1 : null }
                        ,{ x: 'March', y: house.monthName === 'March' ? 1 : null }
                        ,{ x: 'April', y: house.monthName === 'April' ? 1 : null }
                        ,{ x: 'May', y: house.monthName === 'May' ? 1 : null }
                        ,{ x: 'June', y: house.monthName === 'June' ? 1 : null }
                        ,{ x: 'July', y: house.monthName === 'July' ? 1 : null }
                        ,{ x: 'August', y: house.monthName === 'August' ? 1 : null }
                        ,{ x: 'September', y: house.monthName === 'September' ? 1 : null }
                        ,{ x: 'October', y: house.monthName === 'October' ? 1 : null }
                        ,{ x: 'November', y: house.monthName === 'November' ? 1 : null }
                        ,{ x: 'December', y: house.monthName === 'December' ? 1 : null }
                    ]
                })
                for (let i = 0; i < targetDataArray[targetDataArray.length - 1].data.length; i++) {
                    if (targetDataArray[targetDataArray.length - 1].data[i].y === 1) {
                        for (let j = i + 1; j < targetDataArray[targetDataArray.length - 1].data.length; j++) {
                            targetDataArray[targetDataArray.length - 1].data[j].y = (targetDataArray[targetDataArray.length - 1].data[j].y ?? 0) + 1
                        }
                        break;
                    }
                }
            }

        })

        setLineChartDataSales(tempLineChartDataSales.sort((a, b) => Number(a.id) - Number(b.id)))
        setLineChartDataStarts(tempLineChartDataStarts.sort((a, b) => Number(a.id) - Number(b.id)))
        setLineChartDataSettlements(tempLineChartDataSettlements.sort((a, b) => Number(a.id) - Number(b.id)))

        ////////////////
        // Table Data //
        ////////////////

        // create map that will hold all houses filtered down by community, to house 
        houses.forEach(house => {
            // check if community exists
            const community = tempCommunityMap.get(house.communityNameCode)
            if (community) {
                community.houses.push(house)
            } else {
                tempCommunityMap.set(house.communityNameCode, { houses: [house] })
            }
        })

        setCommunityMap(tempCommunityMap)
        setTotals(calculateTotals(houses))

        ////////////////////
        // Bar Chart Data //
        ////////////////////

        // create bar chart data that will hold the count of each event type by year
        houses.forEach((record) => {

                let OnOrBeforeCurrentDate = isOnOrBeforeCurrentDate(record.eventDate)

                if (OnOrBeforeCurrentDate) {
                    let eventTypeFound = false;

                    for (let i = 0; i < tempBarChartData.length; i++) {
                        if (tempBarChartData[i].eventType === record.eventType) {
                            eventTypeFound = true;

                            if (record.year in tempBarChartData[i]) {
                                tempBarChartData[i][record.year]++
                            } else {
                                tempBarChartData[i][record.year] = 1
                                tempBarChartData[i][(record.year + 'Color').toString()] = "hsl(100, 70%, 50%)"
                            }
                        }                        
                    }
                    if (!eventTypeFound) {
                        tempBarChartData.push({
                            eventType: record.eventType,
                            [record.year]: 1,
                            [(record.year + 'Color').toString()]: "hsl(100, 70%, 50%)"
                        })
                    }
                }

        })

        setBarChartData(tempBarChartData)

    }
    const isOnOrBeforeCurrentDate = (date: string) => {

        const formattedDate = FormatDate(date)

        const [month, day, year] = formattedDate.split('/').map(Number); // Parse the date string
        // Compare year, month, and day
        if (year < currentYear || year === currentYear && (month < currentMonth || (month === currentMonth && day < currentDay))) {
            return true; // The date is before the current month and day
        }
        return false; // The date is not before
    };
    
    const calculateTotals = (houses: house[]) => {
        let communityTotals: totals = {}
        let eventYearTotals: { [key: string]: { count: number, revenue: number, gross: number } } = {}

        const updateTotals = (totals: any, key: string, eventType: eventType, year: string, house: house) => {
            if (!totals[key]) {
                totals[key] = {}
            }
            if (!totals[key][`${eventType}${year}`]) {
                totals[key][`${eventType}${year}`] = {
                    count: 0,
                    revenue: 0,
                    gross: 0,
                }
            }
            totals[key][`${eventType}${year}`].count++
            totals[key][`${eventType}${year}`].revenue += house.revenue
            totals[key][`${eventType}${year}`].gross += house.gross
        }

        for (let i = 0; i < houses.length; i++) {
            const house = houses[i]
            const year = house.year
            const community = house.communityNameCode
            const eventType = house.eventType

            updateTotals(communityTotals, community, eventType, year, house)
            updateTotals(eventYearTotals, year, eventType, year, house)            
        }
        return { communityTotals: communityTotals }
    }

    const getFilterOptions = (actualPerformanceRecordset: actualPerformanceRecordset) => {
        // get the unique values for each filter
        const tempOptions: actualPerformanceOptions = {
            community: [],
            year: [{name: new Date().getFullYear().toString(), value: new Date().getFullYear().toString()}],
            eventType: []
        }

        actualPerformanceRecordset.forEach(record => {

            if (!tempOptions.community.some(option => option.value === record.communityNameCode)) tempOptions.community.push({ name: record.communityNameCode, value: record.communityNameCode })
            if (!tempOptions.year.some(option => option.value === record.year)) tempOptions.year.push({ name: record.year, value: record.year })
            if (!tempOptions.eventType.some(option => option.value === record.eventType)) tempOptions.eventType.push({ name: record.eventType, value: record.eventType })

        })

        // order the options
        tempOptions.community.sort((a, b) => a.value.localeCompare(b.value))
        tempOptions.year.sort((a, b) => a.value.localeCompare(b.value))
        //tempOptions.eventType.sort((a, b) => a.value.localeCompare(b.value))

        return tempOptions
    }

    // filter the data based on the selected filters
    const filterData = (actualPerformanceRecordset: actualPerformanceRecordset) => {
        let filteredRecords = actualPerformanceRecordset

        // remove houses with the same house code in the same community name
        /*
        filteredRecords = filteredRecords.filter((house, index, self) => {
            return index === self.findIndex(h => h.houseCode === house.houseCode && h.communityNameCode === house.communityNameCode)
        })
        */
        // filter by filters state 
        filteredRecords = filteredRecords.filter(house => {
            if (filters.community.length > 0 && !filters.community.includes(house.communityNameCode)) return false
            if (filters.year.length > 0 && !filters.year.includes(house.year)) return false
            if (filters.eventType.length > 0 && !filters.eventType.includes(house.eventType)) return false
            return true
        })

        return filteredRecords
    }

    const getActualPerformanceRecordset = async () => {

        const tempActualPerformanceRecordset: actualPerformanceRecordset = [];
        
        if (ScenarioID) {
            try {
                const result = await new APIRequest('/scenariosSalesForecastEvents/actualPerformance', "POST", null, { scenarioID: ScenarioID} ).GenerateRequest()

                if (result.status === 200) {
                    const body = await result.json()

                    if (body.status === 200) {

                        for(let i = 0; i < body.recordset.length; i++) {

                            tempActualPerformanceRecordset.push({
                                year: body.recordset[i].EventYear.toString(),
                                monthName: body.recordset[i].EventMonthName,
                                eventDate: body.recordset[i].EventDate,
                                saleEventDate: body.recordset[i].SaleEventDate,
                                startEventDate: body.recordset[i].StartEventDate,
                                settlementEventDate: body.recordset[i].SettlementEventDate,
                                communityNameCode: body.recordset[i].CommunityNameCode,
                                houseCode: body.recordset[i].HouseCode,
                                eventType: body.recordset[i].EventType,
                                saleStatus: body.recordset[i].SaleStatus,
                                revenue: body.recordset[i].TotalSalesPriceAmount,
                                gross: body.recordset[i].GrossProfitAmount
                            })
                        }
                        setActualPerformanceRecordset(tempActualPerformanceRecordset || []);
                        handleData(tempActualPerformanceRecordset);

                        //console.log(tempActualPerformanceRecordset)

                    } else throw body.message
                } else throw result.statusText

            } catch (err) {
                console.log(err)
            }
        }
    
    }

    // get Actual Performance Items for the selected scenario
    useEffect(() => {

        getActualPerformanceRecordset()

    }, [ScenarioID, triggerNewData])
        
    useEffect(() => {
        if (actualPerformanceRecordset) handleData(actualPerformanceRecordset)
    }, [filters])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth); // Trigger a re-render
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {

        if (filters.eventType.length !== options.eventType.length) {
             setFilters( prevFilters => ({...prevFilters, eventType: options.eventType.map(option => option.value) }))
        }

    }, [options])
    

    return (
        <div className="flex w-full flex-col h-full gap-y-4 max-w-[1600px] mx-auto">
            {/* header */}
            <div className="w-full h-[88px] gap-6 items-center bep-shadow-xl rounded-lg p-4 flex">
                <span className="headline-large">Actual Performance</span>
                <MultiSelectField name="community" label="Community" setValue={setFilters} size="md" options={options.community} selected={filters?.community || []} sort={sortCommunities} />
                <MultiSelectField name="year" label="Year" setValue={setFilters} size="sm" options={options.year} selected={filters?.year || []} sort={sortYears} />
                <MultiSelectField name="eventType" label="Event Type" setValue={setFilters} size="sm" options={options.eventType} selected={filters?.eventType || []} sort={sortEvents} />
            </div>

            {/* body */}
            <div className="flex flex-grow flex-col">

                <div className=' flex w-full gap-y-2 gap-x-4 h-full'>
                    <div className='flex flex-col bep-shadow-xl p-4 rounded-lg items-center w-full h-full'>

                        <div className='flex flex-grow flex-row pt-4 pl-4 pr-4  gap-x-4 items-center justify-center w-full h-full'>
                            <div className='flex flex-col bep-shadow-xl rounded-lg p-2 w-full h-[616px]'>
                                <div className='text-center'>
                                    <span className="text-xl">Sales, Starts and Settlements</span>
                                </div>
                                <div className='text-center'>
                                    <span className="text-sm">Actual Count by Current Calendar Date by Year</span>
                                </div>
                                <ActualPerformanceBarChart data={barChartData} keys={filters.year.map(String)} windowWidth={windowWidth}/>
                            </div>
                            {
                            lineChartDataSales.length !== 0 || lineChartDataStarts.length !== 0 || lineChartDataSettlements.length !== 0 ?

                            <div className='flex flex-col items-center justify-center w-full h-full'>
                                {
                                filters.eventType.includes('Sale') ?
                                
                                <div className={`bep-shadow-xl rounded-lg w-full p-2 ${filters.eventType.length === 1 ? 'h-[616px]' : filters.eventType.length === 2 ? 'h-[300px]' : 'h-[200px]'} ${filters.eventType.includes('Start') && filters.eventType.includes('Settlement') ? 'mb-1' : (!filters.eventType.includes('Start') && filters.eventType.includes('Settlement')) || (filters.eventType.includes('Start') && !filters.eventType.includes('Settlement')) ? 'mb-2' : ''}`}>
                                    <LineChart data={lineChartDataSales} axisLeftLegend="Sales" yFormat='>-.0f' windowWidth={windowWidth} />
                                </div>
                                : <></>
                                }
                                {
                                filters.eventType.includes('Start') ?
                                
                                <div className={`bep-shadow-xl rounded-lg w-full p-2 ${filters.eventType.length === 1 ? 'h-[616px]' : filters.eventType.length === 2 ? 'h-[300px]' : 'h-[200px]'} ${filters.eventType.includes('Sale') && filters.eventType.includes('Settlement') ? 'mt-1 mb-1' : (!filters.eventType.includes('Sale') && filters.eventType.includes('Settlement')) ? 'mb-2' : (filters.eventType.includes('Sale') && !filters.eventType.includes('Settlement')) ? 'mt-2' : ''}`}>
                                    <LineChart data={lineChartDataStarts} axisLeftLegend="Starts" yFormat='>-.0f' windowWidth={windowWidth} />
                                </div>
                                : <></>
                                }
                                {
                                filters.eventType.includes('Settlement') ?
                                <div className={`bep-shadow-xl rounded-lg w-full p-2 ${filters.eventType.length === 1 ? 'h-[616px]' : filters.eventType.length === 2 ? 'h-[300px]' : 'h-[200px]'} ${filters.eventType.includes('Sale') && filters.eventType.includes('Start') ? 'mt-1' : (!filters.eventType.includes('Sale') && filters.eventType.includes('Start')) || (filters.eventType.includes('Sale') && !filters.eventType.includes('Start')) ? 'mt-2' : ''}`}>
                                    <LineChart data={lineChartDataSettlements} axisLeftLegend="Settlements" yFormat='>-.0f' windowWidth={windowWidth}/>
                                </div>
                                : <></>
                                }
                            </div>
                            : <div className="flex flex-grow justify-center items-center w-full h-full">
                                <span className="text-xl">Please select a scenario.</span>
                            </div>
                            }
                        </div>
                        <div className='flex flex-col p-4 rounded-lg gap-y-3 items-center w-full'>
                            <ActualPerformanceTable data={communityMap} filters={filters} options={options} totals={totals}/>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

function ActualPerformanceTable({ data, filters, options, totals }: { data: communityMap, filters: actualPerformanceFilters, options: actualPerformanceOptions, totals: any }) {
    const [openCommunities, setOpenCommunities] = useState<string[]>([])

    const handleClickedObject = (name: string) => {
        if (openCommunities.includes(name)) {
            setOpenCommunities(openCommunities.filter(community => community !== name))
        } else {
            setOpenCommunities([...openCommunities, name])
        }
    }

    const getTotalsByYearByEvent = (totals: any, year: number, event: string) => {

        let total = { count: 0, revenue: 0, gross: 0 }

        for (const community in totals.communityTotals) {
            if (totals.communityTotals[community][`${event}${year}`]) {
                total.count += totals.communityTotals[community][`${event}${year}`].count
                total.revenue += totals.communityTotals[community][`${event}${year}`].revenue
                total.gross += totals.communityTotals[community][`${event}${year}`].gross
            }
        }

        return total

    }
    
    return (

        <div className={`overflow-auto max-w-full  bep-shadow-xl rounded-lg w-full border-2 mx-auto`}>
        <table id="ActualPerformanceTable" className=" text-nowrap h-full   border-collapse ">
            <thead className="sticky top-0 z-10  ">

                <tr>
                    <th className="sticky left-0 z-10"></th>
                    {
                        (filters.year.length > 0 ? filters : options).year
                        .map(year => {
                            let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType
                            const tempYear = parseInt(typeof year === 'string' ? year : year.value)

                            return (
                                <th colSpan={tempFilters.length * 5} className=" p-2 text-left sticky top-0" key={'tableHeadYear' + tempYear}>{tempYear}</th>
                            )
                        })
                    }
                </tr>

                <tr>
                    <th className="sticky left-0 z-10"></th>
                    {(filters.year.length > 0 ? filters : options).year.map(year => {
                        const tempYear = parseInt(typeof year === 'string' ? year : year.value)
                        let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType

                        return tempFilters
                        .map(event => {
                            let tempEvent = typeof event === 'string' ? event : event.value
                            return <th colSpan={5} key={'TableHeadEvent' + tempYear + tempEvent} className=" p-2 text-left">{tempEvent}</th>
                        })
                    })
                    }
                </tr>

                <tr>
                    <th className="sticky left-0 z-10"></th>
                    {(filters.year.length > 0 ? filters : options).year.map(year => {
                        let tempYear = parseInt(typeof year === 'string' ? year : year.value)

                        let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType

                        return (
                            tempFilters.map(event => {
                                let tempEvent = typeof event === 'string' ? event : event.value

                                return (
                                    <Fragment key={'tableHeadDataHeaders' + tempYear.toString() + tempEvent}>
                                        <th className="p-2">Count</th>
                                        <th className="p-2">Revenue</th>
                                        <th className="p-2">Gross</th>
                                        <th className="p-2">Date</th>
                                        <th className="p-2">Sale Status</th>
                                    </Fragment>
                                )
                            })
                        )
                    })
                    }
                </tr>
            </thead>

            <tbody>
                {
                    data ?

                        [...data.keys()]
                        .sort((a, b) => a.localeCompare(b))
                        .map((community, comIndex) => {

                            return <Fragment key={'community' + community + comIndex}>
                                <tr className="even:bg-gray-200  odd:bg-white">
                                    <td className="hover:bg-blue-100  p-1 cursor-pointer text-left font-bold sticky left-0 bg-inherit" onClick={() => handleClickedObject(community)}>{openCommunities.includes(community) ? '-' : '+'} {community}</td>

                                    {
                                        (filters.year.length > 0 ? filters : options).year.map(year => {
                                            let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType
                                            let tempYear = parseInt(typeof year === 'string' ? year : year.value)

                                            return tempFilters.map(event => {
                                                let tempEvent = typeof event === 'string' ? event : event.value
                                                let communityTotals = totals.communityTotals[community][`${tempEvent}${tempYear}`] || {}

                                                return <Fragment key={'communityData' + community + tempYear + tempEvent}>
                                                    <td className="table-cell">{communityTotals.count}</td>
                                                    <td className="table-cell">{Currency(communityTotals.revenue)}</td>
                                                    <td className="table-cell">{Currency(communityTotals.gross)}</td>
                                                    <td className="table-cell"></td>
                                                    <td className="table-cell"></td>
                                                </Fragment>
                                            })
                                        })
                                    }
                                </tr>

                                {
                                    openCommunities.includes(community) && (data.get(community)?.houses || [])
                                    .filter((house, index, self) => 
                                        index === self.findIndex(h => 
                                            h.houseCode === house.houseCode && 
                                            h.communityNameCode === house.communityNameCode
                                        ))
                                    .sort((a, b) => a.houseCode.localeCompare(b.houseCode))
                                    .map(house => (
                                        <HouseRow key={'house' + community + house.houseCode} house={house} filters={filters} options={options} community={community} />
                                    ))
                                }
                            </Fragment>
                        })

                        :
                        <tr className="text-center font-bold h-20 m-auto">
                            <td></td>
                            <td className="w-full text-center">Please select a scenario</td>
                            <td></td>
                        </tr>
                }

                {
                    data ? (

                        <tr className="bg-gray-300">
                            <td className="p-2 font-bold">Totals</td>

                            {(filters.year.length > 0 ? filters : options).year.map(year => {
                                let tempYear = parseInt(typeof year === 'string' ? year : year.value)
        
                                let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType
        
                                return (
                                    tempFilters.map(event => {
                                        let tempEvent = typeof event === 'string' ? event : event.value
        
                                        return (
                                            <Fragment key={'tableHeadDataHeaders' + tempYear.toString() + tempEvent}>
                                                <td className="table-cell font-bold">{Number(getTotalsByYearByEvent(totals, tempYear, tempEvent).count)}</td>
                                                <td className="table-cell font-bold">{Currency(getTotalsByYearByEvent(totals, tempYear, tempEvent).revenue)}</td>
                                                <td className="table-cell font-bold">{Currency(getTotalsByYearByEvent(totals, tempYear, tempEvent).gross)}</td>
                                                <td className="table-cell"> </td>
                                                <td className="table-cell"> </td>
                                            </Fragment>
                                        )
                                    })
                                )
                            })
                            }
                        </tr>
                    ) :
                        <></>
                }

            </tbody>
        </table>
    </div>
    )
}

type HouseRowProps = {
    house: house;
    filters: actualPerformanceFilters;
    options: actualPerformanceOptions;
    community: string;
};

function HouseRow({ house, filters, options, community }: HouseRowProps) {

    return (
        <tr className="even:bg-gray-200 odd:bg-white">
            <td className="hover:bg-blue-100 pl-10 text-left sticky left-0 bg-inherit">{house.houseCode}</td>
            {
                (filters.year.length > 0 ? filters : options).year.map(year => {
                    let tempYear = parseInt(typeof year === 'string' ? year : year.value)
                    let tempFilters = (filters.eventType.length > 0 ? filters : options).eventType

                    const validSale = new Date(house.saleEventDate).getFullYear() === tempYear
                    const validStart = new Date(house.startEventDate).getFullYear() === tempYear
                    const validSettlement = new Date(house.settlementEventDate).getFullYear() === tempYear

                    return tempFilters.map(event => {
                        let tempEvent = typeof event === 'string' ? event : event.value
                        const validHouse = (tempEvent === 'Sale' ? validSale : tempEvent === 'Start' ? validStart : tempEvent === 'Settlement' ? validSettlement : false)

                        return <Fragment key={'houseData' + community + house.houseCode + tempYear + tempEvent}>
                            <td className="table-cell">{validHouse ? 1 : ''}</td>
                            <td className="table-cell">{validHouse ? Currency(house.revenue) : ''}</td>
                            <td className="table-cell">{validHouse ? Currency(house.gross) : ''}</td>
                            <td className="table-cell">{validHouse && tempEvent === 'Sale' ? FormatDate(house.saleEventDate) : validHouse && tempEvent === 'Start' ? FormatDate(house.startEventDate) : validHouse && tempEvent === 'Settlement' ? FormatDate(house.settlementEventDate) : ''}</td>
                            <td className="table-cell">{validHouse ? house.saleStatus : ''}</td>
                        </Fragment>
                    })
                })}
        </tr>
    )
}

export const colorFunction = (id: string): string => {
    const colors: Record<string, string> = {
        '2010': '#E63946', // Red
        '2011': '#F1FAEE', // Light Mint
        '2012': '#F1C40F', // Bright Yellow
        '2013': '#F39C12', // Bright Orange
        '2014': '#2ECC71', // Green
        '2015': '#1F77B4', // Blue
        '2016': '#9B59B6', // Purple
        '2017': '#D35400', // Dark Orange
        '2018': '#16A085', // Teal
        '2019': '#34495E', // Dark Gray
        '2020': '#E67E22', // Pumpkin Orange
        '2021': '#2980B9', // Light Blue
        '2022': '#C0392B', // Crimson Red
        '2023': '#8E44AD', // Medium Purple
        '2024': '#27AE60', // Emerald Green
        '2025': '#D9A44D', // Gold
        '2026': '#F5B041', // Soft Orange
        '2027': '#7D3C98', // Purple
        '2028': '#F39C12', // Bright Yellow-Orange
        '2029': '#BDC3C7', // Light Gray
    };        return colors[id] || '#000'; // Default color if ID not found
};

const sortYears = (years: string[]): string[] => {
    return years.sort((a, b) => Number(a) - Number(b))
}

const sortEvents = (events: string[]): string[] => {

    const eventTypeOrder = [
        'Sale', 'Start', 'Settlement'
    ]
    
    return events.sort((a, b) => eventTypeOrder.indexOf(a) - eventTypeOrder.indexOf(b))
}
const sortCommunities = (communities: string[]): string[] => {
    return communities.sort((a, b) => a.localeCompare(b))
}