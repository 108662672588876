import { useDrag } from "react-dnd";
import { ItemType } from "./ProjectTypes";
import { useMemo, useState } from "react";
import SelectField from "../../components/atoms/forms/SimpleFields/SelectField";
import NewIconButton from "../../components/atoms/NewIconButton";
import { Link } from "react-router-dom";

type ToolbarProps = {
    projectID: string;
    changeProject: (id: string) => void;
    saveProject: (isDuplicate?: boolean) => void;
    deleteProject: () => void;
}

export default function Toolbar({ projectID, changeProject, saveProject, deleteProject }: ToolbarProps) {
    const [isOpen, setIsOpen] = useState(true);
    const projectIDNumber = useMemo(() => projectID.split('_')[1], [projectID]);

    return (
        <div className={`relative flex flex-wrap bg-white ${!isOpen ? 'overflow-visible min-h-[0px]' : ''} shadow-lg justify-between z-20`}>

            {/* select input for projects  */}
            <span className={`flex  ${!isOpen ? 'hidden' : ''} items-center   justify-between w-full py-2 px-4`}>
                <span className="flex justify-start items-center gap-x-2 w-1/2  border-gray-700">
                    <span className="my-auto">
                        <SelectField name="ProjectID" size="md" label="Project" value={projectIDNumber} onChange={e => changeProject(e.target.value)} setValue={() => null} noLabel requestDetails={{ name: 'ProjectObject', namePlural: 'ProjectObjects', }} refreshKey={Number(projectIDNumber)} />
                    </span>

                    {projectIDNumber !== '-1' && <NewIconButton title="New" size="sm" action={() => changeProject('-1')} type="button" icon={
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>} />}

                    <NewIconButton title="Save" size="sm" action={saveProject} type="button" icon={
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#334155" xmlns="http://www.w3.org/2000/svg" className="m-auto">
                            <path fillRule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                        </svg>
                    }
                    />

                    {projectIDNumber !== '-1' && <NewIconButton title="Delete" size="sm" action={deleteProject} type="button" icon={
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                    } />}

                    {/* duplicate button */}
                    {projectIDNumber !== '-1' && <NewIconButton title="Duplicate" size="sm" action={() => saveProject(true)} type="button" icon={
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" /></svg>} />}
                    <span className="flex flex-grow items-center text-center justify-center" >
                        <Link className="underline text-blue-600 " to={'/app/project-dashboard'}>Dashboard</Link>
                    </span>

                </span>

                {/* divider */}
                <span className="border-x border-gray-700 h-full m-auto  mx-2"></span>


                <div className={`flex items-center w-1/2 border-gray-700 gap-x-4  pb-0`} >
                    <Items type={'lot'} />
                    <Items type={'structure'} />
                </div>
            </span>

            <svg className={`absolute cursor-pointer drop-shadow-2xl bg-white border  border-gray-400   left-2 justify-center -bottom-[28px] z-40  ${!isOpen ? "rotate-180 border-b-0 border-t rounded-t-lg" : 'border-t-0 rounded-b-lg'}`} onClick={() => setIsOpen(!isOpen)} xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 -960 960 960" width="40px" fill="#000000"><path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z" /></svg>
        </div >
    );
}

function Items({ type }: { type: ItemType }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type,
        item: { id: `${type} - ${Date.now()}`, type: type, name: type },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <div className={`flex my-auto border-2 cursor-grab w-[80px] h-[50px] text-center px-3  hover:border-gray-500 ${isDragging ? 'border-green-500' : 'border-gray-900'}`} ref={drag} >
            <h1 className="m-auto">{type === 'structure' ? 'house' : type}</h1>
        </div>
    );
}
