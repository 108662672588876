import { CanvasItemPropertiesStructure, CanvasItemPropertiesLot, CanvasItemPropertiesProject, ProjectItems, CanvasItemType } from "../ProjectTypes";
import TextField from "../../../components/atoms/forms/PropertyFields/TextField";
import CurrencyField from "../../../components/atoms/forms/PropertyFields/CurrencyField";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { PropertyDescriptionMap } from "../PropertyDescriptionMap";
import PercentField from "../../../components/atoms/forms/PropertyFields/PercentField";

type PropertiesType = {
    project: ProjectItems
    updateProject: (item: CanvasItemType, project: ProjectItems) => Promise<void>
    selectedItemID?: string
}

export default function Properties({ project, updateProject, selectedItemID }: PropertiesType) {
    const [selectedProperty, setSelectedProperty] = useState<string>()
    const selectedItem = useMemo(() => project.get(selectedItemID || ''), [selectedItemID, project])

    const changeCurrentProperty = (property: string) => {
        const temp = PropertyDescriptionMap.get(property) || 'No description available';
        setSelectedProperty(temp)
    }

    // clear selected property when selected item id changes
    useEffect(() => {
        setSelectedProperty(undefined)
    }, [selectedItemID])

    const handleUpdateProject = (name: string, value: string) => {
        if (selectedItem) updateProject({ ...selectedItem, props: { ...selectedItem.props, [name]: value } }, project)
    }

    // Render the editable properties table
    return (
        <div className="flex flex-col py-4 h-2/3 w-full text-center border-gray-700">
            <div className="w-full  font-semibold">Property Editor</div>
            {
                selectedItem ?

                    selectedItem.type === 'project' ? <ProjectProperties props={selectedItem.props as CanvasItemPropertiesProject} handleUpdateProject={handleUpdateProject} changeCurrentProperty={changeCurrentProperty} />
                        :
                        selectedItem.type === 'lot' ?
                            <LotProperties props={selectedItem.props as CanvasItemPropertiesLot} handleUpdateProject={handleUpdateProject} changeCurrentProperty={changeCurrentProperty} />
                            :
                            selectedItem.type === 'structure' ?
                                <StructureProperties props={selectedItem.props as CanvasItemPropertiesStructure} handleUpdateProject={handleUpdateProject} changeCurrentProperty={changeCurrentProperty} />
                                :
                                <div>No properties available</div>
                    :
                    <div>Select an object to view its properties</div>
            }


            <div className="text-left mx-1 px-2 flex flex-grow py-2">
                {selectedProperty}
            </div>
        </div>
    );
}

function PropertiesForm({ children }: { children: ReactNode }) {
    return (
        <form className="col-span-2 grid grid-cols-2 gap-y-0 text-left overflow-auto">
            <span className="flex font-semibold text-sm border border-gray-700 px-2 bg-gray-300">
                Property
            </span>
            <span className="flex font-semibold text-sm border border-gray-700 px-2 bg-gray-300">
                Value
            </span>
            {children}

        </form>
    )
}

type ProjectPropertiesType = { props: CanvasItemPropertiesProject, handleUpdateProject: (name: string, value: string) => void, changeCurrentProperty: (property: string) => void }
function ProjectProperties({ props, handleUpdateProject, changeCurrentProperty }: ProjectPropertiesType) {

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        changeCurrentProperty(`project_${e.target.name}`)
    }


    return (
        <PropertiesForm>
            <Label label="Type" />
            <TextField label="Type" value={'Project'} saveChanges={handleUpdateProject} onFocus={handleFocus} name="type" readonly />

            <Label label="Name" />
            <TextField label="Name" value={props.name} saveChanges={handleUpdateProject} onFocus={handleFocus} name="name" />

            <Label label="Project No" />
            <TextField label="Project No" value={props.projectNo} saveChanges={handleUpdateProject} onFocus={handleFocus} name="projectNo" />

            <Label label="Project Phase" />
            <TextField label="Project Phase" value={props.projectPhase} saveChanges={handleUpdateProject} onFocus={handleFocus} readonly name="projectPhase" />

            <Label label="Feasibility Method" />
            <TextField label="Feasibility Method" value={props.feasibilityMethod} saveChanges={handleUpdateProject} onFocus={handleFocus} readonly name="feasibilityMethod" />

            <Label label="Feasibility Gross Margin Amount" />
            <CurrencyField label="Feasibility Gross Margin Amount" value={props.feasibilityGrossMarginAmount} saveChanges={handleUpdateProject} readonly onFocus={handleFocus} name="feasibilityGrossMarginAmount" />

            <Label label="Feasibility Gross Margin Percentage" />
            <PercentField label="Feasibility Gross Margin Percentage" value={props.feasibilityGrossMarginPercentage} saveChanges={handleUpdateProject} readonly onFocus={handleFocus} name="feasibilityGrossMarginPercentage" />
        </PropertiesForm>
    )

}

type LotPropertiesType = { props: CanvasItemPropertiesLot, handleUpdateProject: (name: string, value: string) => void, changeCurrentProperty: (property: string) => void }
function LotProperties({ props, handleUpdateProject, changeCurrentProperty }: LotPropertiesType) {

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        changeCurrentProperty(`lot_${e.target.name}`)
    }

    return (
        <PropertiesForm>
            <Label label="Type" />
            <TextField label="Type" value='Lot' saveChanges={handleUpdateProject} onFocus={handleFocus} name="type" readonly />

            <Label label="Name" />
            <TextField label="Name" value={props.name} saveChanges={handleUpdateProject} onFocus={handleFocus} name="name" />

            <Label label="Cost" />
            <CurrencyField label="Cost" value={props.cost} saveChanges={handleUpdateProject} onFocus={handleFocus} name="cost" />
        </PropertiesForm>
    )

}

type StructurePropertiesType = { props: CanvasItemPropertiesStructure, handleUpdateProject: (name: string, value: string) => void, changeCurrentProperty: (property: string) => void }
function StructureProperties({ props, handleUpdateProject, changeCurrentProperty }: StructurePropertiesType) {

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        changeCurrentProperty(`structure_${e.target.name}`)
    }

    return (
        <PropertiesForm>
            <Label label="Type" />
            <TextField label="Type" value={'House'} saveChanges={handleUpdateProject} onFocus={handleFocus} name="type" readonly />

            <Label label="Name" />
            <TextField label="Name" value={props.name} saveChanges={handleUpdateProject} onFocus={handleFocus} name="name" />

            <Label label="Sale Price" />
            <CurrencyField label="Sale Price" value={props.salePrice} saveChanges={handleUpdateProject} onFocus={handleFocus} name="salePrice" />

            <Label label="Cost" />
            <CurrencyField label="Cost" value={props.cost} saveChanges={handleUpdateProject} onFocus={handleFocus} name="cost" />

            <Label label="Lot" />
            <TextField label="Lot" value={props.lot} saveChanges={handleUpdateProject} onFocus={handleFocus} name="lot" readonly />
        </PropertiesForm>
    )
}

function Label({ label }: { label: string }) {
    return (
        <label className="flex font-semibold text-sm border border-gray-700 px-2 ">
            <span className="my-auto">{label}</span>
        </label>
    )
}

