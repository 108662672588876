import { useState } from 'react';

interface Area {
  title: string;
  description: string;
  functions: string[];
}

const ExpandableArea = ({ area }: { area: Area }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="border border-slate-200 px-5 py-3 gapy-1 bg-white w-[450px] flex-none">
      <div className="text-lg font-bold pb-2.5 border-b-2 border-slate-200">
        {area.title}
      </div>
      <div className="italic pb-2.5 border-b border-slate-200">
        {area.description}
      </div>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className=" text-blue-600 hover:text-blue-800 focus:outline-none flex items-center"
      >
        {isExpanded ? '▼' : '▶'} {isExpanded ? 'Collapse' : 'Expand'} Functions
      </button>
      {isExpanded && (
        <ul className="list-disc ml-5 mt-2">
          {area.functions.map((func, funcIndex) => (
            <li key={funcIndex} className="mb-2 leading-relaxed">
              {func}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const EnterpriseDomains = () => {
  const domains = [
    {
      title: "STRATEGY &\nLEADERSHIP",
      areas: [
        {
          title: "Strategic Vision",
          description: "Enterprise vision and long-term strategic direction",
          functions: [
            "Vision Development",
            "Mission Alignment",
            "Core Values Definition",
            "Strategic Positioning",
            "Market Leadership Strategy",
            "Growth Strategy Development",
            "Competitive Analysis",
            "Innovation Strategy",
            "Enterprise Architecture",
            "Portfolio Strategy",
            "Corporate Identity",
            "Strategic Communications"
          ]
        },
        {
          title: "Executive Leadership",
          description: "Organizational leadership and executive direction",
          functions: [
            "Executive Direction",
            "Leadership Framework",
            "Organizational Design",
            "Culture Development",
            "Change Leadership",
            "Stakeholder Engagement",
            "Executive Communications",
            "Performance Leadership",
            "Succession Planning",
            "Leadership Development",
            "Team Building",
            "Executive Decision Making"
          ]
        },
        {
          title: "Strategic Planning",
          description: "Corporate strategy development and execution",
          functions: [
            "Strategy Development",
            "Business Planning",
            "Goal Setting & Alignment",
            "Strategic Initiatives",
            "Resource Allocation",
            "Performance Metrics",
            "Strategy Implementation",
            "Progress Monitoring",
            "Strategic Review",
            "Risk Assessment",
            "Market Analysis",
            "Strategic Partnerships"
          ]
        },
        {
          title: "Organizational Excellence",
          description: "Organizational effectiveness and operational excellence",
          functions: [
            "Business Excellence",
            "Change Management",
            "Process Optimization",
            "Quality Leadership",
            "Performance Standards",
            "Best Practice Development",
            "Continuous Improvement",
            "Knowledge Management",
            "Capability Building",
            "Innovation Programs",
            "Center of Excellence",
            "Operational Efficiency"
          ]
        },
        {
          title: "Performance Management",
          description: "Enterprise performance monitoring and optimization",
          functions: [
            "Performance Framework",
            "KPI Development",
            "Strategic Alignment",
            "Performance Analytics",
            "Business Intelligence",
            "Results Reporting",
            "Improvement Programs",
            "Benchmarking",
            "Quality Management",
            "Performance Reviews",
            "Capability Assessment",
            "Success Metrics"
          ]
        },
        {
          title: "Strategic Innovation",
          description: "Enterprise innovation and transformation",
          functions: [
            "Innovation Strategy",
            "Digital Transformation",
            "Business Model Innovation",
            "Transformation Leadership",
            "Innovation Programs",
            "Technology Strategy",
            "Market Disruption",
            "Innovation Culture",
            "Future Planning",
            "Innovation Metrics",
            "Strategic Partnerships",
            "Change Enablement"
          ]
        }
      ]
    },
    {
      title: "FINANCE",
      areas: [
        {
          title: "Financial Planning & Analysis",
          description: "Strategic financial planning, budgeting, and business analysis",
          functions: [
            "Strategic Financial Planning",
            "Budget Development & Management",
            "Financial Modeling & Forecasting",
            "Business Case Analysis",
            "Capital Planning & Allocation",
            "Profit & Loss Analysis",
            "Investment Decision Support",
            "Financial Performance Analysis",
            "Cost Management & Optimization",
            "Revenue Planning & Analysis",
            "Financial Risk Assessment",
            "Management Reporting"
          ]
        },
        {
          title: "Treasury & Risk Management",
          description: "Cash management, investments, and financial risk oversight",
          functions: [
            "Cash Management & Forecasting",
            "Investment Portfolio Management",
            "Capital Structure Planning",
            "Bank Relationship Management",
            "Foreign Exchange Management",
            "Working Capital Optimization",
            "Debt Management",
            "Financial Risk Hedging",
            "Liquidity Management",
            "Payment Strategy & Operations",
            "Credit Facility Management",
            "Treasury Systems & Controls"
          ]
        },
        {
          title: "Corporate Development",
          description: "Strategic growth initiatives and corporate transactions",
          functions: [
            "Merger & Acquisition Strategy",
            "Deal Sourcing & Evaluation",
            "Due Diligence Management",
            "Valuation Analysis",
            "Deal Structuring & Negotiation",
            "Post-merger Integration",
            "Strategic Partnerships",
            "Investment Strategy",
            "Market Analysis & Research",
            "Business Development",
            "Corporate Restructuring",
            "Synergy Assessment"
          ]
        },
        {
          title: "Financial Operations",
          description: "Day-to-day financial processes and controls",
          functions: [
            "Process Improvement & Automation",
            "Financial Controls Management",
            "System Implementation",
            "Policy & Procedure Development",
            "Financial Data Management",
            "Operational Reporting",
            "Transaction Processing",
            "Record Keeping & Documentation",
            "Compliance Monitoring",
            "Vendor Payment Management",
            "Customer Billing Operations",
            "Financial Reconciliations"
          ]
        },
        {
          title: "Business Finance",
          description: "Financial partnership and support for business units",
          functions: [
            "Business Unit Partnership",
            "Financial Decision Support",
            "Resource Allocation",
            "Cost-Benefit Analysis",
            "Performance Metrics Development",
            "Business Strategy Support",
            "Operational Finance",
            "Project Financial Management",
            "Pricing Strategy Support",
            "Contract Financial Review",
            "Growth Initiative Support",
            "ROI Analysis"
          ]
        },
        {
          title: "Investor Relations",
          description: "Stakeholder communication and relationship management",
          functions: [
            "Investor Communication Strategy",
            "Earnings Release Management",
            "Shareholder Engagement",
            "Financial Presentation Development",
            "Market Intelligence Analysis",
            "Analyst Relationship Management",
            "Investment Community Outreach",
            "Corporate Access Programs",
            "ESG Communication Strategy",
            "IR Website Management",
            "Disclosure Compliance",
            "Stakeholder Feedback Analysis"
          ]
        }
      ]
    },
    {
      title: "ACCOUNTING",
      areas: [
        {
          title: "General Accounting",
          description: "Core accounting operations and financial reporting",
          functions: [
            "General Ledger Management",
            "Financial Statement Preparation",
            "Account Reconciliation",
            "Journal Entry Processing",
            "Chart of Accounts Maintenance",
            "Period-End Close Process",
            "Accounting Policy Management",
            "Regulatory Reporting",
            "Fixed Asset Accounting",
            "Lease Accounting",
            "Intercompany Accounting",
            "Reserve Analysis"
          ]
        },
        {
          title: "Tax Accounting",
          description: "Tax planning, compliance, and reporting",
          functions: [
            "Tax Planning & Strategy",
            "Tax Compliance & Filing",
            "Tax Provision Preparation",
            "International Tax Management",
            "Transfer Pricing",
            "Sales & Use Tax",
            "Property Tax Management",
            "Tax Audit Support",
            "Tax Research & Advisory",
            "Tax Process Improvement",
            "Tax Technology Implementation",
            "Tax Risk Management"
          ]
        },
        {
          title: "Technical Accounting",
          description: "Complex accounting guidance and implementation",
          functions: [
            "Technical Research & Guidance",
            "Accounting Standard Implementation",
            "Complex Transaction Support",
            "Accounting Policy Development",
            "SEC Technical Requirements",
            "Revenue Recognition",
            "Business Combination Accounting",
            "Fair Value Measurements",
            "Stock-Based Compensation",
            "Derivatives & Hedging",
            "Impairment Analysis",
            "Technical Training & Support"
          ]
        },
        {
          title: "Internal Controls",
          description: "Financial control framework and compliance",
          functions: [
            "Control Framework Design",
            "SOX Compliance Management",
            "Control Testing & Monitoring",
            "Deficiency Remediation",
            "Process Documentation",
            "Control Automation",
            "Risk Assessment",
            "Audit Support",
            "Compliance Training",
            "Change Management",
            "Control Self-Assessment",
            "Segregation of Duties"
          ]
        },
        {
          title: "Financial Systems",
          description: "Accounting technology and system management",
          functions: [
            "System Implementation",
            "Process Automation",
            "System Integration",
            "User Access Management",
            "System Controls",
            "Report Development",
            "Data Analytics",
            "System Testing",
            "Training & Documentation",
            "System Maintenance",
            "Vendor Management",
            "Technology Innovation"
          ]
        },
        {
          title: "External Reporting",
          description: "External financial reporting and disclosure",
          functions: [
            "SEC Filing Preparation",
            "Financial Statement Analysis",
            "Disclosure Management",
            "Report Production",
            "XBRL Tagging",
            "Statutory Reporting",
            "Regulatory Compliance",
            "Audit Coordination",
            "Management Discussion & Analysis",
            "Earnings Release Support",
            "Supplemental Reporting",
            "External Communication"
          ]
        }
      ]
    },
    {
      title: "BUSINESS\nADMINISTRATION",
      areas: [
        {
          title: "Office Operations",
          description: "Day-to-day office management and administration",
          functions: [
            "Facilities Management",
            "Office Space Planning",
            "Administrative Services",
            "Reception Management",
            "Mail & Shipping Services",
            "Office Supply Management",
            "Vendor Management",
            "Equipment Management",
            "Building Security",
            "Maintenance Coordination",
            "Workspace Solutions",
            "Service Request Management"
          ]
        },
        {
          title: "Business Services",
          description: "Enterprise-wide business support services",
          functions: [
            "Document Management",
            "Records Management",
            "Print & Copy Services",
            "Travel Management",
            "Event Coordination",
            "Meeting Management",
            "Business Expense Processing",
            "Administrative Support",
            "Executive Support Services",
            "Corporate Card Program",
            "Resource Scheduling",
            "Business Continuity Support"
          ]
        },
        {
          title: "Process Management",
          description: "Business process optimization and control",
          functions: [
            "Process Documentation",
            "Standard Operating Procedures",
            "Process Improvement",
            "Quality Control",
            "Workflow Management",
            "Change Implementation",
            "Policy Administration",
            "Process Training",
            "Efficiency Monitoring",
            "Best Practice Development",
            "Compliance Management",
            "Process Innovation"
          ]
        },
        {
          title: "Resource Management",
          description: "Resource allocation and optimization",
          functions: [
            "Resource Planning",
            "Asset Management",
            "Inventory Control",
            "Resource Scheduling",
            "Capacity Planning",
            "Cost Management",
            "Resource Optimization",
            "Usage Analytics",
            "Budget Administration",
            "Contract Management",
            "Service Level Management",
            "Performance Reporting"
          ]
        },
        {
          title: "Information Management",
          description: "Business information and knowledge management",
          functions: [
            "Knowledge Base Management",
            "Information Architecture",
            "Content Management",
            "Data Organization",
            "Archive Management",
            "Information Security",
            "Access Control",
            "Version Control",
            "Documentation Standards",
            "Information Sharing",
            "Research Services",
            "Library Management"
          ]
        },
        {
          title: "Administrative Technology",
          description: "Administrative systems and technology support",
          functions: [
            "System Administration",
            "Technology Support",
            "Software Management",
            "User Access Management",
            "Technology Training",
            "Digital Workflow",
            "Automation Solutions",
            "Systems Integration",
            "Data Management",
            "Reporting Tools",
            "Technical Support",
            "Innovation Implementation"
          ]
        }
      ]
    },
    {
      title: "SALES",
      areas: [
        {
          title: "Sales Strategy & Operations",
          description: "Strategic sales planning and operational excellence",
          functions: [
            "Sales Strategy Development",
            "Territory Planning & Design",
            "Sales Process Optimization",
            "Sales Performance Management",
            "Revenue Forecasting",
            "Sales Operations Management",
            "Quota Planning & Management",
            "Sales Compensation Design",
            "Sales Tools & Technology",
            "Pipeline Management",
            "Sales Analytics & Reporting",
            "Sales Enablement Programs"
          ]
        },
        {
          title: "Account Management",
          description: "Strategic account planning and relationship management",
          functions: [
            "Key Account Strategy",
            "Account Planning & Review",
            "Customer Relationship Management",
            "Contract Negotiations",
            "Account Growth Planning",
            "Customer Success Programs",
            "Solution Development",
            "Executive Relationship Management",
            "Account Risk Management",
            "Cross-selling Initiatives",
            "Account Team Leadership",
            "Customer Advocacy Programs"
          ]
        },
        {
          title: "Business Development",
          description: "New business acquisition and market expansion",
          functions: [
            "Market Opportunity Analysis",
            "Lead Generation Strategy",
            "New Business Development",
            "Partner Channel Development",
            "Sales Pipeline Development",
            "Prospecting Programs",
            "Deal Qualification",
            "Proposal Development",
            "RFP Response Management",
            "Sales Presentation Design",
            "Competitive Analysis",
            "Win/Loss Analysis"
          ]
        },
        {
          title: "Channel Sales",
          description: "Partner channel strategy and management",
          functions: [
            "Channel Strategy Development",
            "Partner Program Design",
            "Channel Partner Recruitment",
            "Partner Enablement",
            "Channel Performance Management",
            "Partner Portal Management",
            "Channel Conflict Resolution",
            "Partner Training Programs",
            "Channel Marketing Support",
            "Partner Incentive Programs",
            "Channel Analytics",
            "Partner Relationship Management"
          ]
        },
        {
          title: "Sales Excellence",
          description: "Sales capability development and best practices",
          functions: [
            "Sales Methodology Development",
            "Sales Training Programs",
            "Sales Coaching Framework",
            "Best Practice Sharing",
            "Sales Talent Development",
            "Sales Certification Programs",
            "Sales Playbook Development",
            "Sales Technology Adoption",
            "Performance Benchmarking",
            "Sales Knowledge Management",
            "Sales Innovation Programs",
            "Sales Culture Development"
          ]
        },
        {
          title: "Inside Sales",
          description: "Remote sales operations and management",
          functions: [
            "Inside Sales Strategy",
            "Virtual Sales Programs",
            "Lead Qualification Process",
            "Remote Sales Technology",
            "Sales Script Development",
            "Call Center Operations",
            "Virtual Demo Programs",
            "Digital Sales Tools",
            "Remote Team Management",
            "Performance Monitoring",
            "Customer Experience Management",
            "Sales Activity Tracking"
          ]
        }
      ]
    },
    {
      title: "MARKETING",
      areas: [
        {
          title: "Marketing Strategy",
          description: "Strategic marketing planning and brand management",
          functions: [
            "Marketing Strategy Development",
            "Brand Strategy & Management",
            "Market Research & Analysis",
            "Customer Segmentation",
            "Value Proposition Development",
            "Marketing Planning",
            "Budget Management",
            "Marketing Analytics",
            "Competitive Intelligence",
            "Market Opportunity Analysis",
            "Marketing Innovation",
            "ROI Measurement"
          ]
        },
        {
          title: "Digital Marketing",
          description: "Online presence and digital channel management",
          functions: [
            "Digital Strategy Development",
            "SEO/SEM Management",
            "Social Media Marketing",
            "Email Marketing",
            "Content Marketing",
            "Marketing Automation",
            "Web Analytics",
            "Digital Campaign Management",
            "Mobile Marketing",
            "Online Advertising",
            "User Experience Optimization",
            "Digital Asset Management"
          ]
        },
        {
          title: "Product Marketing",
          description: "Product positioning and go-to-market strategy",
          functions: [
            "Product Positioning",
            "Market Requirements",
            "Go-to-Market Strategy",
            "Product Launch Planning",
            "Sales Enablement",
            "Competitive Analysis",
            "Product Messaging",
            "Customer Research",
            "Product Collateral",
            "Market Feedback Analysis",
            "Product Training",
            "Customer Success Stories"
          ]
        },
        {
          title: "Campaign Management",
          description: "Integrated marketing campaign development and execution",
          functions: [
            "Campaign Strategy",
            "Campaign Planning",
            "Creative Development",
            "Media Planning",
            "Campaign Execution",
            "Performance Tracking",
            "Lead Generation",
            "Multi-channel Integration",
            "Budget Management",
            "Campaign Analytics",
            "A/B Testing",
            "Campaign Optimization"
          ]
        },
        {
          title: "Marketing Operations",
          description: "Marketing process, technology, and performance management",
          functions: [
            "Marketing Technology Management",
            "Process Optimization",
            "Performance Measurement",
            "Data Management",
            "Marketing Automation",
            "Vendor Management",
            "Resource Allocation",
            "Project Management",
            "Quality Assurance",
            "Budget Tracking",
            "Marketing Systems",
            "Workflow Optimization"
          ]
        },
        {
          title: "Corporate Communications",
          description: "Corporate messaging and stakeholder communications",
          functions: [
            "Communication Strategy",
            "PR Management",
            "Media Relations",
            "Executive Communications",
            "Crisis Communications",
            "Internal Communications",
            "Content Strategy",
            "Event Management",
            "Stakeholder Relations",
            "Corporate Messaging",
            "Thought Leadership",
            "Social Responsibility"
          ]
        }
      ]
    },
    {
      title: "PRICING",
      areas: [
        {
          title: "Pricing Strategy",
          description: "Strategic pricing direction and framework development",
          functions: [
            "Pricing Strategy Development",
            "Value-Based Pricing",
            "Market Position Analysis",
            "Competitive Price Analysis",
            "Price Segmentation",
            "Portfolio Pricing",
            "New Product Pricing",
            "Global Pricing Strategy",
            "Channel Pricing Strategy",
            "Price Architecture",
            "Strategic Price Planning",
            "Pricing Innovation"
          ]
        },
        {
          title: "Price Management",
          description: "Price execution and management operations",
          functions: [
            "Price Setting Process",
            "Price Change Management",
            "Discount Management",
            "Promotion Management",
            "Price List Administration",
            "Price Exception Handling",
            "Deal Desk Operations",
            "Quote Management",
            "Price Implementation",
            "Contract Pricing",
            "Special Pricing Agreements",
            "Price Communication"
          ]
        },
        {
          title: "Analytics & Optimization",
          description: "Price analysis and performance optimization",
          functions: [
            "Price Analytics",
            "Margin Analysis",
            "Revenue Optimization",
            "Price Elasticity Studies",
            "Profitability Analysis",
            "Performance Tracking",
            "Market Response Analysis",
            "Customer Value Analysis",
            "Competitive Intelligence",
            "Price Testing",
            "Statistical Modeling",
            "ROI Analysis"
          ]
        },
        {
          title: "Price Technology",
          description: "Pricing systems and technology management",
          functions: [
            "Pricing Software Management",
            "Price Automation",
            "System Integration",
            "Data Management",
            "Technology Implementation",
            "Tool Development",
            "User Training",
            "System Maintenance",
            "Process Automation",
            "Reporting Tools",
            "Analytics Platforms",
            "Technical Support"
          ]
        },
        {
          title: "Governance & Compliance",
          description: "Pricing policies and compliance management",
          functions: [
            "Policy Development",
            "Compliance Monitoring",
            "Approval Workflows",
            "Audit Management",
            "Risk Assessment",
            "Documentation Control",
            "Training Programs",
            "Policy Communication",
            "Process Controls",
            "Regulatory Compliance",
            "Ethics Guidelines",
            "Performance Standards"
          ]
        },
        {
          title: "Price Operations",
          description: "Day-to-day pricing operations and execution",
          functions: [
            "Operations Management",
            "Process Execution",
            "Quality Control",
            "Team Management",
            "Performance Monitoring",
            "Resource Allocation",
            "Project Management",
            "Change Implementation",
            "Stakeholder Management",
            "Continuous Improvement",
            "Documentation Management",
            "Training & Development"
          ]
        }
      ]
    },
    {
      title: "CUSTOMER\nRELATIONS",
      areas: [
        {
          title: "Customer Experience",
          description: "Customer experience strategy and management",
          functions: [
            "Experience Strategy",
            "Journey Mapping",
            "Touchpoint Management",
            "Voice of Customer",
            "Experience Design",
            "Customer Feedback",
            "Satisfaction Measurement",
            "Experience Analytics",
            "Channel Management",
            "Process Improvement",
            "Quality Monitoring",
            "Innovation Programs"
          ]
        },
        {
          title: "Customer Service",
          description: "Service delivery and support operations",
          functions: [
            "Service Strategy",
            "Support Operations",
            "Case Management",
            "Quality Assurance",
            "Performance Management",
            "Channel Support",
            "Service Innovation",
            "Knowledge Management",
            "Training Programs",
            "Process Optimization",
            "Service Analytics",
            "Customer Education"
          ]
        },
        {
          title: "Customer Success",
          description: "Customer value realization and retention",
          functions: [
            "Success Strategy",
            "Account Management",
            "Onboarding Programs",
            "Value Realization",
            "Retention Programs",
            "Growth Planning",
            "Risk Management",
            "Success Analytics",
            "Relationship Building",
            "Program Development",
            "Best Practices",
            "Customer Advocacy"
          ]
        },
        {
          title: "Customer Insights",
          description: "Customer intelligence and analytics",
          functions: [
            "Data Analytics",
            "Behavior Analysis",
            "Trend Identification",
            "Predictive Modeling",
            "Segmentation Analysis",
            "Research Programs",
            "Insight Development",
            "Performance Metrics",
            "ROI Analysis",
            "Reporting Systems",
            "Data Management",
            "Strategic Recommendations"
          ]
        },
        {
          title: "Customer Engagement",
          description: "Customer communication and relationship building",
          functions: [
            "Engagement Strategy",
            "Communication Programs",
            "Loyalty Programs",
            "Community Management",
            "Social Media Engagement",
            "Event Management",
            "Content Strategy",
            "Campaign Management",
            "Digital Engagement",
            "Customer Recognition",
            "Feedback Loops",
            "Brand Experience"
          ]
        },
        {
          title: "Customer Operations",
          description: "Operational excellence in customer management",
          functions: [
            "Operations Management",
            "Process Development",
            "Quality Control",
            "Resource Planning",
            "Technology Management",
            "Performance Tracking",
            "Vendor Management",
            "Project Management",
            "Change Management",
            "Documentation Control",
            "Training Development",
            "Continuous Improvement"
          ]
        }
      ]
    },
    {
      title: "WARRANTY",
      areas: [
        {
          title: "Warranty Strategy",
          description: "Strategic warranty program development and management",
          functions: [
            "Warranty Program Design",
            "Policy Development",
            "Coverage Planning",
            "Risk Assessment",
            "Cost Modeling",
            "Performance Metrics",
            "Program Analytics",
            "Market Analysis",
            "Competitive Assessment",
            "Financial Planning",
            "Innovation Strategy",
            "Continuous Improvement"
          ]
        },
        {
          title: "Claims Management",
          description: "Warranty claims processing and administration",
          functions: [
            "Claims Processing",
            "Validation Procedures",
            "Documentation Management",
            "Payment Processing",
            "Fraud Detection",
            "Appeals Management",
            "Customer Communication",
            "Vendor Coordination",
            "Performance Tracking",
            "Quality Control",
            "Process Optimization",
            "Reporting & Analytics"
          ]
        },
        {
          title: "Technical Support",
          description: "Technical analysis and product support",
          functions: [
            "Technical Analysis",
            "Defect Investigation",
            "Root Cause Analysis",
            "Product Diagnostics",
            "Repair Procedures",
            "Technical Documentation",
            "Training Development",
            "Quality Feedback",
            "Engineering Support",
            "Field Support",
            "Knowledge Management",
            "Technical Innovation"
          ]
        },
        {
          title: "Service Operations",
          description: "Warranty service delivery and management",
          functions: [
            "Service Network Management",
            "Parts Management",
            "Repair Operations",
            "Service Quality",
            "Customer Support",
            "Service Scheduling",
            "Performance Monitoring",
            "Cost Control",
            "Service Training",
            "Process Improvement",
            "Service Innovation",
            "Customer Experience"
          ]
        },
        {
          title: "Data Analytics",
          description: "Warranty data analysis and reporting",
          functions: [
            "Data Collection",
            "Trend Analysis",
            "Predictive Analytics",
            "Cost Analysis",
            "Performance Reporting",
            "Quality Metrics",
            "Risk Analytics",
            "Customer Insights",
            "Supplier Analysis",
            "ROI Assessment",
            "Benchmarking",
            "Analytics Innovation"
          ]
        },
        {
          title: "Supplier Recovery",
          description: "Warranty cost recovery and supplier management",
          functions: [
            "Recovery Strategy",
            "Supplier Agreements",
            "Cost Analysis",
            "Claims Processing",
            "Negotiation Management",
            "Documentation Control",
            "Performance Tracking",
            "Dispute Resolution",
            "Financial Recovery",
            "Supplier Relations",
            "Process Optimization",
            "Recovery Reporting"
          ]
        }
      ]
    },
    {
      title: "PRODUCT\nDEVELOPMENT",
      areas: [
        {
          title: "Product Strategy & Planning",
          description: "Strategic product direction and roadmap planning",
          functions: [
            "Product Vision & Strategy",
            "Market Opportunity Analysis",
            "Product Portfolio Management",
            "Product Roadmap Planning",
            "Business Case Development",
            "Requirements Gathering",
            "Product Risk Assessment",
            "Resource Planning",
            "Technology Assessment",
            "Competitive Analysis",
            "Strategic Partnerships",
            "Product Innovation Planning"
          ]
        },
        {
          title: "Product Research & Discovery",
          description: "User research and product concept validation",
          functions: [
            "User Research & Analysis",
            "Market Research",
            "Customer Journey Mapping",
            "Prototype Development",
            "Concept Testing",
            "Usability Studies",
            "Competitive Research",
            "Customer Feedback Analysis",
            "Design Thinking Workshops",
            "Persona Development",
            "Problem Space Analysis",
            "Innovation Research"
          ]
        },
        {
          title: "Product Design",
          description: "User experience and interface design",
          functions: [
            "UX Strategy Development",
            "User Interface Design",
            "Information Architecture",
            "Interaction Design",
            "Visual Design Systems",
            "Accessibility Design",
            "Design Specifications",
            "Design Pattern Library",
            "Wireframe Development",
            "Design Testing",
            "Design QA Process",
            "Design Innovation"
          ]
        },
        {
          title: "Engineering & Development",
          description: "Technical development and implementation",
          functions: [
            "Technical Architecture",
            "Development Planning",
            "Sprint Management",
            "Code Quality Standards",
            "Technical Documentation",
            "Performance Optimization",
            "Security Implementation",
            "Integration Management",
            "Testing Automation",
            "Development Standards",
            "Technical Debt Management",
            "Build & Release Management"
          ]
        },
        {
          title: "Quality Assurance",
          description: "Product testing and quality management",
          functions: [
            "QA Strategy & Planning",
            "Test Case Development",
            "Automated Testing",
            "Performance Testing",
            "Security Testing",
            "User Acceptance Testing",
            "Bug Tracking & Triage",
            "Test Environment Management",
            "Quality Metrics & Reporting",
            "Regression Testing",
            "Integration Testing",
            "Release Validation"
          ]
        },
        {
          title: "Product Lifecycle Management",
          description: "End-to-end product lifecycle oversight",
          functions: [
            "Product Launch Management",
            "Version Control",
            "Change Management",
            "Feature Prioritization",
            "Performance Monitoring",
            "Customer Support Integration",
            "Product Documentation",
            "Release Management",
            "Product Maintenance",
            "End-of-Life Planning",
            "Product Analytics",
            "Continuous Improvement"
          ]
        }
      ]
    },
    {
      title: "PRODUCTION",
      areas: [
        {
          title: "Production Planning",
          description: "Manufacturing strategy and production scheduling",
          functions: [
            "Production Strategy Development",
            "Capacity Planning",
            "Schedule Optimization",
            "Resource Allocation",
            "Work Order Management",
            "Production Forecasting",
            "Material Requirements",
            "Cost Planning",
            "Production Metrics",
            "Efficiency Analysis",
            "Production Risk Management",
            "Innovation Planning"
          ]
        },
        {
          title: "Manufacturing Operations",
          description: "Day-to-day production management and optimization",
          functions: [
            "Operations Management",
            "Process Control",
            "Quality Control",
            "Workflow Optimization",
            "Equipment Maintenance",
            "Inventory Management",
            "Production Tracking",
            "Safety Management",
            "Waste Reduction",
            "Cost Control",
            "Performance Monitoring",
            "Continuous Improvement"
          ]
        },
        {
          title: "Quality Management",
          description: "Quality assurance and control systems",
          functions: [
            "Quality System Management",
            "Quality Planning",
            "Inspection Programs",
            "Testing Protocols",
            "Documentation Control",
            "Corrective Actions",
            "Preventive Measures",
            "Quality Training",
            "Supplier Quality",
            "Audit Management",
            "Standards Compliance",
            "Quality Reporting"
          ]
        },
        {
          title: "Process Engineering",
          description: "Manufacturing process design and optimization",
          functions: [
            "Process Design",
            "Technical Support",
            "Process Improvement",
            "Technology Integration",
            "Automation Planning",
            "Efficiency Analysis",
            "Equipment Specification",
            "Process Validation",
            "Cost Reduction",
            "Environmental Compliance",
            "Safety Integration",
            "Innovation Implementation"
          ]
        },
        {
          title: "Facility Management",
          description: "Production facility maintenance and optimization",
          functions: [
            "Facility Planning",
            "Maintenance Management",
            "Infrastructure Support",
            "Safety Compliance",
            "Environmental Management",
            "Energy Optimization",
            "Space Utilization",
            "Equipment Management",
            "Security Systems",
            "Waste Management",
            "Emergency Response",
            "Facility Innovation"
          ]
        },
        {
          title: "Production Technology",
          description: "Manufacturing technology and systems management",
          functions: [
            "Technology Strategy",
            "System Implementation",
            "Automation Management",
            "Data Collection",
            "Performance Analytics",
            "Digital Integration",
            "Technology Training",
            "System Maintenance",
            "Innovation Assessment",
            "Technical Support",
            "Process Control Systems",
            "Technology Optimization"
          ]
        }
      ]
    },
    {
      title: "PURCHASING",
      areas: [
        {
          title: "Strategic Sourcing",
          description: "Strategic supplier relationships and sourcing programs",
          functions: [
            "Sourcing Strategy Development",
            "Supplier Market Analysis",
            "Category Management",
            "Cost Analysis & Modeling",
            "RFP/RFQ Management",
            "Supplier Selection",
            "Contract Negotiation",
            "Global Sourcing Strategy",
            "Supply Base Optimization",
            "Supplier Risk Assessment",
            "Value Analysis & Engineering",
            "Sourcing Process Optimization"
          ]
        },
        {
          title: "Procurement Operations",
          description: "Day-to-day procurement activities and management",
          functions: [
            "Purchase Order Management",
            "Requisition Processing",
            "Vendor Master Management",
            "Procurement Policy Compliance",
            "Order Tracking & Expediting",
            "Invoice Processing",
            "Spend Analytics",
            "System Administration",
            "Process Documentation",
            "Training & Support",
            "Performance Reporting",
            "Operational Efficiency"
          ]
        },
        {
          title: "Supplier Relationship Management",
          description: "Comprehensive supplier performance and relationship oversight",
          functions: [
            "Supplier Performance Management",
            "Relationship Development",
            "Performance Metrics",
            "Quality Management",
            "Supplier Development",
            "Issue Resolution",
            "Communication Programs",
            "Continuous Improvement",
            "Supplier Recognition",
            "Collaboration Programs",
            "Risk Mitigation",
            "Innovation Programs"
          ]
        },
        {
          title: "Materials Management",
          description: "Material planning and inventory optimization",
          functions: [
            "Material Requirements Planning",
            "Inventory Management",
            "Demand Planning",
            "Stock Level Optimization",
            "Warehouse Coordination",
            "Material Flow Management",
            "Obsolescence Management",
            "Supply Chain Integration",
            "Cost Reduction Programs",
            "Material Quality Control",
            "Lead Time Management",
            "Sustainability Initiatives"
          ]
        },
        {
          title: "Procurement Technology",
          description: "Digital procurement solutions and system management",
          functions: [
            "eProcurement Systems",
            "Procurement Analytics",
            "Technology Implementation",
            "System Integration",
            "Digital Catalog Management",
            "Automation Solutions",
            "Data Management",
            "User Training",
            "System Optimization",
            "Vendor Portals",
            "Mobile Solutions",
            "Technology Innovation"
          ]
        },
        {
          title: "Compliance & Risk",
          description: "Procurement compliance and risk management",
          functions: [
            "Policy Development",
            "Regulatory Compliance",
            "Risk Assessment",
            "Audit Management",
            "Ethics & Compliance",
            "Sustainability Compliance",
            "Contract Compliance",
            "Supplier Diversity",
            "Trade Compliance",
            "Documentation Control",
            "Training Programs",
            "Reporting & Analytics"
          ]
        }
      ]
    },
    {
      title: "LAND\nACQUISITION &\nDEVELOPMENT",
      areas: [
        {
          title: "Market Analysis & Strategy",
          description: "Strategic market assessment and planning",
          functions: [
            "Market Research",
            "Opportunity Assessment",
            "Location Analysis",
            "Demographic Studies",
            "Competition Analysis",
            "Growth Planning",
            "Investment Strategy",
            "Risk Assessment",
            "Portfolio Planning",
            "Market Trends Analysis",
            "Economic Modeling",
            "Strategic Partnerships"
          ]
        },
        {
          title: "Land Acquisition",
          description: "Property identification and acquisition management",
          functions: [
            "Site Selection",
            "Due Diligence",
            "Feasibility Studies",
            "Property Valuation",
            "Contract Negotiation",
            "Title Research",
            "Environmental Assessment",
            "Zoning Analysis",
            "Purchase Agreements",
            "Cost Analysis",
            "Risk Management",
            "Closing Management"
          ]
        },
        {
          title: "Development Planning",
          description: "Project planning and development strategy",
          functions: [
            "Master Planning",
            "Development Strategy",
            "Project Feasibility",
            "Budget Planning",
            "Timeline Development",
            "Resource Allocation",
            "Stakeholder Management",
            "Risk Planning",
            "Phasing Strategy",
            "Infrastructure Planning",
            "Sustainability Planning",
            "Innovation Integration"
          ]
        },
        {
          title: "Entitlements & Approvals",
          description: "Regulatory compliance and approval management",
          functions: [
            "Zoning Management",
            "Permit Processing",
            "Environmental Compliance",
            "Government Relations",
            "Community Engagement",
            "Impact Studies",
            "Public Hearings",
            "Documentation Management",
            "Approval Tracking",
            "Regulatory Compliance",
            "Legal Coordination",
            "Timeline Management"
          ]
        },
        {
          title: "Project Management",
          description: "Development execution and project oversight",
          functions: [
            "Project Execution",
            "Contractor Management",
            "Schedule Control",
            "Cost Management",
            "Quality Assurance",
            "Safety Compliance",
            "Progress Monitoring",
            "Change Management",
            "Risk Mitigation",
            "Stakeholder Communication",
            "Documentation Control",
            "Performance Reporting"
          ]
        },
        {
          title: "Asset Management",
          description: "Property portfolio and asset optimization",
          functions: [
            "Portfolio Strategy",
            "Asset Optimization",
            "Performance Analysis",
            "Value Enhancement",
            "Property Management",
            "Maintenance Planning",
            "Financial Analysis",
            "Risk Management",
            "Market Positioning",
            "Investment Planning",
            "Disposition Strategy",
            "Portfolio Reporting"
          ]
        }
      ]
    },
    {
      title: "LAND PLANNING\n& ENGINEERING",
      areas: [
        {
          title: "Site Planning & Civil Engineering",
          description: "Comprehensive site development and infrastructure planning",
          functions: [
            "Site Analysis & Engineering",
            "Grading & Drainage Design",
            "Infrastructure Planning",
            "Utility Coordination",
            "Site Access & Circulation",
            "Stormwater Management",
            "Construction Documentation",
            "Permitting Support",
            "Technical Specifications",
            "Cost Estimation",
            "Quality Control",
            "Sustainable Site Design"
          ]
        },
        {
          title: "Environmental Planning & Engineering",
          description: "Environmental assessment and technical solutions",
          functions: [
            "Environmental Impact Studies",
            "Natural Resource Management",
            "Environmental Engineering",
            "Water Quality Management",
            "Wetland Assessment",
            "Remediation Planning",
            "Conservation Strategy",
            "Sustainability Integration",
            "Environmental Compliance",
            "Mitigation Design",
            "Monitoring Systems",
            "Technical Documentation"
          ]
        },
        {
          title: "Transportation Planning & Engineering",
          description: "Integrated transportation systems and traffic solutions",
          functions: [
            "Traffic Impact Analysis",
            "Road Network Design",
            "Transportation Planning",
            "Intersection Design",
            "Traffic Signal Systems",
            "Parking Solutions",
            "Multi-modal Integration",
            "Pedestrian/Bike Systems",
            "Transit Planning",
            "Access Management",
            "Safety Analysis",
            "Traffic Modeling"
          ]
        },
        {
          title: "Land Use & Urban Design",
          description: "Strategic land use planning and urban framework development",
          functions: [
            "Master Planning",
            "Land Use Strategy",
            "Urban Framework Design",
            "Density Planning",
            "Open Space Design",
            "Community Integration",
            "Design Guidelines",
            "Mixed-Use Planning",
            "Character Development",
            "Public Space Planning",
            "Visual Impact Analysis",
            "Placemaking Strategy"
          ]
        },
        {
          title: "Technical Services & Engineering",
          description: "Specialized technical analysis and engineering support",
          functions: [
            "Geotechnical Engineering",
            "Structural Analysis",
            "Materials Testing",
            "Foundation Design",
            "Engineering Calculations",
            "Technical Reviews",
            "Field Monitoring",
            "Construction Support",
            "Quality Assurance",
            "Risk Assessment",
            "Value Engineering",
            "Technical Innovation"
          ]
        },
        {
          title: "Project Implementation",
          description: "Project execution and regulatory compliance management",
          functions: [
            "Implementation Strategy",
            "Regulatory Compliance",
            "Project Coordination",
            "Document Control",
            "Schedule Management",
            "Quality Management",
            "Change Control",
            "Risk Management",
            "Stakeholder Communication",
            "Performance Tracking",
            "Cost Control",
            "Progress Reporting"
          ]
        }
      ]
    },
    {
      title: "LEGAL &\nCOMPLIANCE",
      areas: [
        {
          title: "Corporate Legal Operations",
          description: "Strategic legal operations and business advisory services",
          functions: [
            "Legal Strategy & Planning",
            "Contract Management & Review",
            "Corporate Governance",
            "Legal Risk Assessment",
            "Intellectual Property Management",
            "Legal Technology Implementation",
            "Outside Counsel Management",
            "Legal Knowledge Management",
            "Legal Process Optimization",
            "Legal Budget Management",
            "Legal Staff Development",
            "Legal Service Delivery"
          ]
        },
        {
          title: "Regulatory Compliance",
          description: "Regulatory oversight and compliance management",
          functions: [
            "Compliance Program Management",
            "Regulatory Change Management",
            "Compliance Risk Assessment",
            "Compliance Training & Education",
            "Compliance Monitoring & Testing",
            "Policy & Procedure Development",
            "Regulatory Reporting",
            "Compliance Investigations",
            "Regulatory Examinations Support",
            "Compliance Technology Solutions",
            "Industry Standards Compliance",
            "Regulatory Relations Management"
          ]
        },
        {
          title: "Ethics & Corporate Integrity",
          description: "Corporate ethics and integrity program management",
          functions: [
            "Ethics Program Development",
            "Code of Conduct Management",
            "Ethics Training & Communications",
            "Whistleblower Program Management",
            "Ethics Investigation Management",
            "Values & Culture Assessment",
            "Ethics Risk Management",
            "Ethics Advisory Services",
            "Conflicts of Interest Management",
            "Anti-corruption Program",
            "Business Conduct Guidelines",
            "Ethics Reporting & Analytics"
          ]
        },
        {
          title: "Enterprise Risk Management",
          description: "Comprehensive risk oversight and management",
          functions: [
            "Risk Strategy Development",
            "Risk Assessment & Analysis",
            "Risk Monitoring & Reporting",
            "Risk Control Implementation",
            "Risk Data Management",
            "Business Continuity Risk",
            "Third-Party Risk Management",
            "Operational Risk Management",
            "Strategic Risk Assessment",
            "Risk Technology Solutions",
            "Risk Training & Awareness",
            "Risk Framework Development"
          ]
        },
        {
          title: "Privacy & Data Protection",
          description: "Privacy program management and data protection",
          functions: [
            "Privacy Program Management",
            "Data Protection Strategy",
            "Privacy Impact Assessments",
            "Privacy Policy Development",
            "Data Subject Rights Management",
            "Privacy Training & Awareness",
            "Privacy Incident Response",
            "Cross-border Data Transfer",
            "Privacy Technology Solutions",
            "Privacy Risk Assessment",
            "Privacy Compliance Monitoring",
            "Privacy by Design Implementation"
          ]
        },
        {
          title: "Government & Regulatory Affairs",
          description: "Government relations and regulatory advocacy",
          functions: [
            "Government Relations Strategy",
            "Regulatory Advocacy",
            "Policy Analysis & Development",
            "Legislative Monitoring",
            "Industry Coalition Management",
            "Government Reporting",
            "Public Policy Engagement",
            "Regulatory Change Management",
            "Stakeholder Relations",
            "Political Activity Compliance",
            "Trade Association Management",
            "Public Affairs Communications"
          ]
        }
      ]
    },
    {
      title: "TECHNOLOGY",
      areas: [
        {
          title: "Infrastructure & Operations",
          description: "Core technology infrastructure and operational management",
          functions: [
            "Data Center Operations",
            "Network Infrastructure",
            "Cloud Services",
            "System Administration",
            "Security Operations",
            "Disaster Recovery",
            "Storage Management",
            "Service Desk",
            "Asset Management",
            "Performance Monitoring",
            "Capacity Planning",
            "Technology Standards"
          ]
        },
        {
          title: "Application Development",
          description: "Enterprise software development and management",
          functions: [
            "Software Architecture",
            "Application Development",
            "Quality Assurance",
            "DevOps Management",
            "Release Management",
            "API Development",
            "Mobile Development",
            "Integration Services",
            "Database Management",
            "Testing & Validation",
            "Documentation",
            "Performance Optimization"
          ]
        },
        {
          title: "Information Security",
          description: "Enterprise security and risk management",
          functions: [
            "Security Strategy",
            "Threat Management",
            "Identity Management",
            "Access Control",
            "Security Operations",
            "Compliance Management",
            "Incident Response",
            "Risk Assessment",
            "Security Architecture",
            "Policy Development",
            "Security Training",
            "Vulnerability Management"
          ]
        },
        {
          title: "Data & Analytics",
          description: "Enterprise data management and analytics",
          functions: [
            "Data Architecture",
            "Business Intelligence",
            "Data Governance",
            "Analytics Platform",
            "Data Integration",
            "Predictive Analytics",
            "Data Quality",
            "Reporting Services",
            "Data Visualization",
            "Master Data Management",
            "Data Privacy",
            "Analytics Innovation"
          ]
        },
        {
          title: "Enterprise Architecture",
          description: "Technology strategy and architecture management",
          functions: [
            "Architecture Strategy",
            "Technology Standards",
            "Solution Architecture",
            "Integration Architecture",
            "Reference Architecture",
            "Architecture Governance",
            "Technology Portfolio",
            "Innovation Management",
            "Digital Strategy",
            "Vendor Management",
            "Technology Roadmap",
            "Architecture Assessment"
          ]
        },
        {
          title: "Project Management",
          description: "Technology project and portfolio management",
          functions: [
            "Project Portfolio",
            "Resource Management",
            "Project Governance",
            "Change Management",
            "Risk Management",
            "Quality Management",
            "Vendor Coordination",
            "Status Reporting",
            "Budget Management",
            "Stakeholder Management",
            "Project Methodologies",
            "Benefits Realization"
          ]
        }
      ]
    },
    {
      title: "HUMAN\nRESOURCES",
      areas: [
        {
          title: "HR Strategy & Operations",
          description: "Strategic HR planning and operational management",
          functions: [
            "HR Strategy Development",
            "Workforce Planning",
            "HR Operations",
            "Policy Development",
            "Program Management",
            "HR Analytics",
            "Change Management",
            "HR Technology",
            "Process Improvement",
            "Vendor Management",
            "Budget Management",
            "Performance Metrics"
          ]
        },
        {
          title: "Talent Acquisition",
          description: "Recruitment and hiring processes",
          functions: [
            "Recruitment Strategy",
            "Talent Sourcing",
            "Candidate Assessment",
            "Interview Process",
            "Selection Management",
            "Offer Management",
            "Onboarding Programs",
            "Recruitment Marketing",
            "College Relations",
            "Diversity Recruitment",
            "Recruitment Analytics",
            "Employment Branding"
          ]
        },
        {
          title: "Compensation & Benefits",
          description: "Total rewards strategy and administration",
          functions: [
            "Compensation Strategy",
            "Benefits Administration",
            "Salary Administration",
            "Incentive Programs",
            "Equity Compensation",
            "Health & Welfare",
            "Retirement Programs",
            "Market Analysis",
            "Job Evaluation",
            "Executive Compensation",
            "Global Compensation",
            "Total Rewards"
          ]
        },
        {
          title: "Learning & Development",
          description: "Employee development and training",
          functions: [
            "Learning Strategy",
            "Training Programs",
            "Leadership Development",
            "Career Development",
            "Skill Assessment",
            "Performance Support",
            "Learning Technology",
            "Content Development",
            "Program Evaluation",
            "Certification Programs",
            "Knowledge Management",
            "Mentoring Programs"
          ]
        },
        {
          title: "Employee Relations",
          description: "Employee engagement and workplace relations",
          functions: [
            "Employee Engagement",
            "Performance Management",
            "Issue Resolution",
            "Policy Compliance",
            "Labor Relations",
            "Workplace Culture",
            "Diversity & Inclusion",
            "Recognition Programs",
            "Communication Programs",
            "Employee Surveys",
            "Change Support",
            "Conflict Resolution"
          ]
        },
        {
          title: "HR Compliance",
          description: "Regulatory compliance and risk management",
          functions: [
            "Compliance Management",
            "Risk Assessment",
            "Policy Development",
            "Regulatory Reporting",
            "Audit Management",
            "Safety Compliance",
            "Investigation Management",
            "Documentation Control",
            "Training & Education",
            "Legal Coordination",
            "Ethics Programs",
            "Compliance Monitoring"
          ]
        }
      ]
    }
  ];

  return (
    <div className="flex flex-col gap-3 my-5">
      {domains.map((domain, domainIndex) => (
        <div key={domainIndex} className="flex gap-2 mx-4 py-0 overflow-x-auto min-h-fit">
          <div className="min-w-[250px] text-2xl font-bold px-5 bg-slate-100 border border-slate-200 flex items-center justify-center text-center self-stretch whitespace-pre-line">
            {domain.title}
          </div>

          <div className="flex gap-y-5 gap-x-3 flex-nowrap">
            {domain.areas.map((area, index) => (
              <ExpandableArea area={area} key={`${domain.title}-${area.title}-${index}`} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EnterpriseDomains;