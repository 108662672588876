import { JVSpec, JVSpecCalculations, JVSTUserDetails } from "./JVSpecTypes";
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";
import { useEffect, useState } from "react";
import FormatDate from "../../helpers/FormatDate";
  
export type Events = {
    event: string,
    date: Date,
    balance?: number
  }[]

export function ConstructionInterestTable(props: { form: JVSpec, calculations?: JVSpecCalculations }): JSX.Element {

    const [constructionInterestTotals, setConstructionInterestTotals] = useState<{interestTotal: string}>({interestTotal: Currency('0')});

    useEffect(() => {
        if (props.calculations) {
            // Calculate Construction Interest Totals

            let constructionInterestTotals = {
                interestTotal: Currency(props.calculations.MonthlyConstructionInterest && props.calculations.MonthlyConstructionInterest.length > 0 ? props.calculations.MonthlyConstructionInterest.reduce((acc, month) => acc + Number(month.Interest), 0) : 0)
            };
            setConstructionInterestTotals(constructionInterestTotals);
        }
    }, [props.form, props.calculations]);

    console.log(props.calculations);

    return (
            <table className="">
                <thead className="">
                    <tr>
                        <th colSpan={5} className="p-4 border-b border-t border-l border-r border-black bg-gray-200 text-lg" >Construction Interest</th>
                    </tr>
                    <tr>
                        <th className="border-r border-l border-black text-center">Order</th>
                        <th className="border-r border-black text-right pr-1">Period</th>
                        <th className="border-r border-black text-left pl-2">Events</th>
                        <th className="border-r border-black text-right pr-1">Balance (EOM)</th>
                        <th className="border-r border-black text-right pr-1">Interest</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.calculations?.MonthlyConstructionInterest &&

                        props.calculations.MonthlyConstructionInterest.sort((a,b) => a.Order - b.Order).map((month, index) => (
                            <tr key={'month' + month.Period}>
                                <td className="w-max table-cell border-2 border-black text-center pr-2">{month.Order}</td>
                                <td className="w-max table-cell border-2 border-black text-right">{month.Period}</td>
                                <td className="w-max table-cell border-2 border-black flex justify-center items-left">
                                    <ConstructionInterestDetails events={month.Events} />
                                </td>
                                <td className="w-max table-cell border-2 border-black text-right">{Currency(month.Balance)}</td>
                                <td className="w-max table-cell border-2 border-black text-right">{Currency(month.Interest)}</td>
                            </tr>
                        ))
                    }
                    {
                        // Totals
                        <tr>
                            <td colSpan={4} className="table-cell border-2 border-black text-right bg-gray-200 font-bold">Interest Reserve:</td>
                            <td className="table-cell border-2 border-black text-right bg-gray-200 font-bold">{constructionInterestTotals.interestTotal}</td>
                        </tr>
                    }
                </tbody>
            </table>
 );
}

export function ConstructionInterestDetails(props: {events: Events}): JSX.Element {

    return (
        <ul className="list-disc pl-4">
            {
                props.events.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime() ).map((event, index) => (
                    <li key={`${event.event}${index}`} >{event.event} ({FormatDate(event.date)})</li>
                ))
            }
        </ul>
    )

}
