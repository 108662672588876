import { UseFormSetValue } from "react-hook-form";
import { useEffect, useState } from "react";
import APIRequest from "../../../../helpers/CreateRequest";


export type SearchFieldType = {
    name: string,
    label: string,
    size?: 'sm' | 'md' | 'lg' | 'xl',
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value?: string,
    setValue: UseFormSetValue<any>,
    fieldNames: {
        name: string;
        namePlural: string;
    },
    staticOptions?: {
        name: string,
        value: any,
    }[],
    setWrapperState?: Function,
    requestDetails?: {
        path: string,
        method: "GET" | "POST" | "PUT" | "DELETE",
        header?: any,
        body?: any,
        resultName: string,
        resultIDName: string,
    }
}


export default function SearchField({
    name, label, size, supportingText, disabled, required, value, setValue, fieldNames, staticOptions, setWrapperState, requestDetails
}: SearchFieldType) {
    const [isFocused, setIsFocused] = useState(false);
    const [tempValue, setTempValue] = useState<string>('');
    const [options, setOptions] = useState<{ name: string, value: any, index: number }[]>(staticOptions?.map((option, index) => { return { ...option, index: index } }) || []);
    const [filteredOptions, setFilteredOptions] = useState<{ name: string, value: any, index: number }[]>(options);
    const [selectedOption, setSelectedOption] = useState(0);

    const getNames = async () => {
        const tempOptions: { name: string, value: any, index: number }[] = [];
        try {
            const result = await (new APIRequest(requestDetails?.path || `/${fieldNames.namePlural.replace(/\s+/g, '')}/names`, requestDetails?.method || 'GET', requestDetails?.header || null, requestDetails?.body || null).GenerateRequest());
            if (result.status === 200) {
                const body = await result.json();
                const objs: any[] = body.recordset || [];
                if (objs.length > 0) {
                    for (let i = 0; i < objs.length; i++) {
                        const tempName = requestDetails ? objs[i][requestDetails.resultName] : objs[i].Name || objs[i][`${fieldNames.name.replace(/\s+/g, '')}Name`] || objs[i][`${fieldNames.name.replace(/\s+/g, '')}NameCode`];
                        const tempID = requestDetails ? objs[i][requestDetails.resultIDName] : objs[i][`${fieldNames.name.replace(/\s+/g, '')}ID`];
                        tempOptions.push({ name: tempName, value: tempID, index: i });
                    }
                }
            } else {
                tempOptions.push({ name: `Error...`, value: undefined, index: -1 });
            }
            setOptions(tempOptions);
            setFilteredOptions(tempOptions);
        } catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setTempValue(inputValue);
        setFilteredOptions(options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase())));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setIsFocused(false);
    };

    const handleSelect = (option?: { name: string, value?: string, index: number }) => {
        if (option) {
            setTempValue(option.name)
            setValue(name, option.value, { shouldDirty: true })
            setSelectedOption(option.index)
            if (setWrapperState) setWrapperState(option.value)
        } else {
            options.forEach(option => {
                if (option.index === selectedOption) {
                    setTempValue(option.name)
                    setValue(name, option.value, { shouldDirty: true })
                    setIsFocused(false)
                    if (setWrapperState) setWrapperState(option.value)
                }
            })
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') setSelectedOption(selectedOption >= filteredOptions.length - 1 ? 0 : selectedOption + 1);
        else if (e.key === 'ArrowUp') setSelectedOption(selectedOption <= 0 ? filteredOptions.length - 1 : selectedOption - 1);
        else if (e.key === 'Enter') handleSelect(filteredOptions[selectedOption]);
    };

    const handleClear = () => {
        setTempValue('');
        setValue(name, '', { shouldDirty: true });
        setSelectedOption(0);
        if (setWrapperState) setWrapperState('');
    }

    useEffect(() => {
        if (!staticOptions) getNames();
    }, [value]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        const element = document.getElementById(`searchFieldOptions${name}${label}${selectedOption}`);
        if (element) element.scrollIntoView({ block: 'nearest' });
    }, [selectedOption]);

    //if option matches set as temp value
    useEffect(() => {
        let found = false
        options.forEach(option => {
            if (option.value === value) {
                setTempValue(option.name)
                setValue(name, option.value, { shouldDirty: true })
                found = true
            }
        })
        if (!found) setTempValue('')

    }, [value, options])

    return (
        <div key={`${label}${name}`} className="font-sans overflow-visible">
            <div className="relative">
                <input
                    type="text"
                    disabled={disabled}
                    required={required}
                    className={`h-[40px] ${size === 'sm' ? 'w-[125px]' : size === 'lg' ? 'w-[400px]' : 'w-[200px]'} rounded-[2px] outline-none outline-[1.5px] text-md-on-surface body-large pr-[32px] pl-[14px] py-[16px] transition-opacity duration-300
                    ${disabled ? 'outline-gray-300 dark:outline-gray-300 text-opacity-[.38] cursor-default' : 'outline-md-outline cursor-pointer'}
                    ${isFocused ? 'outline-[2.5px] text-black outline-md-primary text-md-on-surface cursor-text' : ''}
                    ${!disabled && !isFocused ? 'hover:outline-md-on-surface hover:text-md-on-surface' : ''}
                    `}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsFocused(true)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={tempValue}
                />

                {tempValue ?
                    // clear button
                    <svg xmlns="http://www.w3.org/2000/svg" onMouseDown={handleClear} fill={` ${!disabled && !isFocused ? 'var(--md-sys-color-on-surface-variant)' : ''}  ${disabled ? 'var(--md-sys-color-on-surface)' : ''} ${isFocused ? 'var(--md-sys-color-primary)' : ''} `} className={`cursor-pointer z-10 absolute top-[8px] ${size === 'sm' ? 'left-[120px]' : size === 'lg' ? 'left-[370px]' : 'left-[172px]'} transition-all duration-300 ${disabled ? 'opacity-[.38]' : ''}`} height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>
                    :
                    // search icon
                    <svg xmlns="http://www.w3.org/2000/svg" fill={` ${!disabled && !isFocused ? 'var(--md-sys-color-on-surface-variant)' : ''}  ${disabled ? 'var(--md-sys-color-on-surface)' : ''} ${isFocused ? 'var(--md-sys-color-primary)' : ''} `} className={`absolute top-[8px] ${size === 'sm' ? 'left-[120px]' : size === 'lg' ? 'left-[370px]' : 'left-[172px]'} pointer-events-none transition-all duration-300 ${disabled ? 'opacity-[.38]' : ''}`} height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>
                }
                <label
                    htmlFor={name}
                    className={`absolute flex items-center transition-all duration-300 h-[16px] pointer-events-none z-10 body-large text-nowrap
                    ${isFocused || (disabled && value) || tempValue ? ` ${isFocused ? 'text-md-primary' : ''} top-[-12px] bg-white px-1 left-[4px] body-large ` : 'top-[12px] left-[16px] text-md-on-surface-variant bg-transparent'}
                    ${required ? "after:content-['*'] after:ml.5" : ''}
                    ${disabled ? ` ${!value ? 'opacity-[.38]' : ''} text-md-on-surface body-large` : ''}
                     `}
                >
                    {label}
                </label>

                {/* Supporting text */}
                <div className={`font-sans text-[12px] tracking-[0.3px] pt-[4px] px-[16px] 
        ${disabled ? ' text-md-on-surface opacity-[.38]' : 'text-md-on-surface-variant hover:text-md-on-surface-variant'}
        ${isFocused ? ' text-md-on-surface-variant' : ''}
        `}>{supportingText}</div>

                <ul className={`absolute ${size === 'sm' ? 'w-[125px]' : size === 'lg' ? 'w-[400px]' : 'w-[200px]'} left-[-4px] z-50 overflow-auto text-md-on-secondary-container bg-[--md-ref-palette-primary98] shadow-black rounded-[2px] max-h-52 py-[4px] shadow-sm 
                     ${disabled ? ' text-md-on-surface opacity-[.38]' : ''}
                     ${!disabled && !isFocused ? 'text-opacity-100 hover:text-md-on-surface body-large' : ''}
                     ${isFocused ? '' : 'invisible'}
                     `}
                >
                    {
                        filteredOptions.length > 0 ?
                            filteredOptions.map((option, index) => (
                                <li
                                    id={`searchFieldOptions${name}${label}${index}`}
                                    key={`search${option.name}${option.value}`}
                                    onMouseDown={() => handleSelect(option)}
                                    className={`cursor-pointer w-full px-[6px] py-[8px] hover:bg-gray-200 break-words 
                                             text-md-on-surface label-large
                                             hover:text-md-on-surface
                                             hover:bg-[--md-ref-palette-primary80]
                                             focus:bg-md-secondary focus:text-md-on-surface
                                             ${selectedOption === index ? 'bg-gray-300' : ''}
                                            `}
                                >
                                    {option.name}
                                </li>
                            ))
                            :
                            <li key={'dropdownOptionNoneMatch'} className="w-full px-4 py-2 text-sm ">
                                No results found
                            </li>
                    }
                </ul>
            </div>
        </div>
    );
}