import { useEffect, useState } from "react";
import NewIconButton from "../../components/atoms/NewIconButton";
import TextField from "../../components/atoms/forms/SimpleFields/TextField";
import SelectField from "../../components/atoms/forms/SimpleFields/SelectField";
import { usePrompt } from "../../hooks/usePrompt";
import APIRequest from "../../helpers/CreateRequest";
import SnackBar from "../../components/molecules/SnackBar";

interface settingsForm {
    FirstName: string,
    LastName: string,
    TenantName: string,
    CurrencyDecimalHidden: string
}

const defaultForm: settingsForm = {
    FirstName: "",
    LastName: "",
    TenantName: "",
    CurrencyDecimalHidden: "True"
}

export function Settings() {
    const [form, setForm] = useState<settingsForm>(defaultForm)
    const [formBackup, setFormBackup] = useState<settingsForm>(defaultForm)
    const [isDirty, setIsDirty] = useState(false)
    const [message, setMessage] = useState({ message: '', refresh: false })


    usePrompt({
        when: isDirty,
        message: "You have unsaved changes. Are you sure you want to exit without saving?"
    })

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        try {
            const result = (await new APIRequest('/settings/update', 'PUT', null, form).GenerateRequest())

            if (result.status === 200) {
                const body = await result.json();
                if (body.status === 200) {
                    setIsDirty(false);
                    setMessage({ message: "Settings saved successfully", refresh: true })
                }
            } else {
                throw result.statusText
            }
        } catch (error) {
            setMessage({ message: 'Failed to save settings', refresh: false })
        }
    }

    const handleCancel = () => {
        setForm({ ...formBackup })
    }

    const getSettings = async () => {

        try {
            const result = await new APIRequest('/settings/select', 'POST', null, {}).GenerateRequest()

            if (result.status === 200) {
                const body = await result.json();
                if (body.status === 200) {
                    const record = body.recordset[0]
                    setForm({ ...record })
                    setFormBackup({ ...record })
                }
            } else {
                console.error("Failed to fetch settings")
            }
        } catch (error) {
            console.error("Error fetching settings", error)

        }
    }

    // get settings from API
    useEffect(() => { getSettings() }, [])


    // check if form is dirty
    useEffect(() => {
        setIsDirty(JSON.stringify(form) !== JSON.stringify(formBackup))
    }, [form, formBackup])

    return (
        <form className="h-full w-full " onSubmit={handleSave}>
            <header className="flex justify-between px-4 py-2 border-b-2 w-full h-max">
                <h1 className="text-xl font-semibold m-auto ml-0">Settings</h1>

                <span className="flex gap-x-3">
                    <NewIconButton title="Cancel" size="sm" action={handleCancel} icon={
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                    } />
                    <NewIconButton title="Save" size="sm" disabled={!isDirty} type="submit" icon={
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#334155" xmlns="http://www.w3.org/2000/svg" className="m-auto">
                            <path fillRule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                        </svg>
                    }
                    />
                </span>
            </header>

            <main className="py-2 gap-y-4 flex flex-col" >
                <ModuleSection header="User" id="UserSection">
                    <TextField label="First Name" name="FirstName" placeholder="First Name" size="md" setValue={setForm} value={form.FirstName} />
                    <TextField label="Last Name" name="LastName" placeholder="Last Name" size="md" setValue={setForm} value={form.LastName} />
                </ModuleSection>

                {/* General  */}
                <ModuleSection header="General" id="GeneralSection">
                    <TextField label="Company Name" name="TenantName" placeholder="Company Name" size="lg" setValue={setForm} value={form.TenantName} />

                    {/* <CheckboxField label="Hide Decimals" name="HideDecimals" size="md" setValue={setForm} value={form.HideDecimals} /> */}

                    <SelectField label="Hide decimals for currency" name="CurrencyDecimalHidden" size="md" setValue={setForm} value={form.CurrencyDecimalHidden} noPlaceholder options={[
                        { name: "Yes", value: "True" },
                        { name: "No", value: "False" }
                    ]} />

                </ModuleSection>
            </main>
            <SnackBar message={message} />
        </form>
    )
}



function ModuleSection(props: { children: React.ReactNode, header: string, id?: string }) {

    return (
        <section id={props.id} className="px-4 m-auto gap-y-3 gap-x-[40px] w-[580px] border-gray-100 rounded-sm  p-4 flex flex-wrap">
            <h1 className="text-lg font-semibold w-full">{props.header}</h1>
            {props.children}
        </section>
    )
}

export function InputDescription({ text, additionalClasses }: { text: string, additionalClasses?: string }) {
    return <p className={`w-[250px] mt-auto h-max body-small ${additionalClasses ? additionalClasses : ' '}`}>{text}</p>
}