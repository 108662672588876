import React from "react";
import BaseField, { BaseFieldProps } from "./BaseField";
import { Currency } from "../../../../helpers/InputValueConverter";

function CurrencyField(props: BaseFieldProps) {

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (props.onBlur) props.onBlur(e);

        const changedValue = e.currentTarget.value === "$" ? "" : e.currentTarget.value;
        const formattedValue = Currency(changedValue);
        props.saveChanges(props.name, formattedValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === "Backspace" || e.key === "Delete" || e.key === "ArrowLeft" || e.key === "ArrowRight" || e.key === "Tab" || (e.key === "." && !e.currentTarget.value.includes("."));

        // Only digits and the exceptions above will work
        if (!/^\d$/.test(e.key) && (!EXCEPTION || e.key === " ")) {
            e.preventDefault();
        }
    };

    return (
        <BaseField
            {...props}
            value={props.value}
            type="currency"
            onBlur={handleBlur}
            onKeydown={handleKeyDown}
        />
    );
}

export default CurrencyField;