import { Fragment, useMemo } from "react";
import { CanvasItemPropertiesLot, CanvasItemPropertiesProject, CanvasItemPropertiesStructure, ProjectItems } from "./ProjectTypes";
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";

export default function Report({ project, projectID }: { project: ProjectItems, projectID: string }) {
    const projectHeader = useMemo(() => project.get(projectID)?.props, [project, projectID]);
    const projectLots = useMemo(() => [...project.keys()].filter(key => project.get(key)?.type === 'lot'), [project]);
    const projectStructures = useMemo(() => [...project.keys()].filter(key => project.get(key)?.type === 'structure'), [project]);

    return (
        <div className="border-t-2 border-gray-700 p-4 w-full max-w-md mx-auto overflow-auto">
            <h1 className="font-semibold text-xl text-center mb-4">Gross Margin</h1>

            <div className="mb-4">
                <h2 className="font-semibold text-lg mb-2">Income</h2>
                <div className="grid grid-cols-2 gap-2 pl-4">
                    {projectStructures.map(structureID => {
                        const structure = project.get(structureID)?.props as CanvasItemPropertiesStructure;
                        return (
                            <Fragment key={structureID}>
                                <span className="text-gray-700">{structure.name}</span>
                                <span className="text-gray-700 text-right">{structure.salePrice}</span>
                            </Fragment>
                        );
                    })}
                </div>
                <div className="flex justify-between font-semibold mt-2">
                    <span>Total Income</span>
                    <span>{projectHeader?.totalIncome}</span>
                </div>
            </div>

            <div className="mb-4">
                <h2 className="font-semibold text-lg mb-2">Expenses</h2>
                <div className="grid grid-cols-2 gap-2 pl-4">
                    {projectLots.map(lotID => {
                        const lot = project.get(lotID)?.props as CanvasItemPropertiesLot;
                        return (
                            <Fragment key={lotID}>
                                <span className="text-gray-700">{lot.name}</span>
                                <span className="text-right">{lot.cost}</span>
                            </Fragment>
                        );
                    })}
                    {projectStructures.map(structureID => {
                        const structure = project.get(structureID)?.props as CanvasItemPropertiesStructure;
                        return (
                            <Fragment key={structureID}>
                                <span className="text-gray-700">{structure.name}</span>
                                <span className="text-right">{structure.cost}</span>
                            </Fragment>
                        );
                    })}
                </div>
                <div className="flex justify-between font-semibold mt-2">
                    <span>Total Expenses</span>
                    <span>{projectHeader?.totalExpenses}</span>
                </div> 

            </div>

            <div className="flex justify-between font-semibold">
                <span>Gross Margin</span>

                {
                    (CurrencyToNumber(projectHeader?.feasibilityGrossMarginAmount) || 0) <= 0 ?
                        <span className="text-red-500">({Currency(projectHeader?.feasibilityGrossMarginAmount).replace('-', '')})</span>
                        :
                        <span>{Currency(projectHeader?.feasibilityGrossMarginAmount)}</span>
                }
            </div>
        </div>
    );
}