import { useEffect, useState } from "react"

type SnackBarType = {
    message: { message: string, refresh: boolean },
}

export default function SnackBar({ message }: SnackBarType) {
    const [firstRender, setFirstRender] = useState(true)
    const [visible, setVisible] = useState(false)
    const [fade, setFade] = useState(false)

    // skip first render. Anytime the message changes, show the snackbar
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false)
        } else {
            setVisible(true)
        }
    }, [message])

    // set fade to be the same as visible. (done via useEffect so fade is set after visible to avoid race condition)
    useEffect(() => { setFade(visible) }, [visible])

    // fade out after two seconds. the useeffect will be called again to set visible to false
    useEffect(() => {
        if (fade) {
            setTimeout(() => {
                setFade(false)
            }, 2000);
        } else {
            setTimeout(() => {
                setVisible(false)
            }, 500);
        }
    }, [fade])


    return (
        // container
        <div className={`absolute bottom-[30px]  left-1/2 -translate-x-1/2 -translate-y-1/2 h-[36px] w-max px-4 py-1 transition-opacity duration-500 bg-gray-700 shadow-lg 
        ${visible ? ' z-50' : '-z-50'} 
        ${fade ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`}
        >
            {/* Label */}
            <span className="text-sm text-gray-100 m-auto">{message.message}</span>
        </div>
    )
}