import { Events, JVSpec, JVSpecCalculations } from "./JVSpecTypes";
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";
import { useEffect, useState } from "react";
import FormatDate from "../../helpers/FormatDate";
import { get } from "http";
import { set } from "react-hook-form";

export function SourcesUsesTable(props: { form: JVSpec, calculations?: JVSpecCalculations }): JSX.Element {

    const [sourcesUsesValues, setSourcesUsesValues] = useState<{loanAmount: number, builderCashSum: number, investorCashSum: number, loanAmountPercent: number, builderCashSumPercent: number, investorCashSumPercent: number, totalSources: number, totalLandAndRelatedCosts: number, totalSiteImprovementCosts: number, totalSoftOtherCosts: number, interestReserve: number, totalUses: number}>({loanAmount: 0, builderCashSum: 0, investorCashSum: 0, loanAmountPercent: 0, builderCashSumPercent: 0, investorCashSumPercent: 0, totalSources: 0, totalLandAndRelatedCosts: 0, totalSiteImprovementCosts: 0, totalSoftOtherCosts: 0, interestReserve: 0, totalUses: 0});

    const getTotals = () => {

        console.log(props.calculations);
        console.log(props.form);

        const loanAmount = CurrencyToNumber(props.calculations?.LoanAmount ?? '0') || 0;
        const builderCashSum = CurrencyToNumber(props.form?.BuilderCashSum ?? '0') || 0;
        const investorCashSum = CurrencyToNumber(props.form?.InvestorCashSum ?? '0') || 0;

        const loanAmountPercent = loanAmount / (loanAmount + builderCashSum + investorCashSum)*100 || 0;
        const builderCashSumPercent = builderCashSum / (loanAmount + builderCashSum + investorCashSum)*100 || 0;
        const investorCashSumPercent = investorCashSum / (loanAmount + builderCashSum + investorCashSum)*100 || 0;

        const totalSources = loanAmount + builderCashSum + investorCashSum;

        const totalLandAndRelatedCosts = (CurrencyToNumber(props.form?.LandPurchaseSum) || 0) + (CurrencyToNumber(props.form?.DirectConstructionSum) || 0) + (CurrencyToNumber(props.form?.DesignPlanningSum) || 0); 
        const totalSiteImprovementCosts = CurrencyToNumber(props.form?.SiteImprovementsSum) || 0;
        const totalSoftOtherCosts = (CurrencyToNumber(props.calculations?.Contingency) || 0) + (CurrencyToNumber(props.calculations?.Commissions) || 0) + (CurrencyToNumber(props.form?.TransferTaxesOtherDispositionSum) || 0) + (CurrencyToNumber(props.form?.FinanceExpensesSum) || 0) + (CurrencyToNumber(props.form?.OtherProjectExpensesSum) || 0);
        const interestReserve = CurrencyToNumber(props.calculations?.ConstructionInterest) || 0;

        const totalUses = totalLandAndRelatedCosts + totalSiteImprovementCosts + totalSoftOtherCosts + interestReserve;

        setSourcesUsesValues({loanAmount, builderCashSum, investorCashSum, loanAmountPercent, builderCashSumPercent, investorCashSumPercent, totalSources, totalLandAndRelatedCosts, totalSiteImprovementCosts, totalSoftOtherCosts, interestReserve, totalUses});

    }
    useEffect(() => {
        if (props.calculations && props.form) {
            // Calculate Construction Interest Totals
            getTotals();
        }
    }, [props.form, props.calculations]);

    return (
            <table id="SourcesUsesTable" className="h-full border-collapse">
                <thead className="">
                    <tr>
                        <th colSpan={5} className="p-4 border-b border-t border-l border-r border-black bg-gray-200 text-lg" >Use of Proceeds</th>
                    </tr>
                    <tr>
                        <th colSpan={3} className="p-4 border-b border-t border-l border-r border-black" >Sources</th>
                        <th colSpan={2} className="p-4 border-b border-t border-l border-r border-black" >Uses</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="table-cell border-2 border-black text-left">Loan</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(sourcesUsesValues.loanAmount)}</td>
                        <td className="table-cell border-2 border-black text-right">{sourcesUsesValues.loanAmountPercent.toFixed(2)}%</td>
                        <td className="table-cell border-2 border-black text-left">Total Land And Related Costs</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(sourcesUsesValues.totalLandAndRelatedCosts)}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-left">Borrower Equity</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(sourcesUsesValues.builderCashSum)}</td>
                        <td className="table-cell border-2 border-black text-right">{sourcesUsesValues.builderCashSumPercent.toFixed(2)}%</td>
                        <td className="table-cell border-2 border-black text-left">Total Site Improvement Costs</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(sourcesUsesValues.totalSiteImprovementCosts)}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-left">Other</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(sourcesUsesValues.investorCashSum)}</td>
                        <td className="table-cell border-2 border-black text-right">{sourcesUsesValues.investorCashSumPercent.toFixed(2)}%</td>
                        <td className="table-cell border-2 border-black text-left">Total Soft-Other Costs</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(sourcesUsesValues.totalSoftOtherCosts)}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-left">-</td>
                        <td className="table-cell border-2 border-black text-right">-</td>
                        <td className="table-cell border-2 border-black text-right">-</td>
                        <td className="table-cell border-2 border-black text-left">Interest Reserve</td>
                        <td className="table-cell border-2 border-black text-right">{Currency(sourcesUsesValues.interestReserve)}</td>
                    </tr>
                    <tr>
                        <td className="table-cell border-2 border-black text-left font-bold">Total Sources</td>
                        <td className="table-cell border-2 border-black text-right font-bold">{Currency(sourcesUsesValues.totalSources)}</td>
                        <td className="table-cell border-2 border-black text-right font-bold">100.00%</td>
                        <td className="table-cell border-2 border-black text-left font-bold">Total Uses</td>
                        <td className="table-cell border-2 border-black text-right font-bold">{Currency(sourcesUsesValues.totalUses)}</td>
                    </tr>
                </tbody>
            </table>
 );
}
