import { JVSpec, JVSpecCalculations, JVSTUserDetails } from "./JVSpecTypes";
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";
import { useEffect, useState } from "react";
import FormatDate from "../../helpers/FormatDate";
import { ConstructionDrawTable } from "./ConstructionDrawTable";
import { ConstructionInterestTable } from "./ConstructionInterestTable";
import { SourcesUsesTable } from "./SourcesUsesTable";

export function BuilderTermsBody(props: { form: JVSpec, calculations?: JVSpecCalculations }): JSX.Element {

    return (
        <div className="flex flex-col gap-y-2 p-2">
            <ConstructionDrawTable form={props.form} calculations={props.calculations} />
            <ConstructionInterestTable form={props.form} calculations={props.calculations} />
            <SourcesUsesTable form={props.form} calculations={props.calculations} />
        </div>
 );
}

export function BuilderTerms(props: { form: JVSpec, calculations: JVSpecCalculations, userDetails: JVSTUserDetails }): JSX.Element {
    // TODO: GET USER DETAILS FROM JWT CALL


    return (
        <div id="BuilderTerms" className="text-pretty">

            <BuilderTermsBody form={props.form} calculations={props.calculations} />

            <section>
                {/* Signatory */}
                <div className="col-span-12 mt-4">{props.userDetails.SignatoryName}</div>
                <div className="col-span-12">{props.userDetails.SignatoryPosition}</div>
                <div className="col-span-12">{props.userDetails.CompanyName}</div>
                <div className="col-span-12">{props.userDetails.PhoneNumber}</div>
                <div className="col-span-12">{props.userDetails.EmailAddress}</div>
            </section>
        </div>
    );
}
