import { useEffect, useState } from "react";

export interface BaseFieldProps {
    name: string;
    label: string;
    value?: string;
    saveChanges: (name: string, value: string) => void
    defaultValue?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeydown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: string;
    required?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    size?: "md" | "lg";
    autoFocus?: boolean;
    aiAction?: () => Promise<string | false>;
    noLabel?: boolean;
}

export default function BaseField(props: BaseFieldProps) {
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    const [aiInfo, setAiInfo] = useState<string>()
    const [internalValue, setInternalValue] = useState<string>(props.value || "")

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(e)
        const { value } = e.target
        setInternalValue(value)
    }

    const handleInvalid = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
        e.currentTarget.setCustomValidity(`Please enter a ${props.label.toLocaleLowerCase()}`)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (props.onBlur) props.onBlur(e);
        if (props.type === 'currency') return
        const { value } = e.target
        setInternalValue(value);
        props.saveChanges(props.name, value);

    };

    const handleAiClick = async () => {
        setIsInfoVisible(true)
        if (props.aiAction) {
            const result = await props.aiAction()
            if (result) {
                setAiInfo(result)
            }
        }
    }

    useEffect(() => {
        setInternalValue(props.value || "");
    }, [props.value]);

    return (
        <div className={`flex relative flex-wrap body-small `}>


            <input
                name={props.name}
                type={props.type === 'date' ? 'date' : props.type === 'checkbox' ? 'checkbox' : 'text'}
                id={props.name}
                value={internalValue}
                readOnly={props.readonly}
                disabled={props.disabled}
                onChange={handleChange}
                required={props.required}
                onInvalid={handleInvalid}
                onInput={(e) => e.currentTarget.setCustomValidity('')}
                onBlur={handleBlur}
                onKeyDown={props.onKeydown}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                className={` py-1 pl-2 ${props.type === 'checkbox' ? ' w-min mt-auto mb-[6px] h-[20px]' : ' w-full h-full'} ${props.type === 'percent' ? 'pr-[18px]' : 'pr-2'} read-only:outline-none outline-none focus:z-10 focus-within:-outline-offset-1 focus-within:outline-sky-500 border border-gray-700 read-only:bg-gray-200`}
            />
            {props.type === 'percent' && <span className={`absolute right-[6px] z-10 flex items-center h-full`}>%</span>}
        </div>
    )
}