import { FieldValues } from "react-hook-form"
import { field, mode } from "../../Types/FormTypes"
import Dropdown from "../../components/atoms/Dropdown"
import APIRequest from "../../helpers/CreateRequest"
import { task } from "./Procedures"
import Button from "../../components/atoms/Button"

type TaskTableType = {
    procedureID: string
    fields: field[],
    task?: task
    tasks?: task[],
    mode: mode,
    setMode: Function,
    refreshProcedure: boolean,
    setRefreshProcedure: Function,
    setTask: Function,
    emptySet: FieldValues

}

export default function TaskTable(props: TaskTableType) {

    function ModifyTaskHandler(task: task | undefined, mode: 'add' | 'edit' | 'delete', procedureID?: string) {
        props.setMode(mode)
        if (mode === 'add') {
            props.setTask({ ...props.emptySet })
        } else {
            props.setTask({ ...task, ProcedureID: procedureID })
        }
    }


    async function MoveTask(task: task, direction: -1 | 1) {
        try {
            const body = {
                TaskID: task.TaskID,
                Direction: direction
            }
            const result = await new APIRequest(
                `/tasks/order`,
                'POST',
                null,
                body
            ).GenerateRequest()

            const resultBody: { status: number; message: string } = await result.json()

            if (resultBody.status === 200) {
                props.setRefreshProcedure(!props.refreshProcedure)
            }
        }
        catch (err) {
            console.log(err)
        }

    }

    return (
        <div>
            <div>
                <Button
                    label='Add'
                    styleType="tonal"
                    action={() => ModifyTaskHandler(props.task, 'add', props.procedureID)}
                    type="button"
                />
            </div>
            <div >
                <table className="h-full overflow-y-auto mt-2 w-full border-collapse border border-white">
                    <thead>
                        {/* tableHeaderRow */}
                        <tr>
                            {
                                props.fields.map(field => (
                                    <th key={`${field.alias || field.name}Col`} className="border">{field.alias || field.name}</th>
                                ))
                            }
                            <th className="border"></th>
                        </tr>
                    </thead>
                    <tbody >
                        {/* tableRows */}
                        {
                            props.tasks && props.tasks.length > 0 ?

                                props.tasks.map(task => (
                                    <tr key={`task${task.TaskID}`}>
                                        <td className="p-1 border">{task.Description}</td>
                                        <td className="p-1 border">{task.DurationMinutes}</td>
                                        <td className="p-1 border">{task.PerformerRoleName}</td>
                                        <td className="p-1 border">
                                            <Dropdown options={[
                                                { name: 'Edit', action: () => ModifyTaskHandler(task, 'edit') },
                                                { name: 'Delete', action: () => ModifyTaskHandler(task, 'delete') },
                                                { name: 'Move Up', action: () => MoveTask(task, -1) },
                                                { name: 'Move Down', action: () => MoveTask(task, 1) },
                                            ]} />
                                        </td>
                                    </tr>
                                ))

                                :

                                <tr className="text-center w-full border">
                                    <td></td>
                                    <td>Add a task</td>
                                    <td></td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}