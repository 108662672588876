import { useEffect, useMemo, useState } from "react";
import Button from "../../components/atoms/Button";
import Modal from "../../components/atoms/Modal";
import Report from "./Report";
import CheckMarkProgress from "./CheckMarkProgress";
import { GeneralPage1, GeneralPage2, SchedulePage, RevenuePage, CostingPage1, CostingPage2, CostingPage3, CapitalPage, CompletePage } from "./Pages";
import { JVSpecDefaultValues, PageNumberType, ProgressType, type JVSpec, JVSTSections, JVSpecCalculations, JVSTFormSubmissionConversions } from "./JVSpecTypes"
import APIRequest from "../../helpers/CreateRequest";
import ReportModal from "./ReportModal";
import SelectField from "../../components/atoms/forms/SimpleFields/SelectField";
import { Currency, CurrencyToNumber } from "../../helpers/InputValueConverter";
import SnackBar from "../../components/molecules/SnackBar";
import NewIconButton from "../../components/atoms/NewIconButton";
import { usePrompt } from "../../hooks/usePrompt";
import FormatDate from "../../helpers/FormatDate";

const progessBarNextThreshholds = [2, 3, 4, 7, 8]
const progressBarPreviousThreshholds = [1, 2, 3, 6, 7]

type formStatusType = 'empty' | 'partial' | 'filled'


export default function JVSpec() {
    const [progress, setProgress] = useState<ProgressType>({ section: 0, page: 0 });
    const [form, setForm] = useState<JVSpec>(JVSpecDefaultValues)
    const [formBackup, setFormBackup] = useState<JVSpec>(JVSpecDefaultValues)
    const [triggerCalculations, setTriggerCalculations] = useState(false)
    const [isReportOpen, setIsReportOpen] = useState(false)
    const [isReportSelectOpen, setIsReportSelectOpen] = useState<'download' | 'email' | false>(false)
    const [calculations, setCalculations] = useState<JVSpecCalculations>()
    const [project, setProject] = useState({ ProjectID: -1 })
    const [formStatus, setFormStatus] = useState<formStatusType>('empty')
    const [refreshSelect, setRefreshSelect] = useState(0)
    const [message, setMessage] = useState({ message: '', refresh: false })
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false)
    const [isFormDirty, setIsFormDirty] = useState(false)

    usePrompt({
        when: isFormDirty,
        message: "You have unsaved changes. Are you sure you want to leave?",
    });

    const triggerChange = () => setTriggerCalculations(!triggerCalculations)
    const triggerRefreshSelect = () => setRefreshSelect(refreshSelect + 1)

    const componentsMap = useMemo(() => {
        return {
            0: <GeneralPage1 />,
            1: <GeneralPage2 form={form} setForm={setForm} />,
            2: <SchedulePage form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            3: <RevenuePage form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            4: <CostingPage1 form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            5: <CostingPage2 form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            6: <CostingPage3 form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            7: <CapitalPage form={form} setForm={setForm} triggerCalculations={triggerChange} />,
            8: <CompletePage />
        }
    }, [form, setForm, progress]);

    const getCalculations = async (localForm: JVSpec = form) => {
        try {
            const result = await new APIRequest('/JVSpec/calculations', 'POST', null, localForm).GenerateRequest()

            if (result.status === 200) {
                const body = await result.json()
                if (body) {
                    setCalculations(body)
                } else throw new Error('Error fetching calculations')
            } else throw new Error('Error fetching calculations')
        } catch (err) {
            console.log(err)
        }
    }

    const handleNext = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page + 1) as PageNumberType;
            const newSection = progessBarNextThreshholds.includes(newPage) ? prev.section + 1 : prev.section;
            return { section: newSection, page: newPage };
        });
    }

    const handlePrevious = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page - 1) as PageNumberType;
            const newSection = progressBarPreviousThreshholds.includes(newPage) ? prev.section - 1 : prev.section;
            return { section: newSection, page: newPage };
        })
    }

    const handleBubbleClick = (section: number) => {
        setProgress({ section: section, page: section === 0 ? 0 : progessBarNextThreshholds[section - 1] as PageNumberType }
        )
    }

    const saveProject = async () => {
        try {
            // convert form data to proper format
            const newForm: any = { ...form }
            const currentForm = { ...newForm }
            JVSTFormSubmissionConversions.currency.forEach(field => {
                newForm[field] = CurrencyToNumber(currentForm[field])
            })
            JVSTFormSubmissionConversions.percentage.forEach(field => {
                newForm[field] = currentForm[field] || currentForm[field] === 0 ? (CurrencyToNumber(currentForm[field]) || 0) / 100 : undefined
            })
            JVSTFormSubmissionConversions.date.forEach(field => {
                newForm[field] = currentForm[field] ? FormatDate(currentForm[field]) : undefined
            })

            const method = project.ProjectID > 0 ? 'PUT' : 'POST'
            if (project.ProjectID > 0) newForm.id = project.ProjectID

            const result = await new APIRequest('/JVSTProjects', method, null, newForm).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    if (method === 'POST') setProject({ ProjectID: body.id })
                    setMessage({ message: 'Project saved successfully', refresh: !message.refresh })
                    triggerRefreshSelect()
                    setFormBackup({ ...form })
                } else throw body.message
            } else throw result.statusText
        } catch (err) {
            console.log(err)
        }
    }

    const getProject = async () => {
        try {
            const result = await new APIRequest(`/JVSTProjects/unit`, 'POST', null, { id: project.ProjectID }).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200 && body.recordset) {
                    // convert to proper format
                    const newForm: any = { ...body.recordset[0] }
                    const currentForm = { ...newForm }
                    JVSTFormSubmissionConversions.currency.forEach(field => {
                        newForm[field] = Currency(currentForm[field])
                    })
                    JVSTFormSubmissionConversions.percentage.forEach(field => {
                        newForm[field] = currentForm[field] || currentForm[field] === 0 ? currentForm[field] * 100 : ""
                    })
                    JVSTFormSubmissionConversions.date.forEach(field => {
                        newForm[field] = currentForm[field] ? FormatDate(currentForm[field], true) : undefined
                    })        
                    for (let key in newForm) {
                        if (newForm[key] === null) newForm[key] = ''
                    }
                    setForm(newForm)
                    setFormBackup(newForm)
                    getCalculations(newForm)
                } else throw body.message
            } else throw result.statusText
        } catch (err) {
            console.log(err)
        }
    }

    const deleteProject = async () => {
        try {
            const result = await new APIRequest(`/JVSTProjects`, 'DELETE', null, { id: project.ProjectID }).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    setProject({ ProjectID: -1 })
                    setForm(JVSpecDefaultValues)
                    setCalculations(undefined)
                    setMessage({ message: 'Project deleted successfully', refresh: !message.refresh })
                    triggerRefreshSelect()
                } else throw body.message
            } else throw result.statusText
        } catch (err) {
            console.log(err)
        }
    }

    // check if form is dirty or not
    useEffect(() => {
        const isDirty = JSON.stringify(form) !== JSON.stringify(formBackup);
        setIsFormDirty(isDirty);
    }, [form, formBackup])

    useEffect(() => { getCalculations() }, [triggerCalculations])

    // get project details when project id changes
    useEffect(() => {
        if (project.ProjectID > 0) {
            getProject()
        } else if (project.ProjectID <= 0) {
            setForm(JVSpecDefaultValues)
            setCalculations(undefined)
            setProgress({ section: 0, page: 0 })
        }
    }, [project.ProjectID])

    // check if form is partially empty, partially filled or fully filled
    useEffect(() => {
        let status: formStatusType = 'empty'
        let isFilled = true
        const currentForm: any = { ...form }
        for (const key in currentForm) {
            if (currentForm[key] && currentForm[key] !== '') status = 'partial'
            else isFilled = false
        }

        if (isFilled) status = 'filled'

        setFormStatus(status)
    }, [form])


    return (
        <div className="flex flex-col w-full h-full">
            <nav className="w-full h-[60px] bg-white-200 border-b-2 px-4 flex justify-between gap-1">
                <span className="flex  gap-x-5">
                    <h1 className="flex text-base font-semibold items-center ">
                        Joint Venture Spec Tool
                    </h1>

                    {/* select input for projects  */}
                    <span className="flex m-auto ">
                        <SelectField name="ProjectID" size="md" label="Project" value={String(project.ProjectID).valueOf()} setValue={setProject} noLabel requestDetails={{ name: 'JVSTProject', namePlural: 'JVSTProjects', }} refreshKey={refreshSelect} />
                    </span>

                    <span className="flex m-auto gap-x-1">
                        <NewIconButton title="Save" size="sm" action={saveProject} type="button" disabled={!isFormDirty || !form.Name} icon={
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#334155" xmlns="http://www.w3.org/2000/svg" className="m-auto">
                                <path fillRule="evenodd" d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                            </svg>
                        }
                        />
                        {project.ProjectID > 0 && <NewIconButton title="New" size="sm" action={() => setProject({ ProjectID: -1 })} type="button" icon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>} />}

                        {project.ProjectID > 0 && <NewIconButton title="Delete" size="sm" action={() => setIsDeleteWarningOpen(true)} type="button" icon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>
                        } />}


                    </span>
                </span>

                <span className="flex m-auto mr-0 gap-x-1">
                    <span className="2xl:hidden">
                        <NewIconButton title="Reports" size="sm" action={() => setIsReportOpen(true)} type="button" icon={
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M330-250h300v-60H330v60Zm0-160h300v-60H330v60Zm-77.69 310Q222-100 201-121q-21-21-21-51.31v-615.38Q180-818 201-839q21-21 51.31-21H570l210 210v477.69Q780-142 759-121q-21 21-51.31 21H252.31ZM540-620v-180H252.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v615.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h455.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-620H540ZM240-800v180-180V-160v-640Z" /></svg>
                        } />
                    </span>
                    <NewIconButton title="Download" size="sm" action={() => setIsReportSelectOpen('download')} type="button" icon={
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
                    } />
                    <NewIconButton title="Email" size="sm" action={() => setIsReportSelectOpen('email')} type="button" icon={
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#334155"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" /></svg>
                    } />
                </span>

            </nav>


            <div className="flex w-full h-[calc(100%-60px)]">
                <main className=" h-full flex-grow m-auto max-w-[1140px] overflow-auto py-5">
                    <header className="grid grid-cols-2 w-[580px] mx-auto flex-wrap">
                        <div className="w-full col-span-2 justify-center flex ">
                            <CheckMarkProgress key='CheckMarkProgress' progress={progress.section} steps={["General", "Schedule", "Revenue", "Costs", "Capital"]} handleClick={handleBubbleClick} form={form} formSectionsAndfields={JVSTSections} />
                        </div>
                    </header>

                    <form className="w-[580px] mx-auto"  >
                        {componentsMap[progress.page] || <div>Error</div>}
                    </form>

                    <section className=" flex mx-auto w-[580px] pt-10 flex-row-reverse">
                        <span className="m-auto mr-0">
                            {progress.page < 8 && <Button label="Next" action={handleNext} styleType="tonal" type="button" width="w-[100px]" />}
                        </span>

                        <span className="m-auto ml-0">
                            {progress.page > 0 && <Button label="Previous" action={handlePrevious} styleType="tonal" type="button" width="w-[100px]" />}
                        </span>
                    </section>
                </main>


                <aside className=" hidden 2xl:flex flex-grow h-full w-full max-w-[700px] overflow-auto  bg-gray-100 width">
                    <Report form={form} setForm={setForm} calculations={calculations} getCalculations={getCalculations} />
                </aside>

                {
                    isReportOpen && (
                        <Modal
                            className="w-full max-w-[720px]  m-10 max-h-[90%] h-full bg-gray-100"
                            onClose={() => setIsReportOpen(false)}>
                            <Report form={form} setForm={setForm} calculations={calculations} getCalculations={getCalculations} closeIfModal={() => setIsReportOpen(false)} />
                        </Modal>)
                }

                {calculations && isReportSelectOpen && <ReportModal isReportSelectOpen={isReportSelectOpen} setIsReportSelectOpen={setIsReportSelectOpen} form={form} calculations={calculations} />}

            </div>

            {isDeleteWarningOpen && <Modal onClose={() => setIsDeleteWarningOpen(false)} className=" flex bg-white rounded-lg px-6 py-5 flex-col gap-y-5">
                <h2 className="font-bold text-lg">Are you sure you want to delete the project?</h2>
                <div className="flex gap-x-3 justify-between">
                    <Button label="No" action={() => setIsDeleteWarningOpen(false)} styleType="tonal" type="button" width="w-[80px]" />

                    <Button label="Yes" action={() => { deleteProject(); setIsDeleteWarningOpen(false) }} styleType="tonal" type="button" width="w-[80px]" />
                </div>
            </Modal>
            }

            <SnackBar message={message} />
        </div>
    )
}
