import { useEffect, useState } from "react";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import Tasks from "./Tasks";
import APIRequest from "../../helpers/CreateRequest";

const name = {
    normal: 'Procedure',
    plural: 'Procedures',
    alias: 'Procedure',
    aliasPlural: 'Procedures',
    data: 'Procedure',
    dataPlural: 'Procedures',
    sectionTitle: 'Operations Management'
}

const fields: field[] = [
    { name: 'ProcedureName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'lg' },
    { name: 'Description', type: 'text', size: 'xl', },
    { name: "Code", type: "text", size: "md", required: true },
    { name: 'RevisionNumber', type: 'text', size: 'md', alias: 'Revision Number', required: true },
    { name: 'Status', type: 'select', size: 'md', required: true, SelectElement: { name: 'Status', namePlural: 'Statuses', staticOptions: [{ name: 'Draft', value: 'Draft' }, { name: 'Done', value: 'Done' }] } },
    { name: 'OwnerRoleID', type: 'search', size: 'lg', alias: 'Owner', SelectElement: { name: 'Role', namePlural: 'Roles' } },
    { name: 'ApproverRoleID', type: 'search', size: 'lg', alias: 'Approved By', SelectElement: { name: 'Role', namePlural: 'Roles' } },
    { name: 'EffectiveDate', type: 'date', size: 'md', alias: 'Effective' },
    { name: 'Prerequisites', type: 'text', size: 'md', },
    { name: 'Source', type: 'text', size: 'md', },
    { name: 'FunctionalAreaID', type: 'select', size: 'md', alias: 'Functional Area', SelectElement: { name: 'FunctionalArea', namePlural: 'FunctionalAreas' } },
]

export type procedure = {
    ProcedureID: string,
    ProcedureName: string,
    Description: string,
    [key: string]: any;
    tasks: task[]
}

export type task = {
    TaskID: string,
    Description: string,
    DurationMinutes: string,
    PerformerRoleID: number | string,
    PerformerRoleName: string,
    ProcedureID: string,
    [key: string]: any
}


export default function Procedures() {
    const [obj, setObj] = useState<procedure>()
    const [objID, setObjID] = useState()
    const [mode, setMode] = useState<mode>('view')
    const [refresh, setRefresh] = useState(false)
    const emptySet: FieldValues = Object.fromEntries(fields.map(field => [field.sendAs || field.name, null]));

    const [taskMode, setTaskMode] = useState<mode>('view')
    const [task, setTask] = useState<task>()
    const [tasks, setTasks] = useState<task[]>()

    //Get object when the id changes or a refresh is called 
    useEffect(() => {
        const getObj = async () => {
            if (objID) {
                const obj = await GetObj(objID, name.dataPlural, fields)
                if (obj !== -1) {
                    setObj(obj)
                    setMode('view')
                }
            }
        }
        getObj()
    }, [objID, refresh])


    useEffect(() => {
        const getTasks = async () => {
            if (objID === undefined) return
            try {
                const result: any = await ((await new APIRequest(`/Procedures/unit`, "POST", null, { id: objID }).GenerateRequest()).json())
                const body: any[] = result.recordsets[1]
                if (body) {
                    const tasks: task[] = body.map(task => task)
                    setTasks(tasks)
                }
            }
            catch (err) {
                console.log(err)
            }
        }

        getTasks()

    }, [obj])

    return (
        <>
            <div className="py-10 px-10 h-full overflow-y-auto">

                <CRUD
                    name={name}
                    fields={fields}
                    obj={obj}
                    id={objID}
                    setID={setObjID}
                    mode={mode}
                    setMode={setMode}
                    emptySet={emptySet}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    extraButtons={[]}
                />


                {/* Details or sections */}
                <div className="flex-grow w-full m-auto">
                    {
                        <Tasks
                            procedureID={objID}
                            task={task}
                            tasks={tasks}
                            setTask={setTask}
                            refreshProcedure={refresh}
                            setRefreshProcedure={setRefresh}
                            mode={taskMode}
                            setMode={setTaskMode}
                        />
                    }
                </div>
            </div>
        </>
    )
}