import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

interface PromptProps {
    when: boolean;
    message: string;
}

export function usePrompt({ when, message }: PromptProps): void {
    const { navigator } = useContext(NavigationContext) as any;

    useEffect(() => {
        if (!when) return;

        const originalPush = navigator.push;
        const originalReplace = navigator.replace;

        const handleNavigation = (method: (...args: any[]) => void) => (...args: any[]) => {
            const allowTransition = window.confirm(message);
            if (allowTransition) {
                method(...args);
            }
        };

        navigator.push = handleNavigation(originalPush);
        navigator.replace = handleNavigation(originalReplace);

        return () => {
            navigator.push = originalPush;
            navigator.replace = originalReplace;
        };
    }, [when, message, navigator]);


    // logic for unload
    useEffect(() => {
        // Handle browser back/forward/refresh/ close
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (when) {
                event.preventDefault();
                event.returnValue = message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [when, message]);
}
