import { formSubmissionConversions } from "../../Types/FormTypes"

export type Events = {
    event: string,
    date: Date,
    balance?: number
    days?: number
}[]
 
export type MonthlyConstructionInterest = {
    Order: number,
    Period: string,
    Events: Events,
    Balance: number,
    Interest: number
  }[] 
  
export type JVSpec = {
    // General
    Name: string,
    Description: string,
    Address: string,
    // schedule
    LandAcquisitionDate: string,
    PeriodPreConstructionMonths: string,
    PeriodConstructionMonths: string,
    PeriodMarketingMonths: string
    PeriodSettlementMonths: string,
    // product and Revenue
    SalePriceEstimatedSum: string,
    UnitSquareFootageTotal: string,

    // project costs 
    // acquisition
    LandPurchaseSum: string,
    // Planning and Permitting
    DesignPlanningSum: string,
    // Site Improvements
    SiteImprovementsSum: string,
    // direct construction costs
    DirectConstructionSum: string,
    // contingency
    ContingencyPercentage: string,
    // sales and marketing
    CommissionsPercentage: string,
    // Disposition
    TransferTaxesOtherDispositionSum: string,
    // Financing
    FinanceExpensesSum: string,
    CommercialBorrowingInterestRatePercentage: string,
    // Other Project expenses
    OtherProjectExpensesSum: string,

    // Deal expenses
    ProjectManagementRatePercentage: string,
    InvestorCashInterestRatePercentage: string,

    // capital stack 
    InvestorCashSum: string,
    BuilderCashSum: string,
    IRRMinimumPercentage: string,
    IRRMaximumPercentage: string
}

export const JVSpecDefaultValues: JVSpec = {
    // General
    Name: "",
    Description: "",
    Address: "",
    // schedule
    LandAcquisitionDate: "",
    PeriodPreConstructionMonths: "",
    PeriodConstructionMonths: "",
    PeriodMarketingMonths: "",
    PeriodSettlementMonths: "",
    // product and Revenue
    SalePriceEstimatedSum: "",
    UnitSquareFootageTotal: "",

    // project costs 
    // acquisition
    LandPurchaseSum: "",
    // Planning and Permitting
    DesignPlanningSum: "",
    // Site Improvements
    SiteImprovementsSum: "",
    // direct construction costs
    DirectConstructionSum: "",
    // contingency
    ContingencyPercentage: "",
    // sales and marketing
    CommissionsPercentage: "",
    // Disposition
    TransferTaxesOtherDispositionSum: "",
    // Financing
    FinanceExpensesSum: "",
    CommercialBorrowingInterestRatePercentage: "",
    // Other Project expenses
    OtherProjectExpensesSum: "",

    // Deal expenses
    ProjectManagementRatePercentage: "",
    InvestorCashInterestRatePercentage: "",

    // capital stack 
    InvestorCashSum: "",
    BuilderCashSum: "",
    IRRMinimumPercentage: "",
    IRRMaximumPercentage: ""
}

export const JVSTSections = [
    //General
    [
        "Name", "Description", "Address",
    ],
    //Schedule:
    [
        "LandAcquisitionDate", "PeriodPreConstructionMonths", "PeriodConstructionMonths", "PeriodMarketingMonths", "PeriodSettlementMonths",
    ],
    //Revenue: 
    [
        "SalePriceEstimatedSum", "UnitSquareFootageTotal",
    ],
    //Costs:
    [
        "LandPurchaseSum", "DesignPlanningSum", "SiteImprovementsSum", "DirectConstructionSum", "ContingencyPercentage",
        "CommissionsPercentage", "TransferTaxesOtherDispositionSum", "FinanceExpensesSum", "CommercialBorrowingInterestRatePercentage",
        "OtherProjectExpensesSum", "ProjectManagementRatePercentage", "InvestorCashInterestRatePercentage",
    ],
    //Capital:
    [
        "InvestorCashSum", "BuilderCashSum", "IRRMinimumPercentage", "IRRMaximumPercentage",
    ]
]

export type JVSpecCalculations = {
    TotalDuration?: string
    TotalCash?: string,
  
    SalePricePerSquareFoot?: string,
  
    Contingency?: string,
    Commissions?: string,
  
    FinanceExpensesPercent?: string,
    ConstructionInterest?: string,
    LoanAmount?: string,
    LoanToValuePercent?: string,
    TotalFinancingCosts?: string,
    TotalLandAndConstructionFunds?: string,
    TotalCosts?: string,
    DirectConstructionCostsPerSquareFoot?: string,
  
    GrossProfit?: string,
    GrossMargin?: string,
    ProjectManagementFee?: string,
    InterestOnInvestmentCashToInvestor?: string,
    TotalDealExpenses?: string,
    NetIncome?: string,
  
    InvestorEquityPercent?: string,
    BuilderEquityPercent?: string,
  
    InvestorBaseReturnPercent?: string,
    BuilderBaseReturnPercent?: string,
  
    DistributionAmountOne?: string,
    InvestorDistributionAmountOne?: string,
    BuilderDistributionAmountOne?: string,
    BalanceAfterDistributionOne?: string,
    IRRAfterDistributionOne?: string,
  
    InvestorGrowthReturnPercent?: string,
    BuilderGrowthReturnPercent?: string,
  
    DistributionAmountTwo?: string,
    InvestorDistributionAmountTwo?: string,
    BuilderDistributionAmountTwo?: string,
    BalanceAfterDistributionTwo?: string,
    IRRAfterDistributionTwo?: string,
  
    InvestorPerformanceReturnPercent?: string,
    BuilderPerformanceReturnPercent?: string,
  
    DistributionAmountThree?: string,
    InvestorDistributionAmountThree?: string,
    BuilderDistributionAmountThree?: string,
  
    InvestorDistributionTotal?: string,
    BuilderDistributionTotal?: string,
  
    InvestorCashOut?: string,
    BuilderCashOut?: string,
    InvestorROE?: string,
    BuilderROE?: string,
    FinalIRR?: string,
  
    // Construction Interest -- Draw Schedule
    ProjectEvents?: Events,
    MonthlyConstructionInterest?: MonthlyConstructionInterest,
  
  }
  

export type JVSTUserDetails = {
    Logo: JSX.Element;
    CompanyName: string;
    PhoneNumber: string;
    EmailAddress: string;
    SignatoryName: string;
    SignatoryPosition: string;
    SignatoryTitle: string;
}

export interface InvestorTermsProps extends JVSpec, JVSpecCalculations {
    Logo: JSX.Element;
    CompanyName: string;
    PhoneNumber: string;
    EmailAddress: string;
    SignatoryName: string;
    SignatoryPosition: string;
}

export type PageNumberType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export type ProgressType = {
    section: number,
    page: PageNumberType
}


export const JVSTFormSubmissionConversions: formSubmissionConversions = {
    currency: ['SalePriceEstimatedSum', 'LandPurchaseSum', 'DesignPlanningSum', 'SiteImprovementsSum', 'DirectConstructionSum', 'TransferTaxesOtherDispositionSum', 'FinanceExpensesSum', 'OtherProjectExpensesSum', 'InvestorCashSum', 'BuilderCashSum'],
    percentage: ['ContingencyPercentage', 'CommissionsPercentage', 'CommercialBorrowingInterestRatePercentage', 'ProjectManagementRatePercentage', 'InvestorCashInterestRatePercentage', 'IRRMinimumPercentage', 'IRRMaximumPercentage'],
    date: ['LandAcquisitionDate'],
}