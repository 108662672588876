
export const PropertyDescriptionMap = new Map<string, string>()
    // project
    .set("project_type", "")
    .set("project_name", "Enter a unique and descriptive name for the project. This name should help you and the investor easily identify and reference the project in all communications and documents.")
    .set("project_code", "Provide a unique code or reference number for the project. This code can be used to track and organize multiple projects effectively.")
    .set("project_projectNo", "Provide a unique identification number for the project. This can help track and organize multiple projects effectively.")
    .set("project_projectPhase", "Indicate the current phase of the project, such as \"Planning,\" \"Design,\" \"Construction,\" or \"Completed.\" This helps track the project's progress.")
    .set("project_feasibilityMethod", "The method or approach used to assess the project's feasibility, such as cost analysis, market study, or profitability projection.")
    .set("project_feasibilityGrossMarginAmount", "The expected gross margin amount, calculated as the difference between the project's revenue and direct costs. This helps determine profitability.")
    .set("project_feasibilityGrossMarginPercentage", "The gross margin percentage, which represents the ratio of gross margin to total revenue. This provides a quick snapshot of profitability.")
    // lot
    .set("lot_name", "Enter the name or identifier of the lot where the project will be developed. This helps in associating project details with specific land parcels.")
    .set("lot_cost", "Enter the estimated cost of acquiring the land where the spec home will be built. This includes the price of the land itself and any associated purchase fees.")
    // structure
    .set("structure_name", "Provide a descriptive name for the structure being built, such as \"Model Home,\" \"Spec Home,\" or \"Custom Build.\"")
    .set("structure_salePrice", "Input the projected selling price of the home once it's completed. This estimate should reflect current market conditions and expected value based on similar properties in the area.")
    .set("structure_cost", "Enter the estimated cost of constructing the home, including materials, labor, permits, and other direct expenses.")
    .set("structure_lot", "The lot or land parcel where the home will be built.")
