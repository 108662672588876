import { useDrop } from "react-dnd";
import { DraggableItem, ItemType, ProjectItems } from "./ProjectTypes";
import CanvasItem from "./CanvasItem";

type CanvasProps = {
    project: ProjectItems
    projectID: string
    selectedItemID: string;
    changeSelectedItemID: (id: string) => void
    addProjectItem: (item: ItemType) => void
    deleteProjectItem: (itemID: string, project: ProjectItems) => void
};

export default function Canvas({ project, projectID, selectedItemID, addProjectItem, deleteProjectItem, changeSelectedItemID }: CanvasProps) {
    const [{ isOver, isOverCurrent }, drop] = useDrop(() => ({
        accept: ['lot'],
        drop: (draggableItem: DraggableItem, monitor) => {
            const didDrop = monitor.didDrop();

            if (didDrop && !monitor.isOver({ shallow: true })) return;

            addProjectItem(draggableItem.type);

        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            isOverCurrent: !!monitor.isOver({ shallow: true })
        }),
    }));

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        changeSelectedItemID(projectID)
    }

    return (
        <div
            ref={drop}
            className={`flex flex-wrap gap-4 h-full w-full items-center justify-center overflow-auto p-4 bg-neutral-50  ${isOver ? 'bg-blue-200' : ''} z-0 gap-y-4`}
            style={{ position: 'relative' }}
            onClick={handleClick}
        >
            {
                [...project.keys()].map(key => {
                    const item = project.get(key)
                    if (item && !item.parentID && item.type !== 'project') {
                        return <CanvasItem key={key} project={project} item={item} selectedItemID={selectedItemID} nestLevel={0} addProjectItem={addProjectItem} deleteProjectItem={deleteProjectItem} changeSelectedItemID={changeSelectedItemID} />
                    }
                })
            }
        </div>
    );
}
