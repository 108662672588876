import { JVSpec, JVSpecCalculations, JVSTUserDetails } from "./JVSpecTypes";
import { ConstructionDrawTable } from "./ConstructionDrawTable";
import { ConstructionInterestTable } from "./ConstructionInterestTable";
import { SourcesUsesTable } from "./SourcesUsesTable";

export function LoanTermsBody(props: { form: JVSpec, calculations?: JVSpecCalculations }): JSX.Element {
    return (
        <div className="flex flex-col gap-y-2 p-2">
            <main className="grid grid-cols-12 gap-y-2 py-3">
                {/* Purpose */}
                <span className="font-bold col-span-2">Purpose</span>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-10">&nbsp;</span>
                    <span className="col-span-10">
                        To secure financing for a high-quality, luxury single-family spec home that meets market demand. The project aims to achieve a solid return on investment through careful planning, efficient construction, and thoughtful market positioning.
                    </span>
                </div>

                {/* Project Summary */}
                <span className="font-bold col-span-12">Project Summary</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-3">Total Project Cost :</span>
                    <span className="col-span-9">{props.calculations?.TotalCosts}</span>

                    <span className="col-span-3">Estimated Sale Price :</span>
                    <span className="col-span-9">{props.form.SalePriceEstimatedSum}</span>

                    <span className="col-span-3">Estimated Gross Profit:</span>
                    <span className="col-span-9">{props.calculations?.GrossProfit}</span>

                    <span className="col-span-3">Estimated Gross Margin:</span>
                    <span className="col-span-9">{props.calculations?.GrossMargin}{props.calculations?.GrossMargin ? '%' : ''}</span>
                </div>

                {/* Project Schedule */}
                <span className="font-bold col-span-12">Project Schedule</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-7">Duration to Construction Start (Pre-Con Period):</span>
                    <span className="col-span-2 text-right">{props.form.PeriodPreConstructionMonths} {props.form?.PeriodPreConstructionMonths ? props.form.PeriodPreConstructionMonths === '1' ? 'month' : 'months' : ''}</span>

                    <span className="col-span-7">Duration of Construction (Construction Period):</span>
                    <span className="col-span-2 text-right">{props.form.PeriodConstructionMonths} {props.form?.PeriodConstructionMonths ? props.form.PeriodConstructionMonths === '1' ? 'month' : 'months' : ''}</span>

                    <span className="col-span-7">Duration From Construction End to Sale (Marketing Period):</span>
                    <span className="col-span-2 text-right">{props.form.PeriodMarketingMonths} {props.form?.PeriodMarketingMonths ? props.form.PeriodMarketingMonths === '1' ? 'month' : 'months' : ''}</span>

                    <span className="col-span-7">Duration From Sale to Settlement (Settlement Period):</span>
                    <span className="col-span-2 text-right">{props.form.PeriodSettlementMonths} {props.form?.PeriodSettlementMonths ? props.form.PeriodSettlementMonths === '1' ? 'month' : 'months' : ''}</span>

                    <span className="col-span-7">Total Duration:</span>
                    <span className="col-span-2 text-right">{props.calculations?.TotalDuration} {props.calculations?.TotalDuration ? props.calculations.TotalDuration === '1' ? 'month' : 'months' : ''}</span></div>

                {/* Cost Summary */}
                <span className="font-bold col-span-12">Cost Summary</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-5">Land Purchase :</span>
                    <span className="col-span-7">{props.form.LandPurchaseSum}</span>

                    <span className="col-span-5">Design & Planning:</span>
                    <span className="col-span-7">{props.form.DesignPlanningSum}</span>

                    <span className="col-span-5">Site Improvements:</span>
                    <span className="col-span-7">{props.form.SiteImprovementsSum}</span>

                    <span className="col-span-5">Direct Construction:</span>
                    <span className="col-span-7">{props.form.DirectConstructionSum}</span>

                    <span className="col-span-5">Contingency Fund {props.form.ContingencyPercentage ? `(${props.form.ContingencyPercentage}%)` : ''}:</span>
                    <span className="col-span-7">{props.calculations?.Contingency}</span>

                    <span className="col-span-5"></span>
                    <span className="col-span-1 border-b-2 pb-2 border-black"></span>
                    <span className="col-span-3"></span>

                    <span className="col-span-5">Total Construction Costs :</span>
                    <span className="col-span-7">{props.calculations?.TotalLandAndConstructionFunds}</span>

                    <span className="col-span-5">Selling, Financing & Disposition Costs :</span>
                    <span className="col-span-7">{props.calculations?.TotalFinancingCosts}</span>

                    <span className="col-span-5"></span>
                    <span className="col-span-1 border-b-2 pb-2 border-black"></span>
                    <span className="col-span-3"></span>

                    <span className="col-span-5">Total Costs :</span>
                    <span className="col-span-7">{props.calculations?.TotalCosts}</span>
                </div>

                {/* Financing Assumptions */}
                <span className="font-bold col-span-12">Financing Assumptions</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-5">Estimated Loan Amount:</span>
                    <span className="col-span-7">{props.calculations?.LoanAmount}</span>

                    <span className="col-span-5">Loan-to-Cost (LTC) Ratio:</span>
                    <span className="col-span-7">{props.calculations?.LoanToValuePercent}{props.calculations?.LoanToValuePercent ? '%' : ''}</span>

                    <span className="col-span-5">Interest Rate :</span>
                    <span className="col-span-7">{props.calculations?.FinanceExpensesPercent}{props.calculations?.FinanceExpensesPercent ? '%' : ''}</span>

                    <span className="col-span-5">Loan Term :</span>
                    <span className="col-span-7">{props.calculations?.TotalDuration} Months</span>

                    <span className="col-span-5">Estimated Financing & Transaction Costs :</span>
                    <span className="col-span-7">{props.calculations?.TotalFinancingCosts}</span>

                    <span className="col-span-5">Borrowing Interest:</span>
                    <span className="col-span-7">{props.calculations?.ConstructionInterest}</span>
                </div>
            </main>
            <ConstructionDrawTable form={props.form} calculations={props.calculations} />
            <ConstructionInterestTable form={props.form} calculations={props.calculations} />
            <SourcesUsesTable form={props.form} calculations={props.calculations} />
        </div>
    );
}

export default function LoanTerms(props: { form: JVSpec, calculations: JVSpecCalculations, userDetails: JVSTUserDetails }): JSX.Element {

    return (
        <div id="LoanTerms" className="text-pretty" >
            <LoanTermsBody form={props.form} calculations={props.calculations} />

            <section>
                {/* Signatory */}
                <div className="col-span-12 mt-4">{props.userDetails.SignatoryName}</div>
                <div className="col-span-12">{props.userDetails.SignatoryTitle}</div>
                <div className="col-span-12">{props.userDetails.CompanyName}</div>
                <div className="col-span-12">{props.userDetails.PhoneNumber}</div>
                <div className="col-span-12">{props.userDetails.EmailAddress}</div>
            </section>

        </div>
    )
}