import { ResponsiveLine } from '@nivo/line'
import { lineChartData } from '../ReportTypes'
import { colorFunction } from './ActualPerformanceReport'

export default function LineChart({ data, axisLeftLegend, yFormat, windowWidth }: { data: lineChartData, axisLeftLegend: string, yFormat: string, windowWidth?: number }) {

    const yMax: number = data.flatMap(d => d.data.map(p => p.y)).reduce((a, b) => Math.max(a ?? 0, b ?? 0), 0) ?? 0

    const yTicks = (yMin: number, yMax: number) => {
        const tickArray = []
        const tickCount = 5
        const tickInterval = (yMax - yMin) / 1 <= tickCount ? 1 : (yMax - yMin) / 2 <= tickCount ? 2 : (yMax - yMin) / 3 <= tickCount ? 3 : (yMax - yMin) / 5 <= tickCount ? 5 : 10; 

        for (let i = 0; i <= tickCount; i++) {
            tickArray.push(Math.round(yMin + (tickInterval * i)))
        }

        return tickArray
    }


    return (
        <ResponsiveLine
            key={windowWidth}
            data={data}
            margin={{ top: 30, right: 120, bottom: 70, left: 80 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 0,
                max: yTicks(0, yMax)[yTicks(0, yMax).length - 1],
                stacked: false,
                reverse: false,
            }}
            yFormat={yFormat}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
                legend: 'Year',
                legendOffset: 65,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisLeftLegend,
                legendOffset: -70,
                legendPosition: 'middle',
                truncateTickAt: 0,
                tickValues: yTicks(0, yMax)
            }}
            colors={data.map(d => colorFunction(d.id))}
            pointSize={6}
            pointColor={{ from: 'color', modifiers: [] }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            enablePoints={true}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: true,
                    translateX: 100,
                    translateY: 50,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 50,
                    itemHeight: 15,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    )
}
