import { communityHouse } from './MonthlyPerformanceReport'

export default function DotCell({house, month}: { house: communityHouse, month: string }) {

    return (
        <div className={`flex items-center justify-center font-bold p-2 border-r border-l border-gray-300`}>
            {
                house.monthName === month 
                ?
                <div className={`w-4 h-4 rounded-full ${house.eventStatus === 'Estimated' ? 'bg-pale-green' : house.eventStatus === 'Scheduled' ? 'bg-light-blue' : 'bg-gray-400' }`}></div>
                : <></>
            }
    </div>
)
}