import { useEffect, useMemo, useState } from "react";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import { useLocation } from "react-router-dom";

const name = {
  normal: 'Role',
  plural: 'Roles',
  alias: 'Role',
  aliasPlural: 'Roles',
  data: 'Role',
  dataPlural: 'Roles',
  sectionTitle: 'Operations Management'

}

const fields: field[] = [
  { name: 'RoleName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'lg' },
  { name: 'Description', type: 'text', size: 'xl', },
  { name: 'Code', type: 'text', required: true, size: 'md' },
  { name: "PositionID", type: "search", size: 'lg', SelectElement: { name: 'Position', namePlural: 'Positions' }, alias: "Position", required: true }
]


export default function Roles() {
  const location = useLocation()
  const stateID = location.state?.id || undefined
  const [obj, setObj] = useState<any>()
  const [objID, setObjID] = useState(stateID)
  const [mode, setMode] = useState<mode>('view')
  const [refresh, setRefresh] = useState(false)

  const emptySet: FieldValues = useMemo(() => Object.fromEntries(fields.map(field => [field.sendAs || field.name, null])), [])

  //Get object when the id changes or a refresh is called 
  useEffect(() => {
    const getObj = async () => {
      if (objID) {
        const obj = await GetObj(objID, name.dataPlural, fields)
        if (obj !== -1) {
          setObj(obj)
          setMode('view')
        }
      }
    }
    getObj()
  }, [objID, refresh])

  return (
    <>
      <div className="py-10 px-10 h-full overflow-y-auto">

        <CRUD
          name={name}
          fields={fields}
          obj={obj}
          id={objID}
          setID={setObjID}
          mode={mode}
          setMode={setMode}
          emptySet={emptySet}
          refresh={refresh}
          setRefresh={setRefresh}
          extraButtons={[]}
        />

        {/* Details or sections */}
        <div className="flex-grow w-full m-auto">

        </div>
      </div>
    </>

  )
}
