import { type JVSpec } from "./JVSpecTypes";
import TextField from "../../components/atoms/forms/SimpleFields/TextField";
import NumberField from "../../components/atoms/forms/SimpleFields/NumberField";
import CurrencyField from "../../components/atoms/forms/SimpleFields/CurrencyField";
import PercentField from "../../components/atoms/forms/SimpleFields/PercentField";
import DateField from "../../components/atoms/forms/SimpleFields/DateField";
import RequestAI from "./RequestAI";
import TextareaField from "../../components/atoms/forms/SimpleFields/TextareaField";


function InputDescription({ text, additionalClasses }: { text: string, additionalClasses?: string }) {
    return <p className={`body-small  ${additionalClasses ? additionalClasses : ' '}`}>{text}</p>
}

export function GeneralPage1() {

    return (
        <div className="flex flex-grow flex-wrap text-wrap overflow-auto  w-[580px] min-w-[580px] body-small">

            <div className="text-wrap">
                <p>
                    The Joint Venture Spec Tool simplifies builder-investor collaboration for the financing and construction of a spec home by providing a comprehensive view of crucial financial data and anticipated outcomes. It equips home builders to attract and engage investors by collecting key inputs such as sales price, construction costs, project schedule, capital contributions, and profit distribution. By promoting transparency, the tool helps both parties gain a clear understanding of capital requirements and potential returns.
                </p>
                <br />
                <p>
                    To ensure a smooth experience, please gather the following information before you begin:
                </p>

                <ul className="list-disc p-5 grid grid-cols-2 gap-x-4 text-nowrap">
                    <li>Deal Name</li>
                    <li>Description</li>
                    <li>Months Held Until Settlement</li>
                    <li>Months Construction</li>
                    <li>Months Hold Until Construction</li>
                    <li>House Estimated Sell Price</li>
                    <li>Land Purchase</li>
                    <li>Design Planning</li>
                    <li>Site Improvements</li>
                    <li>Direct Construction</li>
                    <li>Commissions %</li>
                    <li>Transfer Taxes and Other Disposition</li>
                    <li>Other Project Expenses</li>
                    <li>Project Management Rate</li>
                    <li>Rate for Investor Cash</li>
                    <li>Investor Cash</li>
                    <li>Builder Cash</li>
                    <li>Minimum IRR</li>
                    <li>Maximum IRR</li>
                    <li >Commercial Finance Costs</li>
                    <li className="col-span-2">Commercial Borrowing Interest Rate %</li>
                </ul>

            </div>
        </div>
    )
}

export function GeneralPage2({ form, setForm }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">General</h5>
            <TextField name="Name" label="Project Name" size="md" value={form.Name} setValue={setForm} autoFocus />
            <InputDescription text="Enter a unique and descriptive name for the joint venture project. This name should help you and the investor easily identify and reference the project in all communications and documents." />
            <TextareaField name="Description" label="Description" size="md" value={form.Description} setValue={setForm} type="textarea" />
            <InputDescription text="Provide a brief overview of the project, including details such as the type of spec home, its location, key features, and overall objectives. This description will help both you and the investor understand the scope and vision of the venture." />
            <TextField name="Address" label="Address" size="md" value={form.Address} setValue={setForm} />
            <InputDescription text="Enter the address of the property where the spec home will be built. This location will serve as the site for the joint venture project and should be specified accurately for planning and reference purposes." />
        </div>
    );
}

export function SchedulePage({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Project Schedule</h5>
            <DateField name="LandAcquisitionDate" label="Land Acquisition Date" size="md" value={form.LandAcquisitionDate} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Select the date when the land for the spec home will be acquired. This date marks the beginning of the project timeline and is used to calculate the project schedule and financial projections." />
            <NumberField name="PeriodPreConstructionMonths" label="Pre-Construction Period" size="md" value={form.PeriodPreConstructionMonths} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the number of months you anticipate holding the property before beginning construction. This period accounts for any delays or preparation time needed before starting the build." />
            <NumberField name="PeriodConstructionMonths" label="Construction Period" size="md" value={form.PeriodConstructionMonths} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Input the estimated duration of the construction phase in months. This should include all time required to complete the building from start to finish." />
            <NumberField name="PeriodMarketingMonths" label="Marketing Period" size="md" value={form.PeriodMarketingMonths} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Specify the number of months you expect to hold the property after construction is completed but before it is sold. This period may include time for finalizing details or waiting for the right market conditions." />
            <NumberField name="PeriodSettlementMonths" label="Settlement Period" size="md" value={form.PeriodSettlementMonths} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the number of months you anticipate for the settlement process after the property is sold. This period accounts for any time needed to finalize the sale and transfer ownership to the buyer." />
        </div>
    );
}

export function RevenuePage({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Revenue</h5>
            <CurrencyField name="SalePriceEstimatedSum" label="House Estimated Sell Price" size="md" value={form.SalePriceEstimatedSum} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Input the projected selling price of the home once it's completed. This estimate should reflect current market conditions and expected value based on similar properties in the area." />
            <NumberField name="UnitSquareFootageTotal" label="Total Square Footage" size="md" value={form.UnitSquareFootageTotal} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the total square footage of the spec home, including all living spaces, garages, and other areas. This figure helps determine the value of the property and potential selling price." />
        </div>
    );
}

export function CostingPage1({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Acquisition</h5>
            <CurrencyField name="LandPurchaseSum" label="Land Purchase" size="md" value={form.LandPurchaseSum} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Enter the estimated cost of acquiring the land where the spec home will be built. This includes the price of the land itself and any associated purchase fees." />

            <h5 className="col-span-2 border-b-2 font-semibold">Planning and Permitting</h5>
            <CurrencyField name="DesignPlanningSum" label="Design / Planning" size="md" value={form.DesignPlanningSum} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="..." />

            <h5 className="col-span-2 border-b-2 font-semibold">Site Improvements</h5>
            <CurrencyField name="SiteImprovementsSum" label="Site Improvements" size="md" value={form.SiteImprovementsSum} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Provide the estimated costs for any improvements required on the land before construction can begin. This might include grading, utility hookups, or other preparatory work not included in direct construction costs." />

            <h5 className="col-span-2 border-b-2 font-semibold">Direct Construction Costs</h5>
            <CurrencyField name="DirectConstructionSum" label="Direct Construction" size="md" value={form.DirectConstructionSum} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the estimated costs directly related to the construction of the home, including materials and labor. This should cover all aspects of building the house." />

            <h5 className="col-span-2 border-b-2 font-semibold">Contingency</h5>
            <PercentField name="ContingencyPercentage" label="Contingency %" size="md" value={form.ContingencyPercentage} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the percentage of the total project cost that will be set aside as a contingency fund. This amount is used to cover unexpected expenses or changes during the construction process. We recommend 3%." />

        </div>
    );
}

export function CostingPage2({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    const systemPromt = "You are an expert in modern home building investments. You have vast and insightful knowledge about how joint venture deals between investors and home builders work. The user will ask what a good commercial borrowing interest rate is based on the current market conditions. Provide a recommendation based on the current market conditions. Keep this response very short."
    const userPrompt = `What is a good commercial borrowing interest rate for a joint venture deal between an investor and a home builder right now?`

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Sales and Marketing</h5>
            <PercentField name="CommissionsPercentage" label="Commissions %" size="md" value={form.CommissionsPercentage} setValue={setForm} autoFocus onBlur={triggerCalculations} />
            <InputDescription text="Enter the percentage of the sale price that will be allocated to commissions for real estate agents and brokers. This rate covers the cost of marketing and selling the property." />

            <h5 className="col-span-2 border-b-2 font-semibold">Disposition</h5>
            <CurrencyField name="TransferTaxesOtherDispositionSum" label="Transfer Taxes and Other Disposition" size="md" value={form.TransferTaxesOtherDispositionSum} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Input the estimated costs associated with transferring the property to the buyer and other disposition expenses. This amount should cover all fees and taxes related to the sale of the home. We recommend 1%." />

            <h5 className="col-span-2 border-b-2 mb-1 font-semibold">Financing</h5>
            <CurrencyField name="FinanceExpensesSum" label="Finance Expenses" size="md" value={form.FinanceExpensesSum} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Input the total costs associated with financing the project, including appraisal, loan origination fees, points, bank legal fees, title insurance, document preparation, settlement fees and other similar expenses. This amount reflects the cost of securing financing for the venture." />
            <PercentField name="CommercialBorrowingInterestRatePercentage" label="Commercial Borrowing Interest Rate %" size="md" value={form.CommercialBorrowingInterestRatePercentage} setValue={setForm} aiAction={async () => RequestAI(systemPromt, userPrompt)} onBlur={triggerCalculations} />
            <InputDescription text="Input the interest rate for any commercial loans or lines of credit used to finance the project. This rate affects the cost of borrowing and the overall financial performance of the venture." />
        </div>
    );
}

export function CostingPage3({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Other Project Expenses</h5>
            <CurrencyField name="OtherProjectExpensesSum" label="Other Project Expenses" size="md" value={form.OtherProjectExpensesSum} setValue={setForm} onBlur={triggerCalculations} autoFocus />
            <InputDescription text="Enter any additional expenses not covered by other categories, such as legal fees, insurance, or other project-related costs. This amount should reflect all miscellaneous expenses associated with the venture. We recommend 1%." />

            <h5 className="col-span-2 border-b-2 font-semibold">Deal Expenses</h5>
            <PercentField name="ProjectManagementRatePercentage" label="Project Management Rate %" size="md" value={form.ProjectManagementRatePercentage} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="This rate covers the costs of managing the project, overseeing its successful completion and project expenses paid to the builder partner as a percentage of the sum of the land purchase, design/ planning, site improvement, direct construction and contigency costs." />
            <PercentField name="InvestorCashInterestRatePercentage" label="Interest Rate %" size="md" value={form.InvestorCashInterestRatePercentage} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Specify the percentage of the project's gross revenue that will be paid to the investor as a return on their cash investment. This rate reflects the return on the investor's capital." />
        </div>
    );
}

export function CapitalPage({ form, setForm, triggerCalculations }: { form: JVSpec, setForm: React.Dispatch<React.SetStateAction<JVSpec>>, triggerCalculations: () => void }) {

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 w-[580px] m-auto">
            <h5 className="col-span-2 border-b-2 font-semibold">Capital Stack</h5>
            <CurrencyField name="InvestorCashSum" label="Investor Cash" size="md" value={form.InvestorCashSum} setValue={setForm} onBlur={triggerCalculations} autoFocus />
            <InputDescription text="Input the total amount of cash the investor is contributing to the project. This represents the investor's financial commitment to the venture." />
            <CurrencyField name="BuilderCashSum" label="Builder Cash" size="md" value={form.BuilderCashSum} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Specify the total amount of cash the builder is contributing to the project. This represents the builder's financial stake in the venture." />
            <PercentField name="IRRMinimumPercentage" label="Baseline IRR" size="md" value={form.IRRMinimumPercentage} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the baseline internal rate of return (IRR) that the investor must achieve for the project to meet their expectations. This represents the lowest acceptable return rate for the investor to consider the project viable." />
            <PercentField name="IRRMaximumPercentage" label="Target IRR" size="md" value={form.IRRMaximumPercentage} setValue={setForm} onBlur={triggerCalculations} />
            <InputDescription text="Enter the target internal rate of return (IRR) that represents the desired outcome for the investor. This rate should be compelling enough to attract the investor to the project." />

        </div>
    );
}

export function CompletePage() {
    return (
        <>
            <div className="flex flex-grow flex-col flex-wrap text-wrap p-5 ">
                <p className="m-auto"> Congratulations, you are done!</p>
            </div>
        </>
    )
}


